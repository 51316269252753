import React from 'react';

import { useSelector } from 'react-redux';

import GroupMemberList from './GroupMemberList';
import GroupNotification from './GroupNotification';

interface GroupChatProfileProps {
  session: {
    sessionId: string;
    conversationID: string;
  };
}

const GroupChatProfile: React.FC<GroupChatProfileProps> = ({ session }) => {
  const groupInfo = useSelector((s) => s.tim.groupInfo);
  return (
    <div style={{ width: '100%' }}>
      {session?.conversationID && session?.sessionId && (
        <>
          <GroupNotification
            notification={groupInfo?.notification}
            conversationID={session?.conversationID}
            sessionId={session?.sessionId}
          ></GroupNotification>
          <GroupMemberList
            memberList={groupInfo?.memberList}
            memberCount={groupInfo?.memberCount}
            conversationID={session?.conversationID}
            sessionId={session?.sessionId}
          ></GroupMemberList>
        </>
      )}
    </div>
  );
};

export default GroupChatProfile;
