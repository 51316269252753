/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState, createContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Justify, SearchBox } from '@tencent/tea-component';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { TIMSDK as tim } from 'tccc-sdk';

import BaseDialog from 'src/components/BaseDialog/BaseDialog';
import HistoryMessageList from 'src/components/IM/Message/MessageList/HistoryMessageList';
import { IM_MESSAGE_EXTRA_TYPE } from 'src/constants/im';
import i18n from 'src/i18n';
import { request } from 'src/services/httpClient';

import { isGroupChat } from 'src/utils';

import style from './HistoryRecord.style';

export function getMessageHistoryList(option) {
  const data = Object.assign({}, option);
  delete data.userName;
  return request('/tcccadmin/chat/queryUserChatHistoryTask', option);
}

const useStyle = makeStyles(style);
let currentIndex = 0;

const { t } = i18n;

function handleMessage(list, userName, isGroup = false) {
  const data = list.map((item) => {
    item.ID = item.id;
    if (item.type === 'context') {
      const detail = JSON.parse(item.detail);
      item.time = item.timestamp / 1000;
      if (item.event === 'EVENT_USER_IM_MSG' || item.from === 'EVENT_USER_IM_MSG') {
        item.flow = 'in';
        if (isGroup) {
          item.from = detail.From_Account || t('用户');
          item.nickName = detail.From_Account || t('用户');
        } else {
          item.from = userName || t('用户');
          item.nickName = userName || t('用户');
        }
      } else if (item.event === 'EVENT_SEAT_IM_MSG') {
        item.flow = 'out';
        if (item.seatName) {
          item.from = item.seatName;
        } else if (detail.From_Account === 'administrator') {
          item.from = t('系统消息');
        } else {
          item.from = t('系统消息');
        }
      }

      if (detail?.RevokerInfo) {
        item.isRevoked = true;
        item.RevokerInfo = detail.RevokerInfo;
      }

      if (detail.MsgBody) {
        item._elements = detail.MsgBody.map((body) => {
          body.type = body.MsgType;
          const msgContent = body.MsgContent;
          switch (body.type) {
            case tim.TYPES.MSG_GEO:
              body.content = {
                description: msgContent.Desc,
                latitude: msgContent.Latitude,
                longitude: msgContent.Longitude,
              };
              break;
            case tim.TYPES.MSG_CUSTOM:
              body.content = {
                description: msgContent.Desc,
                extension: msgContent.Ext,
                data: JSON.parse(msgContent.Data),
              };
              break;
            case tim.TYPES.MSG_IMAGE: {
              // IM的image 后台 1：原图 2大图 3缩略图
              // tim-js-sdk 0 原图 1缩略图 2大图
              const [raw, large, thumbnail] = msgContent.ImageInfoArray.map((img) => {
                img.sizeType = img.size;
                img.url = img.URL;
                img.imageUrl = img.URL;
                img.width = img.Width;
                img.height = img.Height;
                img.size = img.Size;
                return img;
              });
              body.content = {
                imageFormat: msgContent.ImageFormat,
                imageInfoArray: [raw, thumbnail, large].map((item, sizeType) => ({ ...item, sizeType })),
              };
              break;
            }
            case tim.TYPES.MSG_FACE: {
              body.content = {
                data: msgContent.Data,
                index: msgContent.Index,
              };
              break;
            }

            case tim.TYPES.MSG_TEXT:
              body.content = {
                text: msgContent.Text,
              };
              break;
            case tim.TYPES.MSG_FILE:
              body.content = {
                downloadFlag: msgContent.Download_Flag,
                fileUrl: msgContent.Url,
                uuid: msgContent.UUID,
                fileName: msgContent.FileName,
                fileSize: msgContent.FileSize,
              };
              break;
            case tim.TYPES.MSG_SOUND:
              body.content = {
                url: msgContent.Url,
              };
              break;
            default:
              body.content = {};
          }
          return body;
        });
        item._elements.forEach((element) => {
          item.type = element.type;
          item.payload = element.content;
        });
      } else {
        item._elements = [];
        item.type = '';
      }
    }
    if (item.type === 'separator') {
      item.type = IM_MESSAGE_EXTRA_TYPE.INFO;
      const name = item.nickName || t('未成功接入客服');
      item.payload = {
        text: `${name}  ${moment(window.parseInt(item.timestamp)).format('YYYY-MM-DD HH:mm:ss')}`,
      };
    }
    return item;
  });

  if (data.every((item) => item.type === 'separator')) {
    return [];
  }
  return data;
}

export function useIMHistory(data) {
  const sdkAppId = useSelector((s) => s.userInfo.sdkAppId);
  const [isTop, setIsTop] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const [finalList, setFinalList] = useState([]);

  useEffect(() => {
    setIsTop(true);
    setIsBottom(false);
    setFinalList([]);
    data.userId &&
      getMessageHistoryList({
        userId: data.userId,
        sessionId: data.sessionId,
        roomId: data.roomId,
        direct: 'down',
        size: 20,
        sdkAppId,
      }).then((list) => {
        if (list.count === 0) {
          setIsBottom(true);
          setIsTop(true);
          setFinalList([]);
          return;
        }
        setIsTop(false);
        const newMessageList = handleMessage(list.details, data.userName, isGroupChat(data));
        setFinalList(newMessageList);
      });
  }, [data.roomId, data.sessionId, data.userId, data.userName, sdkAppId]);

  const onBeforeMessage = useCallback(() => {
    const size = 20;
    const options = { ...data, sdkAppId, direct: 'up', size, cursor: finalList.find((item) => item?.cursor)?.cursor };
    if (options.cursor) {
      delete options.roomId;
      delete options.sessionId;
    }
    return (
      options.userId &&
      getMessageHistoryList(options).then((newList) => {
        if (newList.count === 0) {
          return setIsTop(true);
        }
        if (newList.count < size) {
          setIsTop(true);
        }

        const newMessageList = handleMessage(newList.details, options.userName, isGroupChat(data));
        setFinalList([...newMessageList, ...finalList]);
      })
    );
  }, [data, finalList, sdkAppId]);
  const onAfterMessage = useCallback(() => {
    const size = 20;
    const cursor = finalList[finalList.length - 1]?.cursor;
    if (!cursor) return;
    const options = { ...data, sdkAppId, direct: 'down', size, cursor };
    if (options.cursor) {
      delete options.roomId;
      delete options.sessionId;
    }
    if (isBottom) return;
    options.userId &&
      getMessageHistoryList(options).then((newList) => {
        if (newList.count === 0) {
          return setIsBottom(true);
        }
        if (newList.count < size) {
          setIsBottom(true);
        }
        const newMessageList = handleMessage(newList.details, options.userName, isGroupChat(data));
        setFinalList([...finalList, ...newMessageList]);
      });
  }, [data, finalList, isBottom, sdkAppId]);
  return {
    list: finalList,
    onAfterMessage,
    onBeforeMessage,
    isTop,
    isBottom,
  };
}
export const MessageHistorySearchKeywordsContext = createContext();
export default function HistoryRecord(props) {
  const { onClose, data } = props;
  const classes = useStyle();
  const historyData = useIMHistory(data);
  const [keywords, setKeywords] = useState(props.keyWords);

  useEffect(() => {
    if (props.keyWords !== keywords) {
      setKeywords(props.keyWords);
    }
  }, [props]);

  const scrollToElement = useCallback(() => {
    const elements = document.querySelectorAll('mark.highlighter-match');
    currentIndex += 1;
    elements?.[currentIndex % elements.length]?.scrollIntoView({ block: 'nearest' });
  }, []);

  return (
    <BaseDialog
      open={!!data.userId}
      title={t('与{{0}}的会话记录', { 0: data.userName ? data.userName : t('用户') })}
      onClose={onClose}
      withCancel={false}
      paperClass={classes.paper}
      scrollPaperClass={classes.scrollPaper}
      dialogProps={{
        maxWidth: false,
        width: 'auto',
        style: { zIndex: 800 },
      }}
    >
      <Justify
        right={<SearchBox onSearch={scrollToElement} value={keywords} onChange={(value) => setKeywords(value)} />}
      />
      <MessageHistorySearchKeywordsContext.Provider value={keywords}>
        <HistoryMessageList
          {...historyData}
          customerInfo={{
            nickName: data.userName,
            userId: data.userId,
            status: '400',
            channelAgentID: data?.channelAgentID ?? '',
          }}
          showFollowUpBtn={data.canFollowUpSession}
        />
      </MessageHistorySearchKeywordsContext.Provider>
    </BaseDialog>
  );
}

HistoryRecord.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  keyWords: PropTypes.string,
};
