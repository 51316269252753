import React, { useEffect } from 'react';

import { Tooltip, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { updateGroupMemberCount, updateGroupNotification } from 'src/store/slices/tim.thunk';
import tccc from 'src/utils/tccc';

import logger from '../../utils/logger';

interface GroupNotificationProps {
  conversationID: string;
  notification: string;
  sessionId: string;
}

const GroupNotificationContainer = styled.div`
  word-break: break-all;
  font-size: 14px;
  padding: 6px 12px 0 12px;
  height: 300px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  overflow: hidden;
`;

const GroupNotificationTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
`;

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 320,
    minHeight: 300,
    fontSize: 12,
    border: '1px solid #dadde9',
    padding: '12px',
  },
}))(Tooltip);

const GroupNotification: React.FC<GroupNotificationProps> = ({ conversationID, notification, sessionId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const groupID = conversationID.replace('GROUP', '');
    const getGroupProfile = async () => {
      try {
        const res = await tccc.Chat.getGroupProfile({ groupID });
        const notification = res.data?.data?.group?.notification;
        const memberCount = res.data?.data?.group?.memberCount ?? 0;
        dispatch(updateGroupMemberCount({ sessionId, memberCount, type: 'init' }));
        if (notification && typeof notification === 'string' && notification?.length > 0) {
          dispatch(updateGroupNotification({ sessionId, notification, type: 'init' }));
        } else {
          dispatch(updateGroupNotification({ sessionId, notification: t('暂无群公告'), type: 'init' }));
        }
      } catch (e) {
        dispatch(updateGroupNotification({ sessionId, notification: t('获取群公告失败'), type: 'init' }));
        logger.error('getGroupProfileError', e);
      }
    };
    getGroupProfile();
  }, [sessionId, conversationID, dispatch, t]);

  return (
    <HtmlTooltip
      interactive
      title={
        <div>
          <div style={{ textAlign: 'center', marginBottom: '10px' }}>{t('群公告')}</div>
          {notification}
        </div>
      }
      placement="left"
    >
      <GroupNotificationContainer>
        <GroupNotificationTitle>
          <div>{t('群公告')}</div>
          <div>{'>'}</div>
        </GroupNotificationTitle>
        <span style={{ fontSize: '12px' }}>{notification}</span>
      </GroupNotificationContainer>
    </HtmlTooltip>
  );
};

export default React.memo(GroupNotification);
