import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';

import sdkMessage from 'src/agentSdkIframe/sdkMessage';
import { hideConfirmDialog, showConfirmDialog } from 'src/components/universal/ConfirmDialogTips/ConfirmDialogTips';
import i18n from 'src/i18n';
import { debugLogRequest } from 'src/services';
import { loginInstance } from 'src/store/slices/app.thunk';
import {
  startCall,
  dialBack,
  updatePhoneRemark,
  triggerReceiveKey,
  callHold,
  cancelCallHold,
  updateReceiveStatus,
  updateForwardStatus,
  setIvrSoundPlayFinished,
  triggerSelfService,
  leaveForwardOut,
  restoreForwardOut,
} from 'src/store/slices/sessions/pstn';
import { changeMicStatus, monitorRTC, exitMonitorRTC, startCallInternal } from 'src/store/slices/sessions/rtc';

import {
  accessSession,
  addToBlackList,
  deleteSession,
  endSession,
  muteVideo,
  transferSession,
  unmuteVideo,
  setActiveUser,
} from 'src/store/slices/sessions/sessions.thunk';
import logger from 'src/utils/logger';

import {
  updateUnreadCount,
  endMedia,
  inviteMedia,
  markUnread,
  messageRevoked,
  receiveMessageThunk,
  revokeMessage,
  sendMessage,
  setMessageRead,
  shouldAddUnreadCountOrUpdateLastMessage,
} from '../tim.thunk';

import { startRealtimeAsr, stopRealtimeAsr } from './asr';

import { updateLastMessage } from './im';

import {
  addSession,
  phoneCallOutAnswer,
  updateSession,
  upsertSession,
  phoneCallInAnswer,
  updateMember,
  changeMicStatusAction,
} from './sessions.action';

import { Direction, Session, SessionStatus } from './types';

export * from './types';

const { t } = i18n;

function isRejectedAction(action: any): action is any {
  return action.type.endsWith('rejected');
}
function isRTCErrorAction(action: any): action is any {
  return (
    action.type.endsWith('rejected') &&
    [dialBack.typePrefix, startCall.typePrefix, startCallInternal.typePrefix, accessSession.typePrefix].includes(
      action.type.replace(/\/rejected$/, ''),
    ) &&
    (action.error.message?.includes('setLocalDescription') ||
      action.error.message?.includes(t('生产环境必须使用HTTPS部署您的应用')))
  );
}

export const sessionsAdapter = createEntityAdapter<Session>({
  selectId: (book) => book.sessionId,
  sortComparer: (a, b) => (Number(a.timestamp) > Number(b.timestamp) ? -1 : 1),
});

function webRTCHttpErrorTips() {
  showConfirmDialog({
    icon: 'error',
    enterText: t('知道了'),
    title: t(`通话失败`),
    cancelText: '',
    content: t(`请使用 HTTPS 协议来部署前端页面（开发时可以用 localhost），否则会因为浏览器限制无法正常通话。`),
    onEnter() {
      hideConfirmDialog();
    },
  });
}

const sessionsSlice = createSlice({
  name: 'sessions',
  initialState: sessionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(endSession.fulfilled, (state, action) => {
        if (action.payload.forceDelete) {
          sessionsAdapter.removeOne(state, action.payload.sessionId);
        } else {
          type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];
          const changes = omit(action.payload, 'forceDelete') as AtLeastOne<Session>;
          sessionsAdapter.updateOne(state, {
            id: action.payload.sessionId,
            changes,
          });
        }
      })
      .addCase(startCall.fulfilled, (state, action) => {
        sessionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(dialBack.fulfilled, (state, action) => {
        sessionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(accessSession.pending, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.meta.arg.sessionId,
          changes: {
            status: '150',
          },
        });
      })
      .addCase(accessSession.fulfilled, (state, action) => {
        const session = sessionsAdapter.getSelectors().selectById(state, action.payload.sessionId);
        if (session && +session.status > 200) {
          return debugLogRequest(`abort accessSession, status:${session.status}`);
        }
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            status: '200',
            timestamp: action.payload.timestamp,
            aiEnabled: action.payload.aiEnabled,
          },
        });
      })
      // 后台暂时是调用callin事件，后续可能会修改成主动插入会话列表
      // .addCase(followUpIMSession.fulfilled, (state, action) => {
      //   action.payload.map((item) => sessionsAdapter.addOne(state, item));
      // })
      .addCase(accessSession.rejected, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.meta.arg.sessionId,
          changes: {
            status: '400',
          },
        });
      })
      // 监听
      .addCase(monitorRTC.fulfilled, (state, action: any) => {
        sessionsAdapter.addOne(state, action.payload);
      })
      .addCase(exitMonitorRTC.fulfilled, (state, action) => {
        sessionsAdapter.removeOne(state, action.payload.sessionId);
      })
      .addCase(leaveForwardOut.fulfilled, (state, action) => {
        sessionsAdapter.removeOne(state, action.payload.sessionId);
      })
      .addCase(restoreForwardOut.fulfilled, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.meta.arg.sessionId,
          changes: {
            holdStatus: false,
          },
        });
      })
      // 内线通话
      .addCase(startCallInternal.fulfilled, (state, action) => {
        sessionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(updatePhoneRemark.fulfilled, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            remark: action.payload.remark,
          },
        });
      })
      .addCase(triggerReceiveKey.pending, (state, action) => {
        const { sessionId, clientData, param, ivrId } = action.meta.arg;
        sessionsAdapter.updateOne(state, {
          id: sessionId,
          changes: {
            receiveStatus: 'waiting',
          },
        });
        sdkMessage.postEventMessage(sdkMessage.events.receiveStatusChanged, {
          receiveStatus: 'waiting',
          sessionId,
          clientData,
          param,
          ivrId,
        });
      })
      .addCase(triggerReceiveKey.rejected, (state, action) => {
        const { sessionId, clientData, param, ivrId } = action.meta.arg;
        sessionsAdapter.updateOne(state, {
          id: sessionId,
          changes: {
            receiveStatus: 'error',
          },
        });
        sdkMessage.postEventMessage(sdkMessage.events.receiveStatusChanged, {
          receiveStatus: 'error',
          sessionId,
          clientData,
          param,
          ivrId,
        });
      })
      .addCase(triggerSelfService.pending, (state, action) => {
        const { sessionId, clientData, param, selfId } = action.meta.arg;
        sessionsAdapter.updateOne(state, {
          id: sessionId,
          changes: {
            receiveStatus: 'waiting',
          },
        });
        sdkMessage.postEventMessage(sdkMessage.events.receiveStatusChanged, {
          receiveStatus: 'waiting',
          sessionId,
          clientData,
          param,
          selfId,
        });
      })
      .addCase(triggerSelfService.rejected, (state, action) => {
        const { sessionId, clientData, param, selfId } = action.meta.arg;
        sessionsAdapter.updateOne(state, {
          id: sessionId,
          changes: {
            receiveStatus: 'error',
          },
        });
        sdkMessage.postEventMessage(sdkMessage.events.receiveStatusChanged, {
          receiveStatus: 'error',
          sessionId,
          clientData,
          param,
          selfId,
        });
      })
      .addCase(callHold.fulfilled, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.meta.arg.sessionId,
          changes: {
            holdStatus: true,
          },
        });
      })
      .addCase(cancelCallHold.fulfilled, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.meta.arg.sessionId,
          changes: {
            holdStatus: false,
          },
        });
      })
      .addCase(transferSession.fulfilled, (state, action) => {
        if (action.payload) {
          const session = sessionsAdapter.getSelectors().selectById(state, action.payload.sessionId);
          // 临时处理，因为在线会话转接会转到自己...
          if (session?.status === '200' && session.type === 'im') {
            sessionsAdapter.updateOne(state, {
              id: action.meta.arg.sessionId,
              changes: {
                state: 'finished',
                status: '400',
                duration: action.payload.duration,
              },
            });
          } else {
            sessionsAdapter.removeOne(state, action.payload.sessionId);
          }
        }
      })
      .addCase(updateReceiveStatus, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            receiveStatus: action.payload.receiveStatus,
          },
        });
      })
      .addCase(updateForwardStatus, (state, action) => {
        const session = sessionsAdapter.getSelectors().selectById(state, action.payload.sessionId);
        if (session) {
          sessionsAdapter.updateOne(state, {
            id: action.payload.sessionId,
            changes: {
              forwardInfo: {
                ...session.forwardInfo,
                ...action.payload.forwardInfo,
              },
            },
          });
        }
      })
      .addCase(setIvrSoundPlayFinished, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            ivrSoundPlaying: false,
          },
        });
        const promise = (document.getElementById('newMessageAudio') as HTMLAudioElement)?.play();
        if (promise !== undefined) {
          promise.catch((error) => {
            logger.error('setIvrSoundPlayFinished auto play failed', error);
          });
        }
      })
      .addCase(addToBlackList.fulfilled, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            isBlackUser: true,
          },
        });
      })
      .addCase(deleteSession.fulfilled, (state, action) => {
        sessionsAdapter.removeOne(state, action.payload.sessionId);
      })
      .addCase(updateLastMessage, (state, action: any) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            lastMessage: action.payload.lastMessage,
          },
        });
      })
      .addCase(changeMicStatus.fulfilled, (state, action) => {
        if (!action.payload.memberId) {
          sessionsAdapter.updateOne(state, {
            id: action.payload.sessionId,
            changes: {
              micStatus: action.payload.micStatus,
            },
          });
        }
      })
      .addCase(muteVideo.fulfilled, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            disableCamera: true,
          },
        });
      })
      .addCase(unmuteVideo.fulfilled, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            disableCamera: false,
          },
        });
      })
      .addCase(loginInstance.fulfilled, (state, action) => {
        const sessions = sessionsAdapter.getSelectors().selectAll(state);
        const res = action.payload.sessions.filter(
          (item) => !sessions.some((session) => session.sessionId === item.sessionId),
        );
        sessionsAdapter.upsertMany(state, res);
      })
      .addCase(phoneCallOutAnswer, (state, action) => {
        const changes = {
          status: '200' as SessionStatus,
          timestamp: Math.floor(Date.now() / 1000),
          isHost: action.payload.isHost,
        };
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes,
        });
      })
      .addCase(phoneCallInAnswer, (state, action) => {
        const changes = {
          status: '200' as SessionStatus,
          timestamp: Math.floor(Date.now() / 1000),
          ivrSoundPlaying: action.payload.playAudio,
          isHost: action.payload.isHost,
        };
        if (action.payload.serverType) {
          Object.assign(changes, { serverType: action.payload.serverType });
        }
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes,
        });
      })
      .addCase(addSession, (state, action) => {
        sessionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(updateSession, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload.changes,
        });
      })
      .addCase(upsertSession, (state, action) => {
        sessionsAdapter.upsertOne(state, action.payload);
      })
      .addCase(setMessageRead.fulfilled, (state, action) => {
        if (action.payload) {
          sessionsAdapter.updateOne(state, {
            id: action.payload.sessionId,
            changes: {
              count: 0,
            },
          });
        }
      })
      .addCase(updateUnreadCount, (state, action) => {
        const updateList: { id: string; changes: { count: number } }[] = [];
        if (Array.isArray(action.payload)) {
          action.payload.map((item) => {
            const session = sessionsAdapter.getSelectors().selectById(state, item?.sessionId ?? '');
            if (session && session?.status === '200' && session?.count !== item.count) {
              updateList.push({ id: session?.sessionId, changes: { count: item.count } });
            }
          });
          if (updateList.length > 0) {
            sessionsAdapter.updateMany(state, updateList);
          }
        }
      })
      .addCase(markUnread.fulfilled, (state, action) => {
        if (action.payload) {
          sessionsAdapter.updateOne(state, {
            id: action.payload.sessionId,
            changes: {
              count: 1,
            },
          });
        }
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        if (action.payload.message.type !== 'TIMCustomElem') {
          sessionsAdapter.updateOne(state, {
            id: action.payload.message.sessionId,
            changes: {
              lastMessage: action.payload.message,
            },
          });
        }
      })
      .addCase(receiveMessageThunk.fulfilled, (state, action) => {
        action.payload.forEach((item) => {
          if (shouldAddUnreadCountOrUpdateLastMessage(item)) {
            sessionsAdapter.updateOne(state, {
              id: item.sessionId,
              changes: {
                lastMessage: item,
              },
            });
          }
        });
      })
      .addCase(messageRevoked.fulfilled, (state, action) => {
        action.payload.forEach((item) => {
          const session = sessionsAdapter.getSelectors().selectById(state, item.sessionId);
          const revokeMessage = session?.lastMessage;
          if (revokeMessage && revokeMessage.ID === item.messageId) {
            const newRevokeMessage = { ...revokeMessage, isRevoked: true };
            sessionsAdapter.updateOne(state, {
              id: item.sessionId,
              changes: {
                lastMessage: newRevokeMessage,
              },
            });
          }
        });
      })
      .addCase(revokeMessage.fulfilled, (state, action) => {
        const { message } = action.payload;
        const session = sessionsAdapter.getSelectors().selectById(state, message.sessionId);
        const revokeMessage = session?.lastMessage;
        if (revokeMessage && revokeMessage.ID === message.ID) {
          const newRevokeMessage = { ...revokeMessage, isRevoked: true };
          sessionsAdapter.updateOne(state, {
            id: message.sessionId,
            changes: {
              lastMessage: newRevokeMessage,
            },
          });
        }
      })
      .addCase(inviteMedia.fulfilled, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            media: {
              type: action.meta.arg.type,
              status: '150',
              direction: Direction.callOut,
            },
          },
        });
      })
      .addCase(endMedia.fulfilled, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            media: undefined,
          },
        });
      })
      .addCase(startRealtimeAsr.fulfilled, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            isStartRealtimeAsr: true,
          },
        });
      })
      .addCase(stopRealtimeAsr.fulfilled, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            isStartRealtimeAsr: false,
          },
        });
      })
      .addCase(setActiveUser.fulfilled, (state, action) => {
        if (action?.payload) {
          sessionsAdapter.updateOne(state, {
            id: action.payload?.sessionId,
            changes: {
              isFirstCallIn: false,
            },
          });
        }
      })
      .addCase(updateMember, (state, action) => {
        const session = sessionsAdapter.getSelectors().selectById(state, action.payload.sessionId);
        if (session && action.payload.changes) {
          let memberDict = keyBy(session.members, 'memberId');
          const { memberId } = action.payload.changes;
          set(memberDict, memberId, action.payload.changes);
          memberDict = omitBy(
            memberDict,
            (member) =>
              (['INIT', 'RINGING'].includes(member.callState) && 'waitId' in member && member.waitId) ||
              member.callState === 'END',
          );

          const members = Object.values(memberDict);
          sessionsAdapter.updateOne(state, {
            id: action.payload.sessionId,
            changes: {
              members,
            },
          });
        }
        if (session && typeof action.payload.isHost === 'boolean') {
          sessionsAdapter.updateOne(state, {
            id: action.payload.sessionId,
            changes: {
              isHost: action.payload.isHost,
            },
          });
        }
      })
      .addCase(changeMicStatusAction, (state, action) => {
        sessionsAdapter.updateOne(state, {
          id: action.payload.sessionId,
          changes: {
            micStatus: action.payload.micStatus,
          },
        });
      })
      .addMatcher(isRejectedAction, (state, action) => {
        debugLogRequest('rejected action', action);
      })
      .addMatcher(isRTCErrorAction, () => {
        webRTCHttpErrorTips();
      });
  },
});

export const sessions = sessionsSlice.reducer;
