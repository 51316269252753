import * as t from 'io-ts';
import * as IMThunk from '../store/sessions/tim.thunk';
import { Message } from '../tccc';
import { SessionAdapter } from './SessionAdapter';

type OmitEndChatParams = 'closeBy' | 'emitter';
export class Chat extends SessionAdapter {
  seatInChat = (args: Omit<Parameters<typeof IMThunk.accessIMSession>[0], 'emitter'>) =>
    IMThunk.accessIMSession({ ...args, emitter: this.sdk });
  endChat = (args: Omit<Parameters<typeof IMThunk.endIMSession>[0], OmitEndChatParams>) =>
    IMThunk.endIMSession({ ...args, emitter: this.sdk, closeBy: 'seat' });
  completeChat = (params: Omit<Parameters<typeof IMThunk.completeChat>[0], 'emitter'>) =>
    IMThunk.completeChat({ ...params, emitter: this.sdk });
  sendSessionRating = (args: Omit<Parameters<typeof IMThunk.sendIMSessionRating>[0], 'sdk'>) =>
    IMThunk.sendIMSessionRating({ ...args, sdk: this.sdk });
  getMessageList = (args: Omit<Parameters<typeof IMThunk.getMessageList>[0], 'tccc'>) =>
    IMThunk.getMessageList({ tccc: this.sdk, ...args });
  getMessageListV2 = (args: Omit<Parameters<typeof IMThunk.getMessageListV2>[0], 'tccc'>) =>
    IMThunk.getMessageListV2({ tccc: this.sdk, ...args });
  revokeMessage = (message: Message) => IMThunk.revokeMessage({ tccc: this.sdk, message });
  resendMessage = (message: Message) => IMThunk.resendMessage({ tccc: this.sdk, message });
  getSessionList = () => IMThunk.getSessionList(this.sdk);
  getInnerConversationList = () => IMThunk.getInnerConversations({ tccc: this.sdk });
  setMessageRead = (args: Omit<Parameters<typeof IMThunk.setMessageRead>[0], 'tccc'>) =>
    IMThunk.setMessageRead({ tccc: this.sdk, ...args });
  markUnread = (args: Omit<Parameters<typeof IMThunk.markUnread>[0], 'tccc'>) =>
    IMThunk.markUnread({ tccc: this.sdk, ...args });
  transfer = (args: t.TypeOf<typeof IMThunk.TransferParams>) => IMThunk.transferIM({ ...args, sdk: this.sdk });
  createTextMessage = (args: Omit<Parameters<typeof IMThunk.createTextMessage>[0], 'tccc'>) =>
    IMThunk.createTextMessage({ tccc: this.sdk, ...args });
  createImageMessage = (args: Omit<Parameters<typeof IMThunk.createImageMessage>[0], 'tccc'>) =>
    IMThunk.createImageMessage({ tccc: this.sdk, ...args });
  createCustomMessage = (args: Omit<Parameters<typeof IMThunk.createCustomMessage>[0], 'tccc'>) =>
    IMThunk.createCustomMessage({ tccc: this.sdk, ...args });
  createFileMessage = (args: Omit<Parameters<typeof IMThunk.createFileMessage>[0], 'tccc'>) =>
    IMThunk.createFileMessage({ tccc: this.sdk, ...args });
  createVideoMessage = (args: Omit<Parameters<typeof IMThunk.createVideoMessage>[0], 'tccc'>) =>
    IMThunk.createVideoMessage({ tccc: this.sdk, ...args });
  sendMessage = (message: Message, options?: { onlineUserOnly: boolean }) =>
    IMThunk.sendMessage({ tccc: this.sdk, message, options });
  sendMessageReadReceipt = (args: Omit<Parameters<typeof IMThunk.sendMessageReadReceipt>[0], 'tccc'>) =>
    IMThunk.sendMessageReadReceipt({ tccc: this.sdk, ...args });
  sendMessageReadReceiptV2 = (args: Omit<Parameters<typeof IMThunk.sendMessageReadReceiptV2>[0], 'tccc'>) =>
    IMThunk.sendMessageReadReceiptV2({ tccc: this.sdk, ...args });
  inviteAudio = (args: Omit<Parameters<typeof IMThunk.inviteMedia>[0], 'tccc' | 'type'>) =>
    IMThunk.inviteMedia({ tccc: this.sdk, type: 'audio', ...args });
  inviteVideo = (args: Omit<Parameters<typeof IMThunk.inviteMedia>[0], 'tccc' | 'type'>) =>
    IMThunk.inviteMedia({ tccc: this.sdk, type: 'video', ...args });
  endAudio = (args: Omit<Parameters<typeof IMThunk.endInviteMedia>[0], 'emitter'>) =>
    IMThunk.endInviteMedia({ emitter: this.sdk, ...args });
  endVideo = (args: Omit<Parameters<typeof IMThunk.endInviteMedia>[0], 'emitter'>) =>
    IMThunk.endInviteMedia({ emitter: this.sdk, ...args });
  getHistoryMessageList = (args: Omit<Parameters<typeof IMThunk.getHistoryMessageList>[0], 'tccc'>) =>
    IMThunk.getHistoryMessageList({ tccc: this.sdk, ...args });
  sendCardMessage = (args: Omit<Parameters<typeof IMThunk.sendCardMessage>[0], 'tccc'>) =>
    IMThunk.sendCardMessage({ tccc: this.sdk, ...args });
  sendOrderMessage = (args: Omit<Parameters<typeof IMThunk.sendOrderMessage>[0], 'tccc'>) =>
    IMThunk.sendOrderMessage({ tccc: this.sdk, ...args });
  sendCustomMessage = (args: Omit<Parameters<typeof IMThunk.sendCustomMessage>[0], 'tccc'>) =>
    IMThunk.sendCustomMessage({ tccc: this.sdk, ...args });
  getConversationProfile = (args: Omit<Parameters<typeof IMThunk.getConversationProfile>[0], 'tccc'>) =>
    IMThunk.getConversationProfile({ tccc: this.sdk, ...args });
  getIMAgentListForContactUser = () => IMThunk.getIMAgentListForContactUser(this.sdk);
  getIMUserProfile = (userIDs: string[]) => IMThunk.getIMUserProfile(userIDs, this.sdk);
  startFollowUpConversation = (args: { channelAgentID: string; ClientUserID: string }) =>
    IMThunk.startFollowUpConversation(args.channelAgentID, args.ClientUserID, this.sdk);
  translateText = (args: { sourceTextList: string[]; sourceLanguage: string; targetLanguage: string }) =>
    IMThunk.translateText(args, this.sdk);
  getGroupProfile = (args: { groupID: string }) => IMThunk.getGroupProfile(args, this.sdk);
  getGroupMemberList = (args: { groupID: string; count: number; offset: number }) =>
    IMThunk.getGroupMemberList(args, this.sdk);
  getGroupMemberProfile = (args: { groupID: string; userIDList: string[] }) =>
    IMThunk.getGroupMemberProfile(args, this.sdk);
  getConversationList = (args: string[]) => IMThunk.getConversationList(args, this.sdk);
}
