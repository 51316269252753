import * as t from 'io-ts';
import pick from 'lodash/pick';
import { getLogger } from '../common/Logger';
import { unwrapResult } from '../store/createAsyncThunk';
import { Direction, SessionStatus, SessionType } from '../constants/sessions';
import { booleanFromString1or2, getOrPromiseReject, timer } from 'tccc-utils';
import { Session } from '../store/sessions';
import { giveUpSession } from '../store/sessions/sessions.thunk';
import { TcccEventEmitter, TcccSdk } from '../tccc';
import type { IMCallIn, VideoCallIn } from '../tccc/events';
import { AsrParser, asrParserMap, AsrResult } from '../trtc/asr/AsrParser';
import * as S2CEvent from './s2cEvent';
import { endIMSession, endInviteMedia, getSessionIdFromUserId } from '../store/sessions/tim.thunk';
import traceContext from '../http/tracer';
export const IMCallInInvite = async (data: unknown, emitter: TcccSdk) => {
  const logger = getLogger(emitter.Agent.userInfo);
  const spanName = 'tccc.events.callIn';
  const manualSpan = traceContext.tracer.startSpan(spanName, {
    attributes: pick(emitter.Agent.userInfo, ['sdkAppId', 'userId']),
  });
  const { spanId, traceId } = manualSpan.spanContext();
  logger.custom(
    {
      spanId,
      traceId,
      level: 'info',
    },
    `[EVENTS] ${spanName}`,
  );
  try {
    const callInfo = await getOrPromiseReject(S2CEvent.IMCallIn)(data);
    const { imTimeout, videoTimeout } = emitter.Agent.settings;
    const { autoEnter } = callInfo.callInInfo;
    const timeout = autoEnter ? videoTimeout : imTimeout;
    const callInProps: IMCallIn | VideoCallIn = {
      sessionId: callInfo.extra.sessionId,
      userId: callInfo.staff.userId,
      timeout,
      type: autoEnter ? SessionType.video : SessionType.im,
      channelName: callInfo.extra?.clientChannelName,
      peerSource: callInfo.callInInfo.peerSource,
      clientData: callInfo.extra.clientData,
      nickname: callInfo.callInInfo.wxNick || callInfo.callInInfo.nickName,
      avatar: callInfo.callInInfo.avatar,
      remark: callInfo.callInInfo.remark,
      onlineState: callInfo.callInInfo.onlineState,
      channelAgentID: callInfo?.extra?.channelAgentID ?? '',
      isIMCallOut: callInfo?.extra?.isIMCallOut ?? 0,
      imAgentChatType: callInfo?.extra?.imAgentChatType ?? 0,
      // 呼入会话一定是群聊
      conversationID: `GROUP${callInfo?.extra?.imGroupId}`,
    };
    const session: Session = {
      ...callInProps,
      roomId: callInfo.callInInfo.roomId,
      userSig: callInfo.callInInfo.userSig,
      privateMapKey: callInfo.callInInfo.privateMapKey,
      imGroupId: callInfo.extra.imGroupId,
      autoEnter,
      nickname: callInProps.nickname,
      status: SessionStatus.ringing,
      direction: Direction.callIn,
      unifiedTRTCParams: callInfo.unifiedTRTCParams?.unified ? callInfo.unifiedTRTCParams : undefined,
    };
    emitter.Chat.upsertOne(session);
    logger.custom(
      {
        spanId,
        traceId,
        level: 'warn',
        sessionId: session.sessionId,
      },
      `[EVENTS] ${spanName}`,
    );
    if (timeout > 0) {
      manualSpan.addEvent('add timer');
      timer.once(
        session.sessionId,
        async () => {
          try {
            const res = await giveUpSession({ sessionId: session.sessionId, emitter }).then(unwrapResult);
            manualSpan
              .addEvent('tccc.events.sessionEnded', {
                sessionId: session.sessionId,
                closeBy: 'timer',
                setRest: res.setRest,
              })
              .end();
            emitter.emit('sessionEnded', {
              sessionId: session.sessionId,
              closeBy: 'timer',
              setRest: res.setRest,
              hangupType: 1,
            });
          } catch (e) {
            logger.info('giveUp failed');
          }
        },
        timeout * 1000,
        () => {
          logger.info('off callIn timer', session.sessionId);
          manualSpan.addEvent('off callIn timer', { sessionId: session.sessionId });
          manualSpan.end();
        },
      );
    } else {
      manualSpan.addEvent('no timer');
    }
    manualSpan.addEvent('emit callIn', {
      sessionId: callInProps.sessionId,
      timeout: callInProps.timeout,
    });
    if (timeout === 0) {
      manualSpan.end();
    }
    emitter.emit('callIn', callInProps);
  } catch (e) {
    manualSpan.addEvent('unexpect data', data as {});
    manualSpan.recordException(e as Error);
    manualSpan.end();
    logger.error('s2cHandler: IMCallInInvite error', e);
  }
};

const IMHangupParams = t.intersection([
  t.type({
    peerUser: t.string,
  }),
  t.partial({
    extra: t.partial({
      sessionId: t.string,
    }),
  }),
]);
export const IMHangup = async (data: unknown, emitter: TcccSdk) => {
  const logger = getLogger(emitter.Agent.userInfo);
  try {
    const { extra, peerUser } = await getOrPromiseReject(IMHangupParams)(data);
    const sessionId = extra?.sessionId || getSessionIdFromUserId(peerUser, emitter);
    if (sessionId) {
      try {
        await endIMSession({ sessionId, closeBy: 'client', emitter });
      } catch (e) {
        logger.error('s2cHandler: IMHangup failed', e);
      } finally {
        emitter.emit('sessionEnded', { sessionId, closeBy: 'system', hangupType: 1 });
      }
    } else {
      throw new Error(`failed to find session from userId: ${sessionId}`);
    }
  } catch (e) {
    logger.error('s2cHandler: IMHangup', e);
  }
};

const inviteCallStateChangedParams = t.type({
  sessionId: t.string,
  state: t.union([t.literal('accepted'), t.literal('hangup'), t.literal('failed')]),
});
export const inviteCallStateChanged = async (data: unknown, emitter: TcccSdk) => {
  const logger = getLogger(emitter.Agent.userInfo);
  try {
    const { sessionId, state } = await getOrPromiseReject(inviteCallStateChangedParams)(data);
    emitter.emit('inviteCallStateChanged', { sessionId, state });
  } catch (e) {
    logger.error('s2cHandle: inviteCallStateChanged error', e);
  }
};

const receiveStateChangedParams = t.intersection([
  t.type({
    sessionId: t.string,
    status: t.union([t.literal('error'), t.literal('timeout'), t.literal('ok')]),
  }),
  t.partial({
    keyPressed: t.string,
    clientData: t.string,
  }),
]);
export const receiveStateChanged = async (data: unknown, emitter: TcccSdk) => {
  const logger = getLogger(emitter.Agent.userInfo);
  try {
    const { sessionId, status, clientData, keyPressed } = await getOrPromiseReject(receiveStateChangedParams)(data);
    emitter.emit('receiveIvrStateChanged', {
      sessionId,
      state: status,
      clientData,
      keyPressed,
    });
  } catch (e) {
    logger.error('s2cHandle: ireceiveStateChange error', e);
  }
};

export const asrEvent = async (
  data: { roomId: number; userId: string; sessionId: string; asr: AsrResult },
  emitter: TcccSdk,
) => {
  const logger = getLogger(emitter.Agent.userInfo);
  try {
    const { roomId, asr, userId, sessionId } = data;
    const asrParser =
      asrParserMap.get(`${roomId}${userId}`) ||
      new AsrParser({
        userId,
        side: data.userId === emitter.Agent.userInfo?.userId ? 'seat' : 'client',
        sessionId,
        roomId: `${roomId}`,
        emitter,
      });
    asrParser.handleRecognizeMessages(asr);
  } catch (e) {
    logger.error('s2cHandle: asrEvent error', e);
  }
};

const IMOnlineStateChangedParams = t.type({
  sessionId: t.string,
  state: booleanFromString1or2,
});
export const IMOnlineStateChanged = async (data: unknown, emitter: TcccSdk) => {
  const logger = getLogger(emitter.Agent.userInfo);
  try {
    const { sessionId, state } = await getOrPromiseReject(IMOnlineStateChangedParams)(data);
    emitter.emit('onlineStateChanged', { sessionId, state });
  } catch (e) {
    logger.error('s2cHandle: IMOnlineStateChanged error', e);
  }
};

export const mediaEndedHandler = async (data: { sessionId: string }, emitter: TcccSdk) => {
  const logger = getLogger(emitter.Agent.userInfo);
  const { sessionId } = data;
  try {
    await endInviteMedia({ sessionId, emitter });
  } catch (e) {
    logger.error('s2cHandler: mediaEndedHandler error', e);
  } finally {
    emitter.emit('mediaEnded', { sessionId });
  }
};

export const mediaStartHandler = async (data: { sessionId: string }, emitter: TcccEventEmitter) => {
  const { sessionId } = data;
  emitter.emit('mediaAccepted', { sessionId });
};

export const mediaRejectHandler = async (data: { sessionId: string }, emitter: TcccSdk) => {
  const logger = getLogger(emitter.Agent.userInfo);
  const { sessionId } = data;
  try {
    await endInviteMedia({ sessionId, emitter });
  } catch (e) {
    logger.error('s2cHandler: mediaRejectHandler error', e);
  } finally {
    emitter.emit('mediaRejected', { sessionId });
  }
};
