// @ts-nocheck
import TIM from '@tencentcloud/chat';
import pick from 'lodash/pick';
import type { Message } from './tim';
import { TcccSdk } from '../tccc';
export const getPlainMessagePayload = ({ type, payload }: TIM.Message) => {
  switch (type) {
    case TIM.TYPES.MSG_IMAGE:
      return {
        ...pick(payload, 'uuid', 'imageFormat'),
        imageInfoArray: (payload as any).imageInfoArray.map((info: any) =>
          pick(info, 'width', 'height', 'url', 'size', 'type', 'imageUrl'),
        ),
      };
    case TIM.TYPES.MSG_FILE:
    case TIM.TYPES.MSG_TEXT:
    case TIM.TYPES.MSG_VIDEO:
    case TIM.TYPES.MSG_AUDIO:
      return pick(
        payload,
        'text',
        'downloadFlag',
        'fileName',
        'fileSize',
        'fileUrl',
        'uuid',
        'second',
        'videoFormat',
        'videoSecond',
        'videoSize',
        'videoUrl',
        'videoUUID',
        'thumbUUID',
        'thumbSize',
        'thumbWidth',
        'thumbHeight',
        'thumbUrl',
        'description',
        'url',
      );
    default:
      return JSON.parse(JSON.stringify(payload));
  }
};
export type PickPlainMessageKey =
  | 'ID'
  | 'time'
  | 'avatar'
  // | 'sequence'
  | 'nick'
  | 'from'
  | 'to'
  | 'cloudCustomData'
  | 'flow'
  | 'type'
  | 'isRevoked'
  | 'isResend'
  | 'isSystemMessage'
  | 'status'
  | 'conversationID'
  | 'payload'
  | 'sequence'
  | 'needReadReceipt'
  | 'readReceiptInfo';
export function getPlainMessage(message: TIM.Message): Omit<Message, 'sessionId'> {
  const payload = getPlainMessagePayload(message);
  return {
    ...pick(
      message,
      'ID',
      'type',
      'cloudCustomData',
      'readReceiptInfo.readCount',
      'readReceiptInfo.unreadCount',
      'needReadReceipt',
      'conversationID',
      'conversationType',
      'time',
      'sequence',
      // 'clientSequence',
      'nick',
      'avatar',
      // 'geo',
      // 'isPlaceMessage',
      'from',
      'to',
      'flow',
      'type',
      // 'isRead',
      'isRevoked',
      'isResend',
      'isSystemMessage',
      'status',
      // 'atUserList',
    ),
    payload,
  } as any as Omit<Message, 'sessionId'>;
}

export const fromUserIdFindSessionId = (userId: string, sdk: TcccSdk) =>
  sdk.Chat.selectAll().find((session) => session.userId === userId)?.sessionId;
