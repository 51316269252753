import { createSlice } from '@reduxjs/toolkit';

import { Message, TextMessage } from 'tccc-sdk';

import { StaffStatus } from 'src/services/httpAPIs/admin';

import { UPDATE_IMAGE_VIEWER } from '../actions/im';

import {
  getMessageList,
  sendMessage,
  createTextMessage,
  createFileMessage,
  createVideoMessage,
  updateTypingState,
  createImageMessage,
  addInfoMessage,
  revokeMessage,
  getInnerConversationList,
  receiveMessageThunk,
  updateUnreadCount,
  startInnerConversation,
  updateReplyInfo,
  updateImageViewer,
  sdkMinimizedChanged,
  setMessageRead,
  reportFileUpload,
  sendMessageReadReceipt,
  messageRevoked,
  messageModified,
  refreshInnerConversationStatus,
  shouldAddUnreadCountOrUpdateLastMessage,
  initMessageList,
  InitGroupInfo,
  updateGroupNotification,
  updateGroupMemberList,
  updateGroupMemberCount,
  updateReadReceipt,
} from './tim.thunk';
export type InnerConversation = {
  sessionId: string;
  userId: string;
  staffNo: string;
  nickname: string;
  skillGroupId: string;
  count: number;
  lastMessage?: Message;
  status: StaffStatus;
};
export type InfoMessage = Omit<TextMessage, 'type'> & {
  type: 'INFO';
};
export type ReplayItem = {
  replying: boolean;
  message?: Message;
};
type CombinedMessage = (Message | InfoMessage) & {
  requestId?: string;
  uploadProgress?: number;
  readReceiptInfo?: {
    unreadCount?: number;
    readCount?: number;
  };
};
type TimState = {
  messageList: Record<
    string,
    {
      list: CombinedMessage[];
      nextReqMessageID: string;
      isCompleted: boolean;
    }
  >;
  sdkMinimized: boolean;
  imReady: string;
  typingStateList: Record<string, boolean>;
  replyInfoList: Record<string, ReplayItem>;
  imageViewer: {
    visible: boolean;
    currentImageId: string;
  };
  innerConversationList: InnerConversation[];
  groupInfo: {
    sessionId: string;
    notification: string;
    memberCount: number;
    memberList: { userID: string; avatar: string; nick: string; role: string }[];
  };
};
const initialState: TimState = {
  messageList: {},
  sdkMinimized: false,
  typingStateList: {},
  imReady: 'init',
  replyInfoList: {},
  imageViewer: {
    visible: false,
    currentImageId: '',
  },
  innerConversationList: [],
  groupInfo: {
    memberCount: 0,
    sessionId: '',
    notification: '',
    memberList: [],
  },
};
export const timSlice = createSlice({
  name: 'tim',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMessageList.fulfilled, (state, action) => {
        const targetMessageItem = state.messageList[action.payload.sessionId];
        if (targetMessageItem) {
          // 如果是获取离线消息新的消息要放在列表最后，如果是拉取以前的消息，新的消息要放在列表前
          let list = [];
          if (action?.payload?.isGetOfflineMessages) {
            list = [...action.payload.messageList];
          } else {
            list = [...action.payload.messageList, ...targetMessageItem.list];
          }
          state.messageList[action.payload.sessionId] = {
            isCompleted: action.payload.isCompleted,
            nextReqMessageID: action.payload.nextReqMessageID,
            list,
          };
        } else {
          state.messageList[action.payload.sessionId] = {
            isCompleted: action.payload.isCompleted,
            nextReqMessageID: action.payload.nextReqMessageID,
            list: action.payload.messageList,
          };
        }
      })
      .addCase(initMessageList.fulfilled, (state, action) => {
        if (action.payload?.sessionId && action.payload?.messageList) {
          state.messageList[action.payload.sessionId] = {
            isCompleted: action.payload.isCompleted,
            nextReqMessageID: action.payload.nextReqMessageID,
            list: action.payload.messageList,
          };
        }
      })
      .addCase(createImageMessage.fulfilled, (state, action) => {
        const message = action.payload;
        const { sessionId } = message;
        const targetMessageItem = state.messageList[sessionId];
        if (targetMessageItem) {
          state.messageList[sessionId] = {
            ...state.messageList[sessionId],
            list: [...state.messageList[sessionId].list, message],
          };
        } else {
          state.messageList[sessionId] = {
            isCompleted: false,
            nextReqMessageID: '',
            list: [message],
          };
        }
      })
      .addCase(createFileMessage.fulfilled, (state, action) => {
        const message = { ...action.payload, requestId: action.meta.requestId, uploadProgress: 0 };
        const { sessionId } = message;
        const targetMessageItem = state.messageList[sessionId];
        if (targetMessageItem) {
          state.messageList[sessionId] = {
            ...state.messageList[sessionId],
            list: [...state.messageList[sessionId].list, message as any],
          };
        } else {
          state.messageList[sessionId] = {
            isCompleted: false,
            nextReqMessageID: '',
            list: [message as any],
          };
        }
      })
      .addCase(sendMessageReadReceipt.fulfilled, (state, action) => {
        const { sessionId, messageIds } = action.meta.arg;
        const list = state.messageList[sessionId]?.list;
        if (list) {
          const message = list?.filter((m) => messageIds.includes(m.ID));
          if (message) {
            message.forEach(
              (m: any) =>
                (m.readReceiptInfo = {
                  unreadCount: 0,
                  readCount: 1,
                }),
            );
          }
        }
      })
      .addCase(reportFileUpload, (state, { payload: { requestId, progress, sessionId } }) => {
        const list = state.messageList[sessionId]?.list;
        if (list) {
          const message = list?.find((m) => m.requestId === requestId);
          if (message) {
            message.uploadProgress = progress;
          }
        }
      })
      .addCase(createVideoMessage.fulfilled, (state, action) => {
        const message = action.payload;
        const { sessionId } = message;
        const targetMessageItem = state.messageList[sessionId];
        if (targetMessageItem) {
          state.messageList[sessionId] = {
            ...state.messageList[sessionId],
            list: [...state.messageList[sessionId].list, message],
          };
        } else {
          state.messageList[sessionId] = {
            isCompleted: false,
            nextReqMessageID: '',
            list: [message],
          };
        }
      })
      .addCase(createTextMessage.fulfilled, (state, action) => {
        const message = action.payload;
        const { sessionId } = message;
        const targetMessageItem = state.messageList[sessionId];
        if (targetMessageItem) {
          state.messageList[sessionId] = {
            ...state.messageList[sessionId],
            list: [...state.messageList[sessionId].list, message],
          };
        } else {
          state.messageList[sessionId] = {
            isCompleted: false,
            nextReqMessageID: '',
            list: [message],
          };
        }
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        const { message } = action.payload;
        const { sessionId } = message;
        const targetMessageItem = state.messageList[sessionId];
        if (targetMessageItem) {
          const existMessage = targetMessageItem.list.find((msg) => msg.ID === message.ID);
          if (existMessage) {
            Object.assign(existMessage, message);
            if ('uploadProgress' in existMessage) {
              existMessage.uploadProgress = undefined;
            }
          }
        } else {
          state.messageList[sessionId] = {
            isCompleted: false,
            nextReqMessageID: '',
            list: [message],
          };
        }
        state.innerConversationList.forEach((conv) => {
          if (conv.sessionId === sessionId && action.payload.message.type !== 'TIMCustomElem') {
            conv.lastMessage = message;
          }
          return conv;
        });
      })
      .addCase(messageRevoked.fulfilled, (state, action) => {
        const data = action.payload;
        data.forEach(({ sessionId, messageId }) => {
          const targetMessageItem = state.messageList[sessionId];
          if (targetMessageItem) {
            const item = targetMessageItem.list.find((msg) => msg.ID === messageId);
            if (item) {
              item.isRevoked = true;
            }
          }
        });
      })
      .addCase(messageModified.fulfilled, (state, action) => {
        const data = action.payload;
        data.forEach(({ sessionId, message }) => {
          const targetMessageItem = state.messageList[sessionId];
          if (targetMessageItem) {
            const index = targetMessageItem?.list?.findIndex((msg) => msg.ID === message.ID);
            if (index !== -1) {
              targetMessageItem.list[index] = { ...message };
            }
          }
        });
      })
      .addCase(revokeMessage.fulfilled, (state, action) => {
        const { message } = action.payload;
        const { sessionId } = message;
        const targetMessageItem = state.messageList[sessionId];
        if (targetMessageItem) {
          const list = targetMessageItem.list.map((msg) => {
            if (msg.ID === message.ID) {
              return {
                ...msg,
                isRevoked: message.isRevoked,
              };
            }
            return msg;
          });
          state.messageList[sessionId] = {
            ...state.messageList[sessionId],
            list,
          };
        }
      })
      .addCase(getInnerConversationList.fulfilled, (state, action) => {
        state.innerConversationList = action.payload as any;
      })
      .addCase(startInnerConversation.fulfilled, (state, action) => {
        if (!state.innerConversationList.find((item) => item.sessionId === action.payload.sessionId)) {
          state.innerConversationList.push(action.payload);
        }
      })
      .addCase(setMessageRead.fulfilled, (state, action) => {
        state.innerConversationList = state.innerConversationList.map((cur) => {
          if (cur.sessionId === action.payload?.sessionId) {
            return {
              ...cur,
              count: 0,
            };
          }
          return cur;
        });
      })
      .addCase(refreshInnerConversationStatus.fulfilled, (state, action) => {
        action.payload.forEach(({ userId, status }) => {
          const conv = state.innerConversationList.find((user) => user.userId === userId);
          if (conv && status) {
            conv.status = status;
          }
        });
      })
      .addCase(receiveMessageThunk.fulfilled, (state, action) => {
        action.payload.forEach((message) => {
          const { sessionId } = message;
          const targetMessageItem = state.messageList[sessionId];
          if (targetMessageItem) {
            state.messageList[sessionId] = {
              ...state.messageList[sessionId],
              list: [...state.messageList[sessionId].list, message],
            };
            state.innerConversationList = state.innerConversationList.map((conv) => {
              if (conv.sessionId === sessionId) {
                if (shouldAddUnreadCountOrUpdateLastMessage(message)) {
                  return {
                    ...conv,
                    lastMessage: message,
                  };
                }
              }
              return conv;
            });
          } else {
            state.messageList[sessionId] = {
              ...state.messageList[sessionId],
              list: [message],
            };
          }
        });
      })
      .addCase(updateUnreadCount, (state, action) => {
        const updateList: { id: string; count: number }[] = [];
        if (Array.isArray(action.payload)) {
          action.payload.map((item) => {
            const session = state.innerConversationList.find((session) => item?.sessionId === session.sessionId);
            if (session && session?.count !== item.count) {
              updateList.push({ id: session?.sessionId, count: item.count });
            }
          });
          if (updateList.length > 0) {
            state.innerConversationList = state.innerConversationList.map((conv) => {
              for (let i = 0; i < updateList.length; i++) {
                if (conv.sessionId === updateList[i].id) {
                  return {
                    ...conv,
                    count: updateList[i].count ?? 0,
                  };
                }
              }
              return conv;
            });
          }
        }
      })
      .addCase(addInfoMessage, (state, action) => {
        action.payload.data.forEach((message) => {
          const { sessionId } = message;
          const targetMessageItem = state.messageList[sessionId];
          if (targetMessageItem) {
            state.messageList[sessionId] = {
              ...state.messageList[sessionId],
              list: [...state.messageList[sessionId].list, message],
            };
          } else {
            state.messageList[sessionId] = {
              ...state.messageList[sessionId],
              list: [message],
            };
          }
        });
      })
      .addCase(updateReadReceipt, (state, action) => {
        if (Array.isArray(action.payload)) {
          const { sessionId } = action.payload[0];
          if (sessionId) {
            const targetMessageList = state.messageList[sessionId];
            if (targetMessageList) {
              targetMessageList.list = targetMessageList.list.map((message) => {
                for (let i = 0; i < action.payload.length; i++) {
                  if (message.ID === action.payload[i].messageID) {
                    message.readReceiptInfo = {
                      readCount: action.payload[i].readCount ?? 0,
                      unreadCount: action.payload[i].unreadCount ?? 0,
                    };
                  }
                }
                return message;
              });
            }
          }
        }
      })
      .addCase(UPDATE_IMAGE_VIEWER, (state, action: any) => ({
        ...state,
        imageViewer: {
          ...state.imageViewer,
          ...action.payload,
        },
      }))
      .addCase(updateTypingState, (state, action) => {
        state.typingStateList = {
          ...state.typingStateList,
          [action.payload.sessionId]: action.payload.state,
        };
      })
      .addCase(updateReplyInfo, (state, action) => {
        state.replyInfoList = {
          ...state.replyInfoList,
          [action.payload.sessionId]: action.payload.replyInfo,
        };
      })
      .addCase(updateImageViewer, (state, action) => ({
        ...state,
        imageViewer: {
          ...state.imageViewer,
          ...action.payload,
        },
      }))
      .addCase(sdkMinimizedChanged.fulfilled, (state, action) => {
        state.sdkMinimized = action.payload.sdkMinimized;
      })
      .addCase(InitGroupInfo.fulfilled, (state, action) => {
        if (action.payload?.sessionId === state.groupInfo.sessionId) return;
        state.groupInfo.sessionId = action.payload?.sessionId ?? '';
        state.groupInfo.memberList = [];
        state.groupInfo.notification = '';
        state.groupInfo.memberCount = 0;
      })
      .addCase(updateGroupNotification.fulfilled, (state, action) => {
        if (action?.payload?.type === 'init') {
          state.groupInfo.notification = action.payload?.notification;
        } else if (state.groupInfo.sessionId === action.payload?.sessionId) {
          state.groupInfo.notification = action.payload?.notification;
        }
      })
      .addCase(updateGroupMemberCount.fulfilled, (state, action) => {
        if (action?.payload?.type === 'init') {
          state.groupInfo.memberCount = action.payload?.memberCount;
        } else if (state.groupInfo.sessionId === action.payload?.sessionId) {
          state.groupInfo.memberCount = action.payload?.memberCount;
        }
      })
      .addCase(updateGroupMemberList.fulfilled, (state, action) => {
        if (action.payload?.type === 'init') {
          state.groupInfo.memberList = action.payload?.memberList;
        } else if (state.groupInfo.sessionId === action.payload?.sessionId) {
          switch (action.payload?.type) {
            case 'add':
              state.groupInfo.memberList = [...state.groupInfo?.memberList, ...action.payload?.memberList];
              state.groupInfo.memberCount = state.groupInfo.memberCount + action.payload?.memberList?.length;
              break;
            case 'delete':
              state.groupInfo.memberList = state.groupInfo?.memberList?.filter(
                (member) =>
                  !action.payload?.memberList?.some((payloadMember) => payloadMember?.userID === member?.userID),
              );
              state.groupInfo.memberCount = state.groupInfo.memberCount - action.payload?.memberList?.length;
              break;
            case 'update':
              state.groupInfo.memberList = state.groupInfo?.memberList?.map((member) => {
                const updatedMember = action.payload?.memberList?.find(
                  (payloadMember) => payloadMember?.userID === member?.userID,
                );
                return updatedMember || member;
              });
              break;
          }
        }
      })
      .addCase(sendMessage.rejected, (state, action) => {
        const {
          meta: {
            arg: {
              message: { sessionId, ID },
            },
          },
        } = action as any;
        const targetMessageItem = state.messageList[sessionId];
        targetMessageItem.list = targetMessageItem.list.filter((m) => m.ID !== ID);
      });
  },
});

export const tim = timSlice.reducer;
