import { useEffect, useState, useRef } from 'react';

import { makeStyles, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectBySessionId } from '../../store';

import CRMQuickReply from '../CRMComponents/CRMQuickReply/CRMQuickReply';
import { useIMHistory } from '../ServiceRecords/HistoryRecord';

import { ImList } from './im-list/ImList';
import { ImTransfer } from './im-transfer/ImTransfer';
import style from './IM.style';
import IMHeader from './IMHeader/IMHeader';
import MediaBar from './MediaBar/MediaBar';
import HistoryMessageList from './Message/MessageList/HistoryMessageList';
import MessageSend from './Message/MessageSend/MessageSend';

const useStyles = makeStyles(style);

const StyledHistoryMessageList = withStyles({
  historyMessage: {
    width: '100%',
  },
})(HistoryMessageList);

function CustomHistoryMessageList({ data }) {
  const { t } = useTranslation();
  const imContactUserEnable = useSelector((s) => s.appSettings.imContactUserEnable);
  const seatType = useSelector((s) => s.appSettings.seatType);
  const { roomId, sdkAppId, userId, sessionId, peerSource, imAgentChatType } = data;
  const historyData = useIMHistory(
    {
      roomId,
      sdkAppId,
      userId,
      userName: data?.clientData?.nickName || data?.nickname || t('用户'),
      sessionId,
      imAgentChatType: data?.imAgentChatType,
    },
    true,
  );
  return (
    <StyledHistoryMessageList
      {...historyData}
      showFollowUpBtn={
        imContactUserEnable &&
        seatType === 4 &&
        peerSource === '10' &&
        imAgentChatType !== 1 &&
        data?.imAgentChatType !== 1
      }
    />
  );
}

CustomHistoryMessageList.propTypes = {
  data: PropTypes.shape({
    roomId: PropTypes.string,
    sdkAppId: PropTypes.string,
    userId: PropTypes.string,
  }),
};
export default function Chart(props) {
  const messageSendRef = useRef(null);
  const currentUser = useSelector((state) => state.userInfo.currentUser);
  const callInfo = useSelector((state) => selectBySessionId(state, currentUser.sessionId));
  const classes = useStyles();
  const [selectIvrStatus, updateStatusFlag] = useState(false); // 是否展示Ivr技能组面板
  const [quickReply, setQuickReply] = useState(false);
  const isFromSDK = window.parent && ['/open', '/open.html'].includes(window.location.pathname);
  const { from } = queryString.parse(window.location.search);
  const isFromExperienceCenter = from === 'exp';

  const reSendMesage = (text) => {
    messageSendRef.current?.setEditorContent(text);
  };

  useEffect(() => {
    if (callInfo.status === '400') {
      updateStatusFlag(false);
    }
  }, [callInfo?.status]);

  if (!callInfo) return null;
  return (
    <div className={isFromExperienceCenter ? classes.brightChart : classes.chart}>
      {!selectIvrStatus ? (
        <div className={classes.conversation}>
          <div className={classes.IM}>
            <IMHeader info={callInfo} quickReply={quickReply} closeQuickReply={setQuickReply} />
            <MediaBar callInfo={callInfo} />
            {callInfo.status === '200' ? (
              <ImList reSend={reSendMesage} />
            ) : (
              <CustomHistoryMessageList data={callInfo} />
            )}
            {callInfo.status === '200' && (
              <MessageSend
                ref={messageSendRef}
                callInfo={callInfo}
                onTransfer={() => {
                  updateStatusFlag(true);
                }}
                quickReply={quickReply}
                onQuickReply={(value) => {
                  setQuickReply(value);
                  if (props?.onQuickReply && typeof props?.onQuickReply === 'function') {
                    props.onQuickReply(value);
                  }
                }}
              />
            )}
          </div>
          {quickReply && <CRMQuickReply className={!isFromSDK && classes.qucikReplyPanel} />}
        </div>
      ) : null}
      {selectIvrStatus && callInfo.status === '200' && (
        <ImTransfer onComplete={() => updateStatusFlag(false)} callInfo={callInfo} />
      )}
    </div>
  );
}

Chart.propTypes = {};
