import * as t from 'io-ts';
import { HangupSide } from '../../constants/sessions';
import { unifiedTRTCParams } from '../../socket/s2cEvent';
import { nullableArray, numberFromString } from 'tccc-utils';

const OptionalString = t.union([t.undefined, t.string]);

export const PhoneSessionMember = t.partial({
  userId: OptionalString,
  phone: OptionalString,
  pstnUserId: OptionalString,
  beginTimestamp: t.number,
  ringTimestamp: t.number,
  acceptTimestamp: t.number,
  enterTimestamp: t.number,
  exitTimestamp: t.number,
  hungupTimestamp: t.number,
  state: t.number,
  acceptedState: nullableArray(t.string),
});

export type SessionMemberTypes = t.TypeOf<typeof PhoneSessionMember>;

export const SessionItem = t.intersection([
  t.partial({
    userId: t.string,
    roomId: t.string,
    userSig: t.string,
    privateMapKey: t.string,
    peerSource: t.string,
    user_type: t.number,
    timestamp: t.string,
    direction: numberFromString,
    wxNick: t.string,
    wxSession: t.string,
    phone: t.string,
    phoneEncodeType: t.string,
    userPhoneLocation: t.string,
    servingNumber: t.string,
    servingNumberRemark: t.string,
    transferFrom: t.string,
    transferFromType: t.string,
    remark: t.string,
    duration: t.string,
    key_press: t.string,
    keyPress: t.string,
    clientData: t.string,
    clientChannelName: t.string,
    pre: t.string,
    avatar: t.string,
    ringTimestamp: t.string,
    acceptTimestamp: t.string,
    members: nullableArray(PhoneSessionMember),
    onLineState: t.union([t.literal('1'), t.literal('2')]),
    ivrPressLabel: t.union([t.null, t.array(t.string)]),
    protectedPhone: t.string,
    inner: t.boolean,
    imGroupId: t.string,
    lastMsg: t.any,
    currentMedia: t.union([t.undefined, t.literal('video'), t.literal('audio')]),
    autoEnter: t.union([t.undefined, t.literal('video'), t.literal('audio')]),
    hangupSide: HangupSide,
    hangupType: t.number,
    state: t.union([
      t.literal('finished'),
      t.literal('inProgress'),
      t.literal('InProgress'),
      t.literal('ringing'),
      t.literal('seatJoining'),
      t.literal(''),
    ]),
    unifiedTRTCParams,
    serverType: t.string,
    isStartRealtimeAsr: t.boolean,
    sequence: t.number,
    sessionEndState: t.type({
      hangupCode: t.number,
      hangupSide: t.string,
    }),
    sessionType: t.string,
    isHost: t.boolean,
    channelAgentID: t.union([t.undefined, t.string]),
    isIMCallOut: t.union([t.undefined, t.number]),
    imAgentChatType: t.union([t.undefined, t.number]),
  }),
  t.type({
    sessionId: t.string,
  }),
]);

export const cccAPIs = {
  '/ccc/im/getActiveSession': {
    Input: t.type({
      staff: t.type({
        sdkAppId: t.string,
        userId: t.string,
      }),
    }),
    Output: t.type({
      sessionList: nullableArray(SessionItem),
    }),
  },
  '/ccc/report/seatrecord': {
    Input: t.type({
      userId: t.string,
      roomId: t.string,
      fileData: t.string,
      seatUserId: t.string,
      fileName: t.string,
      chromeCollectError: t.boolean,
      chromeConnectionError: t.boolean,
    }),
    Output: t.type({
      filePath: t.string,
    }),
  },
  '/ccc/report/seatNetworkQuality': {
    Input: t.type({
      roomId: t.number,
      networkQuality: t.type({
        uplinkNetworkQuality: t.number,
        downlinkNetworkQuality: t.number,
        uplinkRTT: t.number,
        downlinkRTT: t.number,
        uplinkLoss: t.number,
        downlinkLoss: t.number,
      }),
    }),
    Output: t.type({}),
  },
  '/ccc/report/seatConnectionState': {
    Input: t.type({
      roomId: t.number,
      connectionState: t.number,
    }),
    Output: t.type({}),
  },
  '/ccc/queryFeedbackQueue': {
    Input: t.type({}),
    Output: t.type({
      sessions: nullableArray(
        t.type({
          sessionId: t.string,
          userId: t.string,
          peerSource: t.string,
          wxNick: t.string,
          timestamp: numberFromString,
          skillId: t.string,
          roomId: t.string,
          imGroupId: t.string,
        }),
      ),
    }),
  },
  '/ccc/assignFeedbackSession': {
    Input: t.type({
      userId: t.string,
      sessions: t.array(
        t.type({
          skillId: t.string,
          roomId: t.string,
          sessionId: t.string,
        }),
      ),
    }),
    Output: t.type({}),
  },
  '/ccc/remark/setRemark': {
    Input: t.type({
      sessionId: t.string,
      remark: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/black/addBlackUser': {
    Input: t.type({
      sessionId: t.string,
    }),
    Output: t.type({}),
  },
  '/ccc/im/getIMUserProfile': {
    Input: t.type({
      UserIDs: t.array(t.string),
    }),
    Output: t.type({
      UserProfileItems: t.array(
        t.type({
          UserID: t.string,
          ErrorCode: t.number,
          Nick: t.string,
        }),
      ),
    }),
  },
  // im座席对用户发起会话
  '/ccc/im/startCallOut': {
    Input: t.type({
      channelAgentID: t.string,
      ClientUserID: t.string,
      isFromRecord: t.boolean,
    }),
    Output: t.type({
      errorCode: t.string,
    }),
  },
};
