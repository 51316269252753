import React, { useEffect, useState, useRef } from 'react';

import { Tooltip, withStyles } from '@material-ui/core';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import CustomerIcon from 'src/assets/images/customerAvatar.svg';

import { updateGroupMemberList } from 'src/store/slices/tim.thunk';
import tccc from 'src/utils/tccc';

import logger from '../../utils/logger';
interface GroupMemberListProps {
  conversationID: string;
  memberCount: number;
  memberList: { userID: string; avatar: string; nick: string; role: string }[];
  sessionId: string;
}

const GroupMemberListContainer = styled.div`
  padding: 12px 12px 0 12px;
  height: calc(100% - 300px);
  width: 100%;
  overflow-y: auto;
`;

const GroupMemberListTitle = styled.div`
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
`;

const GroupMemberItem = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  width: calc(100% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
`;

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 320,
    fontSize: 12,
    border: '1px solid #dadde9',
    padding: '12px',
  },
}))(Tooltip);

const GroupMemberList: React.FC<GroupMemberListProps> = React.memo(
  ({ conversationID, memberCount, memberList, sessionId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [offset, setOffset] = useState(0);
    const [isFinish, setIsFinish] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const groupID = conversationID.replace('GROUP', '');

    const handleScroll = throttle(() => {
      const container = containerRef.current;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        if (scrollHeight - scrollTop - clientHeight <= 100) {
          if (!isFinish && !isLoading) {
            getMoreGroupMemberList(offset);
          }
        }
      }
    }, 200); // 节流时间间隔为200毫秒

    const initGroupMemberList = async (offset: number) => {
      try {
        setIsLoading(true);
        const groupMemberListRes = await tccc.Chat.getGroupMemberList({ groupID, count: 100, offset });
        if (Array.isArray(groupMemberListRes?.data?.data?.memberList)) {
          await dispatch(
            updateGroupMemberList({
              sessionId,
              memberList: groupMemberListRes.data.data.memberList,
              type: 'init',
            }),
          );
          setOffset(groupMemberListRes?.data?.data?.offset);
          if (groupMemberListRes?.data?.data?.offset === 0) {
            setIsFinish(true);
          }
        }
        setIsLoading(false);
      } catch (e) {
        logger.error('initGroupMemberList', e);
        dispatch(updateGroupMemberList({ sessionId, memberList: [], type: 'init' }));
        setIsFinish(true);
      }
    };

    const getMoreGroupMemberList = async (offset: number) => {
      try {
        if (isLoading || isFinish) {
          return;
        }
        setIsLoading(true);
        const groupMemberListRes = await tccc.Chat.getGroupMemberList({ groupID, count: 100, offset });
        if (Array.isArray(groupMemberListRes?.data?.data?.memberList)) {
          await dispatch(
            updateGroupMemberList({
              sessionId,
              memberList: groupMemberListRes.data.data.memberList,
              type: 'add',
            }),
          );
          setOffset(groupMemberListRes?.data?.data?.offset ?? 0);
          if (groupMemberListRes?.data?.data?.offset === 0) {
            setIsFinish(true);
          }
        }
        setIsLoading(false);
      } catch (e) {
        logger.error('getMoreGroupMemberListError', e);
        dispatch(updateGroupMemberList({ sessionId, memberList: [], type: 'init' }));
        setIsFinish(true);
      }
    };

    useEffect(() => {
      setOffset(0);
      setIsLoading(false);
      setIsFinish(false);
      initGroupMemberList(0);
    }, [conversationID, sessionId]);

    useEffect(() => {
      const container = containerRef.current;
      if (container) {
        container.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (container) {
          container.removeEventListener('scroll', handleScroll);
        }
      };
    }, [containerRef, handleScroll]);

    return (
      <GroupMemberListContainer ref={containerRef}>
        <GroupMemberListTitle>
          {t('群成员')}·{memberCount}
        </GroupMemberListTitle>
        {memberList?.map((member) => (
          <HtmlTooltip
            interactive
            title={
              <div style={{ display: 'flex' }}>
                <img
                  style={{ width: '70px', height: '70px' }}
                  src={member?.avatar && member?.avatar !== '' ? member.avatar : CustomerIcon}
                />
                <div style={{ marginLeft: '10px' }}>
                  <div style={{ fontSize: '16px', wordBreak: 'break-all' }}>{member?.userID}</div>
                  <div style={{ fontSize: '12px', marginTop: '10px', color: '#999', wordBreak: 'break-all' }}>
                    {member?.nick}
                  </div>
                </div>
              </div>
            }
            placement="left-end"
          >
            <GroupMemberItem>
              {member?.nick}({member?.userID})
            </GroupMemberItem>
          </HtmlTooltip>
        ))}
      </GroupMemberListContainer>
    );
  },
  (prevProps, nextProps) =>
    // 比较三个参数是否都发生了变化
    prevProps.conversationID === nextProps.conversationID &&
    prevProps.memberCount === nextProps.memberCount &&
    JSON.stringify(prevProps.memberList) === JSON.stringify(nextProps.memberList),
);

export default GroupMemberList;
