//  1: 电话呼入邀请，attendee是主叫 2: 通知客户座席信息，attendee是座席信息 3: 对方振铃 4: 对方接听 5: 对方挂断 100:没有心跳断线 101:多处登录踢下线
import i18n from 'src/i18n';
const { t } = i18n;

const getConfig = {
  // 会话来源
  peerSource: {
    0: t('电话'), // pstn user
    1: t('内线'), // 座席账号
    2: t('微信小程序'), // 微信小程序(自定义)
    3: t('微信公众号'), // 公众号
    4: t('网站渠道'), // 网站用户
    5: t('微信公众号'), // 订阅号
    6: t('微信小程序'), // 服务号小程序(标准)
    7: t('微信客服'), // 微信客服
    8: t('安卓'),
    10: t('即时通信IM渠道'),
    11: 'WhatsApp',
  },
  agentType: {
    offline: t('已下线'),
    free: t('空闲中'),
    busy: t('忙碌中'),
    rest: t('小休中'),
    countdown: t('话后整理'),
    arrange: t('话后整理'),
    notReady: t('示忙中'),
    disconnect: t('网络断开，重连中'),
  },
  callInfoText: {
    phone: t('请求电话通话'),
    dialBack: t('请求电话通话'),
    audio: t('请求语音通话'),
    video: t('请求视频通话'),
    im: t('请求消息接入'),
    voip: t('请求音频通话'),
  },
  /**
   * 已废弃状态，服务记录电话还在使用
   */
  callStatus: {
    0: t('异常结束'),
    1: t('正常通话'),
    2: t('未接通'),
    3: t('未接入'),
    4: t('拒接挂断'),
    5: t('关机'),
    6: t('空号'),
    7: t('通话中'),
    8: t('欠费'),
    9: t('运营商线路或平台异常'),
    10: t('外呼时，主叫提前挂断'),
    11: t('不在服务区'),
    12: t('来电提醒'),
    13: t('呼叫转移'),
    14: t('停机'),
    15: t('不要挂机'),
    16: t('无人接入'),
    17: t('超时转接'),
    100: t('黑名单用户呼入'),
  },
  newCallStatus: {
    0: t('异常结束'),
    1: t('正常通话'),
    2: t('未接通'),
    3: t('无座席在线'),
    4: t('用户离线'),
    5: t('转人工前放弃'),
    6: t('座席未接入'),
    17: t('超时转接'),
    100: t('黑名单用户呼入'),
    101: t('手动转接'),
    102: t('IVR 阶段放弃'),
    103: t('队列等待时放弃'),
    104: t('座席振铃时放弃'),
    106: t('非工作时间'),
    107: t('IVR 后直接结束'),
    105: t('无座席在线结束'),
    108: t('超时自动结束'),
    109: t('用户主动结束'),
    110: t('用户超时结束'),
    111: t('客服主动结束'),
    112: t('客服超时结束'),
    113: t('机器人接待'),
  },
  pstnEndStatus: {
    0: t('错误'),
    1: t('正常结束'),
    2: t('未接通'),
    17: t('座席未接'),
    100: t('黑名单'),
    101: t('座席转接'),
    102: t('IVR 期间用户放弃'),
    103: t('会话排队期间用户放弃'),
    104: t('会话振铃期间用户放弃'),
    105: t('无座席在线'),
    106: t('非工作时间'),
    107: t('IVR 后直接结束'),
    108: t('本地黑名单'),
    109: t('本地超频限制'),
    110: t('本地呼叫区域限制'),
    111: t('本地呼叫时间限制'),
    201: t('未知状态'),
    202: t('未接听'),
    203: t('拒接挂断'),
    204: t('关机'),
    205: t('空号'),
    206: t('占线'),
    207: t('欠费'),
    208: t('运营商线路异常'),
    209: t('主叫取消'),
    210: t('不在服务区'),
    211: t('客户端错误'),
  },
  seatStatus: {
    0: t('离线'),
    100: t('空闲'),
    200: t('忙碌'),
    300: t('小休'),
    400: t('话后整理'),
    500: t('话后整理'),
    700: t('示忙'),
  },
  monitorSeatStatus: {
    0: t('离线'),
    100: t('空闲'),
    200: t('忙碌'),
    300: t('小休'),
    400: t('话后整理'),
    500: t('话后整理'),
    700: t('示忙'),
  },
  HungUpSideEnum: {
    seat: t('座席'),
    user: t('客户'),
    system: t('系统'),
    admin: t('管理员'),
  },
  statusColor: {
    '-1': '#999',
    100: '#3FCFB8',
    free: '#3FCFB8',
    150: '#E54545',
    busy: '#E54545',
    300: '#006EFF',
    rest: '#006EFF',
    400: '#E54545',
    countdown: '#E54545',
    500: '#E54545',
    arrange: '#E54545',
    700: '#FC4F05',
    notReady: '#FC4F05',
    900: '#E54545',
    offline: '#999',
  },
  sessionStartType: {
    0: t('用户主动联系'),
    1: t('座席主动联系'),
    2: t('群聊'),
  },
};
export default getConfig;
