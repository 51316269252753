import { useState } from 'react';

import { styled, Link } from '@material-ui/core';
import { TIMSDK as TIM } from 'tccc-sdk';

import { decodeText } from 'src/components/IM/im-elements/text-element/decodeText';
import { ImageViewer } from 'src/components/ImageViewer/ImageViewer';
import i18n from 'src/i18n';

import logger from './logger';

const { t } = i18n;

const TextContainer = styled('div')({
  display: 'inline-block',
  wordBreak: 'break-all',
  lineClamp: '2',
  textOverflow: 'ellipsis',
  '& .text-box': {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    lineClamp: '2',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'fit-content',
    maxWidth: '200px',
  },
  '& .text-img': {
    width: '17px',
    height: '17px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

export function parseImageMessageUrl(data) {
  const imageInfo = data.imageInfoArray.find((item) => item.url);
  const url = imageInfo ? imageInfo.url : '';
  if (typeof url !== 'string') {
    return '';
  }
  return url.slice(0, 2) === '//' ? `https:${url}` : url;
}

export function paresMessageImageSet(data) {
  try {
    return {
      thumbnail: data.imageInfoArray[1]?.url || data.imageInfoArray[0]?.url,
      '720P': data.imageInfoArray[2]?.url || data.imageInfoArray[0]?.url,
      raw: data.imageInfoArray[0]?.url,
    };
  } catch {
    // do nothing
  }
}
const ImageInReference = ({ message }) => {
  const [modalIsOpen, setModalOpen] = useState(false);

  function toggleModal() {
    setModalOpen(!modalIsOpen);
  }
  const [raw, thumbnail] = message.payload.imageInfoArray;
  const imageDp = {
    ...message,
    src: thumbnail?.imageUrl || thumbnail?.url || raw?.imageUrl || raw?.url,
    alt: message.payload.uuid,
    downloadUrl: raw?.imageUrl || raw?.url,
  };
  return (
    <Link onClick={toggleModal}>
      {t('[图片消息]')}
      <ImageViewer images={[imageDp]} visible={modalIsOpen} onClose={() => setModalOpen(false)} activeIndex={0} />
    </Link>
  );
};
export function getReplyMessage(message) {
  let replyMessage = '';
  switch (message.type) {
    case TIM.TYPES.MSG_TEXT: {
      const renderDom = decodeText(message?.payload);
      return (
        <TextContainer>
          {renderDom.map((item, index) => {
            if (item.name === 'text') {
              return (
                <div className="text-box" key={index}>
                  {item.text}
                </div>
              );
            }
            if (item.name === 'img') {
              return <img className="text-img" key={index} src={item.src} alt="" />;
            }
            return null;
          })}
        </TextContainer>
      );
    }
    case TIM.TYPES.MSG_IMAGE:
      return <ImageInReference message={message} />;
    case TIM.TYPES.MSG_VIDEO:
      return t('[视频消息]');
    case TIM.TYPES.MSG_SOUND:
      return t('[语音消息]');
    case TIM.TYPES.MSG_GEO:
      return t('[位置消息]');
    case TIM.TYPES.MSG_CUSTOM:
      return t('[自定义消息]');
    case TIM.TYPES.MSG_FILE:
      replyMessage = t('[文件]{{0}}', { 0: message.payload.fileName });
      break;
    default:
      replyMessage = t('未知消息');
  }
  return replyMessage;
}

export function getReplyMessageAbstract(message) {
  let replyMessage = '';
  switch (message.type) {
    case TIM.TYPES.MSG_TEXT:
      return message?.payload?.text || '';
    case TIM.TYPES.MSG_IMAGE:
      return t('[图片消息]');
    case TIM.TYPES.MSG_VIDEO:
      return t('[视频消息]');
    case TIM.TYPES.MSG_SOUND:
      return t('[语音消息]');
    case TIM.TYPES.MSG_GEO:
      return t('[位置消息]');
    case TIM.TYPES.MSG_CUSTOM:
      return t('[自定义消息]');
    case TIM.TYPES.MSG_FILE:
      replyMessage = t('[文件]{{0}}', { 0: message.payload.fileName });
      break;
    default:
      replyMessage = t('未知消息');
  }
  return replyMessage;
}

export function getReplyMessageOriginType(message) {
  switch (message.type) {
    case TIM.TYPES.MSG_TEXT:
      return 1;
    case TIM.TYPES.MSG_IMAGE:
      return 3;
    case TIM.TYPES.MSG_VIDEO:
      return 5;
    case TIM.TYPES.MSG_SOUND:
      return 4;
    case TIM.TYPES.MSG_CUSTOM:
      return 2;
    case TIM.TYPES.MSG_FILE:
      return 6;
    default:
      return 0;
  }
}

export function getMessageFrom(item, callInfo, userInfo) {
  const { isSystemMessage } = item;
  if (!item.sessionId) {
    logger.warn(t('消息不含sessionId'), JSON.stringify(item));
  }
  if (isSystemMessage) {
    return t('系统消息');
  }
  if (item.flow === 'out') {
    // 系统消息
    if (item.from === t('系统消息')) {
      return item.from;
    }
    if (!item.nickName || item.nickName === userInfo.nickName) {
      return userInfo.nickName;
    }
    // 系统消息
    return item.from;
  }
  if (item.sessionId?.startsWith('C2C')) {
    return item.sessionId.replace(/C2C/, '');
  }
  if (item.flow === 'in') {
    if (item.nickName || item.nick) {
      return item.nickName || item.nick;
    }
    if (callInfo.imAgentChatType === 1 && item?.from) {
      return item?.from;
    }
  }
  return callInfo?.clientData?.nickName || callInfo?.nickName || callInfo?.nickname;
}
