import { useState } from 'react';

import { List, Popover, DropdownBox } from '@tencent/tea-component';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TIMSDK as TIM } from 'tccc-sdk';

import msgCopy from 'src/assets/images/imActionIcon/msg-copy.svg';
import msgQnote from 'src/assets/images/imActionIcon/msg-qnote.svg';
import msgRevoke from 'src/assets/images/imActionIcon/msg-revoke.svg';
import { updateReplyInfo, revokeMessage } from 'src/store/slices/tim.thunk';
import { getMessageFrom, getReplyMessageAbstract, getReplyMessageOriginType } from 'src/utils/message';

export const ImActions = (props) => {
  const { children, message, callInfo } = props;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  function getReplaySimplifiedMessage(message) {
    return {
      ID: message.ID,
      payload: message.payload,
      customFrom: message?.from,
      customType: 'REPLY_MESSAGE_REF',
      messageSenderNick: message?.nick,
      type: message.type,
      originType: getReplyMessageOriginType(message),
      messageTime: message?.time,
      messageSequence: message?.sequence,
      messageAbstract: getReplyMessageAbstract(message),
    };
  }
  const revokeCommand = {
    name: t('撤回'),
    icon: msgRevoke,
    execute: (message) => {
      dispatch(revokeMessage({ message }));
    },
    canExecute: (message) =>
      message.flow === 'out' && !message.isSystemMessage && Date.now() / 1000 - message.time <= 2 * 60,
  };
  const replyCommand = {
    name: t('引用'),
    icon: msgQnote,
    execute: (message, dispatch) => {
      dispatch(
        updateReplyInfo({
          sessionId: message.sessionId,
          replyInfo: {
            replying: true,
            message: getReplaySimplifiedMessage(message),
            from: getMessageFrom(message, callInfo, userInfo),
          },
        }),
      );
      // imSenderFocused();
    },
    canExecute: () => true,
  };

  const copyCommand = {
    name: t('复制'),
    icon: msgCopy,
    execute: (message) => {
      copy(message.payload.text);
    },
    canExecute: () => message.type === TIM.TYPES.MSG_TEXT,
  };
  return callInfo?.type === 'im' && callInfo?.status === '200' ? (
    <Popover
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
      trigger="contextMenu"
      overlay={
        <DropdownBox adaptable style={{ borderRadius: 8, minWidth: 'unset', padding: '10px 0' }}>
          <List type="option">
            {[copyCommand, revokeCommand, replyCommand]
              .filter((action) => action.canExecute(message))
              .map((action) => (
                <List.Item
                  key={action.name}
                  children={action.name}
                  onClick={() => {
                    action.execute(message, dispatch);
                    setVisible(false);
                  }}
                  style={{ display: 'flex', alignItems: 'end', fontSize: 12, fontWeight: 500 }}
                >
                  <img style={{ width: 20, height: 20, paddingRight: 4 }} src={action.icon} />
                  {action.name}
                </List.Item>
              ))}
          </List>
        </DropdownBox>
      }
    >
      {children}
    </Popover>
  ) : (
    children
  );
};
