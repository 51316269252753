import React from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CustomerIcon from 'src/assets/images/customerAvatar.svg';
import RobotIcon from 'src/assets/images/robotAvatar.svg';
import SeatIcon from 'src/assets/images/seatAvatar.svg';
import { Session } from 'src/store/slices/sessions';
import { UserInfo } from 'src/store/slices/userInfo';

import { isGroupChat, showError } from 'src/utils';

import { ImActions } from '../../im-actions/ImActions';
import { ImReplyInList } from '../../im-elements/text-element/TextElement.style';

import {
  ImAsideWrapper,
  ImAsideHeader,
  ImAsideBody,
  ImAsideContent,
  ImAsideStatus,
  Avatar,
  MessageTime,
  ImAsideReadStatus,
} from './imAside.style';

// Add types for message, list, and callInfo
type MessageType = {
  flow: string;
  avatar?: string;
  from?: string;
  isSystemMessage: boolean;
  needReadReceipt: boolean;
  readReceiptInfo: { readCount: number };
  ID: string;
  cloudCustomData?: string;
  time: number;
  type: string;
  detail?: string;
};
type ListType = MessageType[];

type CallInfoType = Session;
type UserInfoType = UserInfo;
type ImAsideProps = {
  message: MessageType;
  content: React.ReactNode;
  header?: React.ReactNode;
  status?: React.ReactNode;
  callInfo: CallInfoType;
  userInfo: UserInfoType;
  renderBackground?: boolean;
  list?: ListType;
};
const getAvatar = (message: MessageType) => {
  const isFlowIn = message.flow === 'in';
  const location = isFlowIn ? 'left' : 'right';
  if (isFlowIn) {
    return {
      location,
      children: <img alt="" src={message.avatar || CustomerIcon} />,
    };
  }
  return {
    location,
    children: (
      <img
        src={message.from?.includes('@') ? SeatIcon : RobotIcon}
        alt={message.from?.includes('@') ? 'seat avatar' : 'robot avatar'}
      />
    ),
  };
};

function getMessageTime(timestamp: number): string {
  const time = moment.unix(timestamp);
  if (time.isSameOrBefore(moment().startOf('year'))) {
    return time.format('YYYY-MM-DD HH:mm:ss');
  }
  // if (time.isSameOrAfter(moment().startOf('isoWeek'))) {
  //   return `星期${['天', '一', '二', '三', '四', '五', '六'][time.get('day')]} ${time.format('HH:mm:ss')}`;
  // }
  return time.format('MM-DD HH:mm:ss');
}

export const ImAside: React.FC<ImAsideProps> = (props) => {
  const { t } = useTranslation();
  const appSettings = useSelector((state) => state.appSettings);
  const { message, content, header, status, callInfo, userInfo, renderBackground, list } = props;
  const { flow: messageFlow, isSystemMessage, from } = message;
  const flow = isSystemMessage || from === t('系统消息') || from === 'administrator' ? 'out-others' : messageFlow;
  const messageUnread =
    flow === 'in' && message.needReadReceipt && (message.readReceiptInfo.readCount > 0 ? undefined : message.ID);
  const messageNeedShowReadStatus =
    (flow === 'out' || flow === 'out-others') &&
    message.needReadReceipt &&
    appSettings.staffShowReadReceipt === 1 &&
    ['4', '10'].includes(callInfo?.peerSource ?? '0') &&
    !isGroupChat(callInfo);
  let replyMessage: {
    messageID: string;
    messageAbstract: string;
    messageSender: string;
    messageSenderNick?: string;
    messageType: number;
  } | null = null;

  if (!Object.prototype.hasOwnProperty.call(message, 'cloudCustomData')) {
    try {
      if (JSON.parse(message.detail ?? '').CloudCustomData)
        message.cloudCustomData = JSON.parse(message.detail ?? '').CloudCustomData;
    } catch (e) {
      console.log(e);
    }
  }

  if (message.cloudCustomData && list) {
    try {
      const refMessageFromCloud: any = JSON.parse(message.cloudCustomData)?.messageReply;
      if (refMessageFromCloud) {
        replyMessage = {
          ...refMessageFromCloud,
        };
        if (replyMessage?.messageAbstract === '') {
          switch (refMessageFromCloud?.messageType) {
            case 3:
              replyMessage.messageAbstract = t('[图片消息]');
              break;
            case 4:
              replyMessage.messageAbstract = t('[语音消息]');
              break;
            case 5:
              replyMessage.messageAbstract = t('[视频消息]');
              break;
            case 6:
              replyMessage.messageAbstract = t('[文件消息]');
              break;
            case 2:
              replyMessage.messageAbstract = t('[自定义消息]');
              break;
          }
        }
      }
    } catch {
      replyMessage = null;
    }
  }
  return (
    <ImAsideWrapper
      style={{ marginTop: 30 }}
      messageType={message.type}
      renderBackground={renderBackground}
      flow={flow}
      data-message-id={message.ID}
      {...(messageUnread
        ? {
            'data-unread': true,
          }
        : undefined)}
    >
      {header && (
        <ImAsideHeader>
          {header} <MessageTime>{getMessageTime(message.time)}</MessageTime>
        </ImAsideHeader>
      )}
      <ImActions callInfo={callInfo} userInfo={userInfo} message={message}>
        <ImAsideBody>
          <Avatar {...getAvatar({ ...message, flow })} />
          {messageNeedShowReadStatus && (
            <ImAsideReadStatus>
              {message?.readReceiptInfo?.readCount > 0 ? (
                <span style={{ color: '#b6b8ba' }}>{t('已读')}</span>
              ) : (
                <span style={{ color: '#679ce1' }}>{t('未读')}</span>
              )}
            </ImAsideReadStatus>
          )}
          {flow === 'in' && <ImAsideContent>{content}</ImAsideContent>}
          {status && <ImAsideStatus>{status}</ImAsideStatus>}
          {(flow === 'out' || flow === 'out-others') && <ImAsideContent>{content}</ImAsideContent>}
        </ImAsideBody>
      </ImActions>
      {replyMessage && (
        <ImReplyInList
          onJump={() => {
            if (callInfo?.status === '200') {
              const container = document.querySelector('#messageBox');
              if (container) {
                const messageElement = container.querySelector(`[data-message-id="${replyMessage?.messageID}"]`);
                if (messageElement) {
                  messageElement.scrollIntoView({ block: 'center', inline: 'nearest' });
                } else {
                  return showError(t(`未找到引用消息`));
                }
              }
            }
          }}
          messageContent={replyMessage?.messageAbstract}
          from={
            replyMessage?.messageSenderNick && replyMessage?.messageSenderNick !== ''
              ? replyMessage?.messageSenderNick
              : replyMessage?.messageSender
          }
        />
      )}
    </ImAsideWrapper>
  );
};
