import { useState, useMemo } from 'react';

import loadable from '@loadable/component';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import CallImg from 'src/assets/images/empty-2d07e482c4bfd684cce0ee2b03c83354da22e7e3.svg';
import EmptyMsg from 'src/assets/images/empty-msg.jpg';
import NullImgSmall from 'src/assets/images/null-img-s.svg';
import GroupChatProfile from 'src/components/GroupChatProfile/GroupChatProfile';
import IM from 'src/components/IM/IM';
import InnerIM from 'src/components/IM/InnerIM';
import { isDeskSeat, isShowGroupChatProfile } from 'src/utils';
import logger from 'src/utils/logger';

import { selectBySessionId, sessionsSelector } from '../../../store';
import { isPhoneLikeType } from '../../../store/slices/sessions';

import { AsyncPhoneCard as PhoneCard } from './Content/AsyncPhoneCard';
import { VideoCard } from './Content/VideoCard/VideoCard';
import useLayouts from './useLayouts';
import { useStyles } from './Workbench.style';

const UserList = loadable(() => import('src/components/UserList/UserList'));

const CRM = loadable(() => import('../../../components/CRM/CRM'), {
  fallback: null,
});

const SaasDemo = loadable(() => import('../../../components/SaasDemo/SaasDemo'), {
  fallback: <Skeleton width={'100%'} height="100%" />,
});
const SaasDemoLogin = loadable(() => import('../../../components/SaasDemo/SaasDemoLogin'), {
  fallback: <Skeleton width={'100%'} height="100%" />,
});
export default function Workbench() {
  const location = useLocation();
  const callInfoList = useSelector(sessionsSelector.selectAll);
  const { crmEnabled, crmGray, seatType, imAppType } = useSelector((state) => state.appSettings);
  const { currentUser } = useSelector((state) => state.userInfo);
  const callInfo = useSelector((state) => selectBySessionId(state, currentUser.sessionId));
  const innerConversationList = useSelector((state) => state.tim.innerConversationList);
  const hasInnerIM = innerConversationList.find((item) => item.sessionId === currentUser?.sessionId);
  const { ref, invalidate } = useLayouts();
  const { hideHeader, from } = queryString.parse(location.search);
  const isFromExperienceCenter = from === 'exp';
  const isSaasDemoPage = location.pathname.includes('/demo');
  const classes = useStyles({ hideHeader, isSaasDemoPage });
  const [hasRightPopups, setHasRightPopups] = useState(false);
  const [showIframeActions, setShowIframeActions] = useState(true);
  const [iframeWidth, setIframeWidth] = useState(crmEnabled || crmGray ? 500 : '50%');
  const { t } = useTranslation();

  const isDesk = useMemo(() => isDeskSeat(seatType, imAppType), [imAppType, seatType]);
  const showGroupProfile = useMemo(() => isShowGroupChatProfile(callInfo), [callInfo]);
  return (
    <Box className={`${classes.root} ${classes.content}`}>
      <div ref={ref.listRef} className={classes.userList}>
        <UserList />
      </div>
      <div ref={ref.messageRef} className={isFromExperienceCenter ? classes.expMessage : classes.message}>
        {!callInfo && !hasInnerIM && (
          <div className={classes.nullMessage}>
            <img
              className={classes.nullImg}
              draggable="false"
              width={240}
              height={180}
              src={isFromExperienceCenter ? NullImgSmall : EmptyMsg}
              alt=""
            />
            <div className={classes.nullTitle}>{t('暂无消息')}</div>
          </div>
        )}
        {callInfo && !['100', '150'].includes(callInfo.status) && callInfo.type === 'im' && (
          <IM
            onQuickReply={(value) => {
              try {
                if (value && isDesk) {
                  const crmElement = ref.crmRef.current;
                  const messageElement = ref.messageRef.current;
                  const splitLineElement = ref.splitLineRef.current;
                  if (crmElement && messageElement && splitLineElement) {
                    const crmWidth = crmElement.offsetWidth;
                    if (crmWidth < 650) {
                      crmElement.style.width = '650px';
                      const difference = 650 - crmWidth;
                      const messageWidth = messageElement.offsetWidth;
                      messageElement.style.width = `${messageWidth - difference}px`;
                      const { transform } = splitLineElement.style;
                      let translateX = 0;
                      if (transform) {
                        const match = transform.match(/translateX\(([-\d.]+)px\)/);
                        if (match) {
                          translateX = parseFloat(match[1]);
                          const newTranslateX = translateX - difference;
                          splitLineElement.style.transform = `translateX(${newTranslateX}px)`;
                        }
                      }
                    }
                  }
                }
              } catch (e) {
                logger.error(`onQuickReplyError`, e);
              }
            }}
          />
        )}
        {callInfo && isPhoneLikeType(callInfo.type) && <PhoneCard sessionId={callInfo.sessionId} />}
        {callInfo?.type === 'video' && callInfo?.status !== '200' && (
          <VideoCard callInfo={callInfo} selected={currentUser?.sessionId === callInfo.sessionId} />
        )}
        {/* 进行中的Video会话常显 */}
        {callInfoList
          .filter((callInfo) => callInfo.type === 'video' && callInfo.status === '200')
          .map((callInfo) => (
            <VideoCard
              key={callInfo.sessionId}
              callInfo={callInfo}
              selected={currentUser?.sessionId === callInfo.sessionId}
            />
          ))}
        {hasInnerIM && <InnerIM />}
      </div>
      {!isFromExperienceCenter && <div className={classes.splitLine} ref={ref.splitLineRef} />}
      <div ref={ref.dragModalRef} className={classes.dragModal} />
      {!isFromExperienceCenter ? (
        <div
          ref={ref.crmRef}
          className={classes.CRMWrapper}
          style={{ minWidth: Number(iframeWidth) > 0 ? Number(iframeWidth) : isDesk ? '240px' : '50%' }}
        >
          {isSaasDemoPage ? (
            <>
              <SaasDemo />
              <SaasDemoLogin />
            </>
          ) : showGroupProfile ? (
            <GroupChatProfile session={callInfo}></GroupChatProfile>
          ) : (
            <>
              <CRM
                callInfo={callInfo}
                hasIframeCallback={(hasIframes, isCRM, newWidth) => {
                  setHasRightPopups(hasIframes);
                  setShowIframeActions(!isCRM);
                  setIframeWidth(newWidth);
                  invalidate();
                }}
              >
                <div className={classes.nullCRM}>
                  <img className={classes.nullImg} width={240} height={180} src={CallImg} alt="" />
                  {/* 只能客服暂时没有设置来电弹屏的功能 */}
                  {!isDesk && (
                    <div className={classes.nullTitle}>
                      <a
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                        rel="noreferrer"
                        href="https://cloud.tencent.com/document/product/679/76624"
                      >
                        {t('接入来电弹屏')} »
                      </a>
                    </div>
                  )}
                </div>
              </CRM>
              <div
                className={classes.crmAction}
                ref={ref.crmActionRef}
                style={{ visibility: showIframeActions ? 'visible' : 'hidden' }}
              >
                <div ref={ref.dragRef} style={{ visibility: 'hidden' }} className={classes.drag} />
                <div ref={ref.collapseRef} className={`${classes.crmIcon}  ${classes.collapse}`} />
                <div ref={ref.expandRef} className={`${classes.crmIcon}  ${classes.expand}`} />
                <div
                  style={{ display: hasRightPopups ? 'block' : 'none' }}
                  ref={ref.zoomInIconRef}
                  className={`${classes.crmIcon} ${classes.zoomIn}`}
                />
                <div
                  style={{ display: 'none' }}
                  ref={ref.zoomOutRef}
                  className={`${classes.crmIcon} ${classes.zoomOut}`}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <SaasDemo isFromExperienceCenter={isFromExperienceCenter} />
      )}
    </Box>
  );
}
