import React from 'react';

import { styled } from '@material-ui/core';
import DOMPurify from 'dompurify';
import { marked } from 'marked';

type UserCustomElementProps = {
  data: {
    content: string;
  };
};

const RichText = styled('div')({
  '& img': {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
    marginTop: '6px',
  },
});

export const ImRobotElement: React.FC<UserCustomElementProps> = (props) => {
  try {
    if (typeof props?.data?.content !== 'string') {
      return <div style={{ padding: '12px' }}>Markdown Error. Please check markdown content.</div>;
    }
    const htmlString = marked(props?.data?.content);
    const cleanContent = DOMPurify.sanitize(htmlString);
    return <RichText dangerouslySetInnerHTML={{ __html: cleanContent }} />;
  } catch (e) {
    return <div style={{ padding: '12px' }}>Markdown Error. Please check markdown content.</div>;
  }
};
