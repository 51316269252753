import { getLogger } from '../../common/Logger';
import { TcccSdk } from '../../tccc';

// key: roomId + userId
export const asrParserMap = new Map<string, AsrParser>();

export type AsrResult = {
  slice_type: 0 | 1 | 2;
  index: number;
  start_time: number;
  end_time: number;
  voice_text_str: string;
  word_size: number;
  word_list: any;
};
export class AsrParser {
  side: 'client' | 'seat';
  sessionId: string;
  userId: string;
  emitter: TcccSdk;
  endTime: number;
  lastIndex: number;
  finishIndex: Set<number>;
  logger: ReturnType<typeof getLogger>;
  constructor({
    side,
    sessionId,
    roomId,
    userId,
    emitter,
  }: {
    side: 'client' | 'seat';
    sessionId: string;
    roomId: string;
    userId: string;
    emitter: TcccSdk;
  }) {
    this.side = side;
    this.sessionId = sessionId;
    this.userId = userId;
    this.emitter = emitter;
    this.endTime = 0;
    this.lastIndex = 0;
    this.logger = getLogger(emitter.Agent.userInfo);
    this.finishIndex = new Set();
    asrParserMap.set(`${roomId}${userId}`, this);
    return this;
  }

  handleRecognizeMessages = (msgBody: AsrResult) => {
    try {
      const { index } = msgBody;
      if (this.lastIndex <= index) {
        this.lastIndex = index;
      }
      // 强制触发已完成识别结果
      if (msgBody.slice_type === 2) {
        this.finishIndex.add(msgBody.index);
        this.emitter.emit('asrEvent', {
          sessionId: this.sessionId,
          flow: this.side === 'client' ? 'IN' : 'OUT',
          result: msgBody,
          userId: this.userId,
        });
        return;
      }
      // 过滤已结束结果
      if (this.finishIndex.has(msgBody.index)) {
        return;
      }

      // 过滤时序不正确结果
      if (Number.isInteger(msgBody.end_time) && msgBody.end_time >= this.endTime) {
        this.endTime = msgBody.end_time;
        this.emitter.emit('asrEvent', {
          sessionId: this.sessionId,
          flow: this.side === 'client' ? 'IN' : 'OUT',
          result: msgBody,
          userId: this.userId,
        });
      }
    } catch (e) {
      this.logger.error(`parse asr result failed`, `sessionId: ${this.sessionId}`, `userId: ${this.userId}`, msgBody);
    }
  };
}
