import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { updateReplyInfo } from 'src/store/slices/tim.thunk';

import { ImReplyWrapper, ImReplyContent, ImReplyFrom, ImReplyMessage, ImReplyCloseIcon } from './imReply.style';
export const ImReply = (props) => {
  const { className, messageContent, hideClose, from } = props;

  const dispatch = useDispatch();
  const sessionId = useSelector((state) => state.userInfo.currentUser.sessionId);

  const handleImReplyCloseOnClick = useCallback(() => {
    dispatch(
      updateReplyInfo({
        sessionId,
        replyInfo: {
          replying: false,
        },
      }),
    );
  }, [dispatch, sessionId]);

  return (
    <ImReplyWrapper
      onClick={() => {
        if (hideClose && props?.onJump && typeof props?.onJump === 'function') props?.onJump();
      }}
      className={className}
      style={{ wordBreak: 'break-all' }}
    >
      <ImReplyContent>
        <ImReplyFrom>{from}</ImReplyFrom>: <ImReplyMessage>{messageContent}</ImReplyMessage>
      </ImReplyContent>
      {!hideClose && <ImReplyCloseIcon onClick={handleImReplyCloseOnClick} />}
    </ImReplyWrapper>
  );
};
