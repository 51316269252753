import styled from 'styled-components';

import wxEmojiImage from 'src/assets/images/emoji-sprite.png';
import { ImReply } from 'src/components/IM/im-reply/ImReply';

export const TextElementWrapper = styled.div`
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin: 0;
  line-height: 18px;
  white-space: pre-wrap;
  word-break: break-all;
`;

export const TextPhoneNumber = styled.a``;
export const TextLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})``;

export const EmojiWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
`;

export const EmojiContent = styled.div`
  background-image: url(${wxEmojiImage});
  transform-origin: 0 0;
  transform: scale(0.375);
`;

export const ImReplyInList = styled(ImReply).attrs({
  hideClose: true,
})`
  margin: 10px 42px 4px;
  line-height: 1;
  max-width: 460px;
  line-height: 1;
  max-width: 460px;
  cursor: pointer;
`;
