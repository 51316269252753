import { useCallback, useEffect, useRef, useState, useLayoutEffect, forwardRef, useImperativeHandle } from 'react';

import { ListItemText, Popper, MenuItem, Paper, MenuList, IconButton, Tooltip } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import { isRejected } from '@reduxjs/toolkit';
import { message } from '@tencent/tea-component';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import { usePopupState, bindPopper } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// import useEmojiStyles from 'src/components/IM/Message/Emojis/Emojis.style.js';
import { hideConfirmDialog, showConfirmDialog } from 'src/components/universal/ConfirmDialogTips/ConfirmDialogTips';
import { CUSTOM_MESSAGE_SRC, TRANSFER_TYPE } from 'src/constants/im';
import { PeerSources } from 'src/store/slices/sessions/constant';
import {
  updateReplyInfo,
  createTextMessage,
  sendMessage as sendMessageThunk,
  createCustomMessage,
  createImageMessage,
  createFileMessage,
  createVideoMessage,
  inviteMedia,
  sendIMSessionRating,
} from 'src/store/slices/tim.thunk';
import { messageBoxScrollToBottom, leadDebounce, showError, isShowGroupChatProfile } from 'src/utils';

import tccc from 'src/utils/tccc';

import { useRequest } from '../../../../services';
import globalEventEmitter from '../../../../utils/globalEventEmitter';
import logger from '../../../../utils/logger';
import { FileSvg } from '../../Icons/FileSvg';
import { ImgSvg } from '../../Icons/ImgSvg';
import { QuickReplySvg } from '../../Icons/QuickReplySvg';
import { StarSvg } from '../../Icons/StarSvg';
import { TransferSvg } from '../../Icons/TransferSvg';
import { VideoSvg } from '../../Icons/Video.svg';
import Emojis from '../Emojis/Emojis';
import MessageInputEditor from '../MessageInput/MessageInputEditor';

import useStyles, { ImReplyInSender } from './MessageSend.style';
const selection = document.getSelection();
let selectRange = '';
const imeInputting = false;
let lastMessage = undefined;

function getLatelyQuickReply() {
  let latelyQuickReply = localStorage.getItem('latelyQuickReply');
  if (latelyQuickReply) {
    latelyQuickReply = JSON.parse(latelyQuickReply);
  } else {
    latelyQuickReply = [];
  }
  return latelyQuickReply;
}

function setLatelyQuickReply(id) {
  const latelyQuickReply = getLatelyQuickReply();
  const index = latelyQuickReply.indexOf(id);
  if (index !== -1) {
    latelyQuickReply.splice(index, 1);
  }
  latelyQuickReply.push(id);
  if (latelyQuickReply.length > 5) {
    latelyQuickReply.shift();
  }
  localStorage.setItem('latelyQuickReply', JSON.stringify(latelyQuickReply));
}

const AutoScrollMenuItem = forwardRef((props, ref) => {
  const innerRef = useRef();
  useEffect(() => {
    if (props.selected) {
      innerRef.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [props.selected]);
  return <MenuItem ref={ref} innerRef={innerRef} {...props} />;
});

function MessageSend(props, ref) {
  const { callInfo } = props;
  const classes = useStyles();
  // const emojiClasses = useEmojiStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userInfo.currentUser);
  const sdkAppId = useSelector((state) => state.userInfo.sdkAppId);
  const { t } = useTranslation();
  const currentSeatId = useSelector((state) => state.userInfo.userId);
  const isDemoAccount = currentSeatId?.endsWith('@tcccdemo.com');
  const imagePicker = useRef(null);
  const videoPicker = useRef(null);
  const filePicker = useRef(null);
  const boxRef = useRef();
  const inputRef = useRef(null);
  const { from } = queryString.parse(window.location.search);
  const isFromExperienceCenter = from === 'exp';
  const [quickReplyData, setQuickReplayData] = useState([]);
  const [quickReplySearch, setQuickReplySearch] = useState([]);
  const [isSendingRating, setIsSendingRating] = useState(false);
  const [selectIndex, setSelectIndex] = useState(0);
  const [isSendedRating, setIsSendedRating] = useState(false);
  const popupState = usePopupState({
    popupId: 'message-quick-reply-suggestions',
    variant: 'popover',
    disableAutoFocus: true,
  });
  const isInnerChat = currentUser?.sessionId?.startsWith('C2C');

  const replyInfoList = useSelector((state) => state.tim.replyInfoList);
  const targetReplyInfo = replyInfoList[currentUser.sessionId];

  useLayoutEffect(() => {
    if (lastMessage && isEmpty(boxRef.current?.innerHTML)) {
      boxRef.current.innerHTML = lastMessage;
    }
    if (boxRef.current) {
      const contentEditable = boxRef.current;
      return () => {
        lastMessage = contentEditable.innerHTML;
      };
    }
  }, []);

  // 读ckv
  useRequest(
    '/tcccadmin/app/getCommKV',
    {
      key: 'quickReply',
    },
    {
      complete: ({ value }) => {
        try {
          setQuickReplayData(value ? JSON.parse(value) : []);
        } catch (e) {
          console.error(e);
        }
      },
    },
    [],
  );

  const sendMessage = useCallback(async () => {
    const messageList = inputRef.current.getEditorContent();
    if (isEmpty(messageList)) return inputRef.current.resetEditor();
    await messageList?.forEach(async (content) => {
      try {
        let res;
        switch (content?.type) {
          case 'text':
            res = await dispatch(
              createTextMessage({
                sessionId: currentUser.sessionId,
                payload: { text: content?.payload?.text },
                ...(targetReplyInfo?.replying && {
                  cloudCustomData: JSON.stringify({
                    messageReply: {
                      messageID: targetReplyInfo?.message?.ID,
                      messageAbstract: targetReplyInfo?.message?.messageAbstract,
                      messageSenderNick: targetReplyInfo?.message?.messageSenderNick,
                      messageSender: targetReplyInfo?.message?.customFrom,
                      messageType: targetReplyInfo?.message?.originType,
                      messageTime: targetReplyInfo?.message?.messageTime,
                      messageSequence: targetReplyInfo?.message?.messageSequence,
                      version: 1,
                    },
                  }),
                }),
              }),
            );
            if (targetReplyInfo?.replying) {
              dispatch(
                updateReplyInfo({
                  sessionId: currentUser.sessionId,
                  replyInfo: { replying: false },
                }),
              );
            }
            await dispatch(sendMessageThunk({ message: res.payload }));
            break;
          case 'image':
            res = await dispatch(
              createImageMessage({
                sessionId: currentUser.sessionId,
                payload: { file: content?.payload?.file },
              }),
            );
            await dispatch(sendMessageThunk({ message: res.payload }));
            // await TUIServer?.sendImageMessage(content?.payload?.file);
            break;
          case 'video':
            res = await dispatch(
              createVideoMessage({
                sessionId: currentUser.sessionId,
                payload: { file: content?.payload?.file },
              }),
            );
            await dispatch(sendMessageThunk({ message: res.payload }));
            // await TUIServer?.sendVideoMessage(content?.payload?.file);
            break;
          case 'file':
            res = await dispatch(
              createFileMessage({
                sessionId: currentUser.sessionId,
                payload: { file: content?.payload?.file },
              }),
            );
            await dispatch(sendMessageThunk({ message: res.payload }));
            // await TUIServer?.sendFileMessage(content?.payload?.file);
            break;
          default:
            break;
        }
      } catch (error) {
        logger.error('sendMessage: sendMessage failed', error);
        console.log(error);
      }
    });
    messageBoxScrollToBottom();
    inputRef.current.resetEditor();
    // resetReplyOrReference();
    return;
  }, [targetReplyInfo, dispatch, currentUser?.sessionId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const invalidSuggestionList = useCallback(() => {
    const targetNode = selection.focusNode;
    if (!targetNode) return;
    const nodeValue = targetNode.textContent;
    const value = nodeValue.slice(0, selection.focusOffset);
    if (value.includes('#')) {
      popupState.open(boxRef.current);
      const ary = value.split('#');
      const rangeValue = ary[ary.length - 1];
      const data = [];
      selectRange = selection.getRangeAt(0).cloneRange();
      selectRange.setStart(targetNode, value.length - rangeValue.length - 1);
      selectRange.setEnd(targetNode, selection.focusOffset);
      if (rangeValue === '') {
        const latelyQuickReply = getLatelyQuickReply();
        if (latelyQuickReply.length) {
          data.push(...quickReplyData.filter((item) => latelyQuickReply.includes(item.id)));
        } else {
          data.push(...quickReplyData);
        }
      } else if (rangeValue) {
        data.push(
          ...quickReplyData.filter(
            (item) => item.title.includes(rangeValue) || item.content.toLowerCase().includes(rangeValue.toLowerCase()),
          ),
        );
      }
      if (selectIndex > data.length - 1) {
        setSelectIndex(data.length > 1 ? data.length - 1 : 0);
      }
      if (data.length) {
        if (!isEqual(data, quickReplySearch)) setQuickReplySearch(data);
      } else {
        if (!imeInputting) popupState.close();
      }
    } else {
      popupState.close();
    }
  }, [popupState, selectIndex, quickReplyData, quickReplySearch]);

  const handleSelectReplyData = useCallback(
    (data) => {
      const { content } = data;
      const textNode = document.createTextNode(content);
      selectRange.deleteContents();
      selectRange.insertNode(textNode);
      selection.collapse(textNode, content.length);
      setLatelyQuickReply(data.id);
      popupState.close();
    },
    [popupState],
  );

  const handleInputVal = useCallback(
    (msg) => {
      inputRef.current?.setEditorContent(`<p>${msg}</p>`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputRef.current],
  );

  const sendTypingState = debounce(
    useCallback(async () => {
      if (isShowGroupChatProfile(callInfo)) {
        return;
      }
      const res = await dispatch(
        createCustomMessage({
          sessionId: currentUser.sessionId,
          payload: {
            data: JSON.stringify({
              src: CUSTOM_MESSAGE_SRC.TYPING_STATE,
              customerServicePlugin: 0,
            }),
          },
        }),
      );
      await dispatch(sendMessageThunk({ message: res.payload, options: { onlineUserOnly: true } }));
    }, [dispatch, currentUser?.sessionId]),
    15000,
    {
      leading: true,
      trailing: false,
      maxWait: 15000,
    },
  );

  useEffect(() => {
    // 快捷回复
    globalEventEmitter.on('im-quick-reply', handleInputVal);
    return () => {
      globalEventEmitter.off('im-quick-reply', handleInputVal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxRef.current]);

  const onChooseEmoji = useCallback((emoji) => {
    inputRef.current?.addEmoji({
      url: emoji.url,
      name: emoji.name,
    });
  }, []);

  const sendVideo = useCallback(
    async (event) => {
      const res = await dispatch(
        createVideoMessage({
          sessionId: currentUser.sessionId,
          payload: { file: event.target.files[0] },
        }),
      );
      if (isRejected(res)) {
        showError(res.payload || res.error.message);
        return;
      }
      const sendMessageRes = await dispatch(sendMessageThunk({ message: res.payload }));
      if (isRejected(sendMessageRes)) {
        showError(sendMessageRes.payload || sendMessageRes.error.message);
        return;
      }
      messageBoxScrollToBottom();
      event.target.value = '';
    },
    [currentUser?.sessionId, dispatch],
  );

  const sendImage = useCallback(
    async (event) => {
      const res = await dispatch(
        createImageMessage({
          sessionId: currentUser.sessionId,
          payload: { file: event.target.files[0] },
        }),
      );
      if (isRejected(res)) {
        showError(res.payload || res.error.message);
        return;
      }
      const sendMessageRes = await dispatch(sendMessageThunk({ message: res.payload }));
      if (isRejected(sendMessageRes)) {
        showError(sendMessageRes.payload || sendMessageRes.error.message);
        return;
      }
      messageBoxScrollToBottom();
      event.target.value = '';
    },
    [currentUser?.sessionId, dispatch],
  );
  const handleImagePickerOnChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      if (file.type?.startsWith('video')) {
        sendVideo(event);
      } else {
        sendImage(event);
      }
    },
    [sendImage, sendVideo],
  );
  async function sendFile(event) {
    const res = await dispatch(
      createFileMessage({
        sessionId: currentUser.sessionId,
        payload: { file: event.target.files[0] },
      }),
    );
    if (isRejected(res)) {
      showError(res.payload || res.error.message);
      return;
    }
    const sendMessageRes = await dispatch(sendMessageThunk({ message: res.payload }));
    if (isRejected(sendMessageRes)) {
      showError(sendMessageRes.payload || sendMessageRes.error.message);
      return;
    }
    messageBoxScrollToBottom();
    event.target.value = '';
  }

  function handleSendImageClick(e) {
    imagePicker.current.click();
    e.preventDefault();
  }

  function handleSendVideoClick(e) {
    videoPicker.current.click();
    e.preventDefault();
  }

  function handleSendFileClick() {
    filePicker.current.click();
  }

  const toggleQuickReply = () => {
    if (!isFunction(props.onQuickReply)) {
      return message.error({
        content: t('当前会话不支持快捷回复'),
      });
    }
    props.onQuickReply(!props.quickReply);
  };

  const inputHandleKeyDown = (e) => {
    if (e.keyCode === 13 && (e.ctrlKey || e.shiftKey || e.altKey || e.metaKey)) {
      inputRef.current?.setEditorContent('<p></p>');
    } else if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      sendMessage();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleInnerHtmlContent = () => {
    setTimeout(() => {
      if (boxRef.current) {
        boxRef.current.querySelectorAll('*').forEach((e) => {
          if (e.attributes.class) e.attributes.removeNamedItem('class');
          if (e.attributes.style) e.attributes.removeNamedItem('style');
        });
      }
    }, 0);
  };

  const handleSendRatingOnClick = useCallback(async () => {
    if (isSendingRating) {
      message.error({
        content: t('请勿重复发送评价请求'),
      });
      return;
    }
    setIsSendingRating(true);
    const res = await dispatch(sendIMSessionRating({ sessionId: currentUser.sessionId }));
    if (isRejected(res)) {
      showError(res.payload);
    } else {
      setIsSendedRating(true);
    }
    setIsSendingRating(false);
  }, [isSendingRating, dispatch, currentUser.sessionId]);

  const handleMediaStart = debounce(
    useCallback(
      async (type) => {
        try {
          await tccc.Devices.checkDevices();
        } catch (e) {
          return showConfirmDialog({
            icon: 'error',
            enterText: t('知道了'),
            cancelText: '',
            content: (
              <div>
                {t('发起通话失败，原因是检查设备出错，请进行')}
                {
                  <a target="_blank" href={`https://${window.location.hostname}/helper`} rel="noreferrer">
                    {t('设备检测')}
                  </a>
                }
              </div>
            ),
            onEnter() {
              hideConfirmDialog();
            },
          });
        }
        const res = await dispatch(inviteMedia({ sessionId: currentUser.sessionId, type }));
        if (isRejected(res)) {
          message.error({
            content: t('发起失败，错误信息: {{0}}', { 0: res.error.message }),
          });
        }
      },
      [dispatch, currentUser?.sessionId],
    ),
    2000,
    {
      leading: true,
      trailing: false,
      maxWait: 15000,
    },
  );

  useImperativeHandle(ref, () => ({
    setEditorContent: (text) => {
      inputRef.current?.setEditorContent(`<p>${text}</p>`);
    },
  }));

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <Emojis onChooseEmoji={onChooseEmoji} />
        <Tooltip title={t('发送图片')}>
          <IconButton disableRipple className={classes.iconButton} size="small" onClick={handleSendImageClick}>
            <ImgSvg className={classes.funcIcon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('发送视频')}>
          <IconButton disableRipple className={classes.iconButton} size="small" onClick={handleSendVideoClick}>
            <VideoSvg className={classes.funcIcon} />
          </IconButton>
        </Tooltip>
        {(['4', '7', '10'].includes(callInfo.peerSource) || isInnerChat) && (
          <Tooltip title={t('发送文件')}>
            <IconButton disableRipple className={classes.iconButton} size="small" onClick={handleSendFileClick}>
              <FileSvg className={classes.funcIcon} />
            </IconButton>
          </Tooltip>
        )}
        <input type="file" ref={filePicker} onChange={sendFile} style={{ display: 'none' }} id="filePicker" />
        <input
          type="file"
          ref={imagePicker}
          accept={'.jpg, .jpeg, .png, .gif'}
          onChange={handleImagePickerOnChange}
          style={{ display: 'none' }}
          id="imagePicker"
        />
        <input
          type="file"
          ref={videoPicker}
          accept={'video/mp4, video/quicktime, video/x-msvideo, video/x-ms-wmv'}
          onChange={handleImagePickerOnChange}
          style={{ display: 'none' }}
          id="imagePicker"
        />
        {!isFromExperienceCenter && !isInnerChat && (
          <Tooltip title={t('快捷回复')}>
            <IconButton disableRipple className={classes.iconButton} size="small" onClick={toggleQuickReply}>
              <QuickReplySvg
                active={props.quickReply}
                className={classnames(classes.funcIcon, classes.lightningIcon)}
              />
            </IconButton>
          </Tooltip>
        )}
        {![PeerSources.wechatKf, PeerSources.WhatsApp].includes(callInfo.peerSource) &&
          !isInnerChat &&
          !isShowGroupChatProfile(callInfo) && (
            <Tooltip title={t('发起满意度评价')}>
              <IconButton disableRipple className={classes.iconButton} size="small" onClick={handleSendRatingOnClick}>
                <StarSvg disabled={isSendedRating} className={classes.funcIcon} />
              </IconButton>
            </Tooltip>
          )}
        {/* 新TRTC环境+小程序可发起音频通话 */}
        {callInfo.peerSource === '2' && (
          <Tooltip title={t('发起音频通话')}>
            <IconButton
              disableRipple
              size="small"
              className={classes.iconButton}
              disabled={!!callInfo.media}
              onClick={() => handleMediaStart('audio')}
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 0C11.4853 0 13.5 2.01472 13.5 4.5V8.5C13.5 10.9853 11.4853 13 9 13C6.51472 13 4.5 10.9853 4.5 8.5V4.5C4.5 2.01472 6.51472 0 9 0ZM9 1.5C7.40232 1.5 6.09634 2.74892 6.00509 4.32373L6 4.5V8.5C6 10.1569 7.34315 11.5 9 11.5C10.5977 11.5 11.9037 10.2511 11.9949 8.67627L12 8.5V4.5C12 2.84315 10.6569 1.5 9 1.5ZM3 7.96666C3 7.97783 2.99976 7.98895 2.99927 8H3V8.5C3 11.8137 5.68629 14.5 9 14.5C12.2384 14.5 14.8776 11.9344 14.9959 8.72494L15 8.5V8H15.0007C15.0002 7.98895 15 7.97783 15 7.96666C15 7.55245 15.3358 7.21666 15.75 7.21666C16.1642 7.21666 16.5 7.55245 16.5 7.96666C16.5 7.97783 16.4998 7.98895 16.4993 8H16.5V8.5C16.5 12.389 13.54 15.5867 9.75 15.963V17.25C9.75 17.6642 9.41421 18 9 18C8.58579 18 8.25 17.6642 8.25 17.25V15.963C4.46001 15.5867 1.5 12.389 1.5 8.5V8H1.50073C1.50024 7.98895 1.5 7.97783 1.5 7.96666C1.5 7.55245 1.83579 7.21666 2.25 7.21666C2.66421 7.21666 3 7.55245 3 7.96666Z"
                  fill="#666666"
                />
              </svg>
            </IconButton>
          </Tooltip>
        )}
        {/* NOTE: 华师定制 —— 不显示视频通话按钮 */}
        {callInfo.peerSource === '2' && sdkAppId !== '1400309319' && (
          <Tooltip title={t('发起视频通话')}>
            <IconButton
              disableRipple
              className={classes.iconButton}
              size="small"
              disabled={!!callInfo.media}
              onClick={() => handleMediaStart('video')}
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.5 3.75C14.5 2.50736 13.4926 1.5 12.25 1.5H2.25C1.00736 1.5 0 2.50736 0 3.75V14.25C0 15.4926 1.00736 16.5 2.25 16.5H12.25C13.4926 16.5 14.5 15.4926 14.5 14.25V12.416L16.3778 13.7997C16.5541 13.9295 16.7704 14 16.9931 14C17.5493 14 18.0003 13.5693 18.0003 13.038V4.96205C18.0003 4.76429 17.9365 4.57134 17.8175 4.40947C17.498 3.97458 16.8698 3.86944 16.4145 4.17463L14.5 5.4578V3.75ZM14.5 7.262V10.553L16.5 12.027V5.922L14.5 7.262ZM2.25 3H12.25L12.3518 3.00685C12.7178 3.05651 13 3.3703 13 3.75V14.25L12.9932 14.3518C12.9435 14.7178 12.6297 15 12.25 15H2.25L2.14823 14.9932C1.78215 14.9435 1.5 14.6297 1.5 14.25V3.75L1.50685 3.64823C1.55651 3.28215 1.8703 3 2.25 3ZM3 5.25C3 4.83579 3.33579 4.5 3.75 4.5H5.25C5.66421 4.5 6 4.83579 6 5.25C6 5.66421 5.66421 6 5.25 6H3.75C3.33579 6 3 5.66421 3 5.25Z"
                  fill="#666666"
                />
              </svg>
            </IconButton>
          </Tooltip>
        )}
        {!isInnerChat && !isDemoAccount && (
          <Tooltip title={t('转接')}>
            <IconButton
              disableRipple
              className={classnames(classes.iconButton, classes.transfer)}
              size="small"
              onClick={() => {
                if (!isFunction(props.onTransfer)) {
                  return message.error({
                    content: t('当前会话不支持转接'),
                  });
                }
                props.onTransfer(TRANSFER_TYPE.SKILL_GROUP);
              }}
            >
              <TransferSvg className={classnames(classes.funcIcon)} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Popper
        {...bindPopper(popupState)}
        autoFocus={false}
        anchorReference="anchorEl"
        placement="top-start"
        style={{ zIndex: 100, width: 600 }}
      >
        <Paper
          style={{
            backgroundColor: 'white',
            maxHeight: 200,
            overflowY: 'scroll',
          }}
        >
          <MenuList autoFocusItem={false} id="composition-menu" aria-labelledby="composition-button">
            {quickReplySearch?.map((item, index) => (
              <AutoScrollMenuItem
                selected={selectIndex === index}
                key={index}
                onClick={() => handleSelectReplyData(item)}
              >
                <ListItemText>
                  <p title={item.content} className={classes.quickReplyText}>
                    {item.title}
                    <span className={classes.quickReplyContent}>{item.content}</span>
                  </p>
                </ListItemText>
              </AutoScrollMenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popper>
      <div
        style={{ height: 130 }}
        onKeyDown={(e) => {
          inputHandleKeyDown(e);
        }}
      >
        <MessageInputEditor ref={inputRef} sendTypingState={sendTypingState} callInfo={callInfo} />
        {targetReplyInfo?.replying && (
          <div>
            <ImReplyInSender
              messageContent={targetReplyInfo?.message?.messageAbstract}
              from={
                targetReplyInfo?.message?.messageSenderNick && targetReplyInfo?.message?.messageSenderNick !== ''
                  ? targetReplyInfo?.message?.messageSenderNick
                  : targetReplyInfo?.message?.customFrom
              }
            />
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={leadDebounce(sendMessage, 300)}
          className={classes.sendBtn}
        >
          {t('发送(Enter)')}
        </Button>
      </div>
    </div>
  );
}

MessageSend.propTypes = {
  callInfo: PropTypes.object.isRequired,
  onTransfer: PropTypes.func,
  onQuickReply: PropTypes.func,
  quickReply: PropTypes.bool,
};

export default forwardRef(MessageSend);
