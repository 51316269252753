import { isFulfilled } from '@reduxjs/toolkit';
import { message } from '@tencent/tea-component';
import pick from 'lodash/pick';
import { TcccSdk, unwrapResult } from 'tccc-sdk';

import sdkMessage, { transformCallInfoToSdk } from 'src/agentSdkIframe/sdkMessage';
import { getServerTypeName } from 'src/components/SessionStatusDisplay/SessionStatusDisplay';
import { hideConfirmDialog, showConfirmDialog } from 'src/components/universal/ConfirmDialogTips/ConfirmDialogTips';
import i18n from 'src/i18n';

import { imSdkReadyStateChanged } from 'src/store/actions/im';
import { OutboundMember, QueueMember, SeatMember, Session } from 'src/store/slices/sessions';
import { UseMobileAccept } from 'src/store/slices/sessions/constant';
import { setIvrSoundPlayFinished, updateForwardStatus, updateReceiveStatus } from 'src/store/slices/sessions/pstn';
import {
  changeMicStatusAction,
  phoneCallInAnswer,
  phoneCallOutAnswer,
  updateMember,
  updateSession,
  upsertSession,
} from 'src/store/slices/sessions/sessions.action';
import { accessSession, endSession, getHangupType, setActiveUser } from 'src/store/slices/sessions/sessions.thunk';
import {
  addInfoMessage,
  endMedia,
  getRatingMessage,
  messageModified,
  messageRevoked,
  receiveMessageThunk,
  setMessageRead,
  shouldAddUnreadCountOrUpdateLastMessage,
  updateTypingState,
  updateUnreadCount,
  updateReadReceipt,
} from 'src/store/slices/tim.thunk';
import { updateUseMobileConfig } from 'src/store/slices/userInfo';
import Notification from 'src/utils/browserNotification';
import { goldlog } from 'src/utils/goldlog';

import logo from '../assets/images/calling-e28ab6f86ce0499409518aba62c947ca296fdd5e.svg';

import { debugLogRequest } from '../services';
import store, { RootState, selectAllSession, selectBySessionId } from '../store';
import { online } from '../store/actions/agent.thunk';
import { changeStatus } from '../store/slices/agent';

import { AsrParser, asrParserMap } from './AsrParser';
import { getTabIsActive } from './browserActive';
import logger from './logger';
import sessionManage from './sessionManage';

const tccc = new TcccSdk({ origin: window.location.origin });

const { t } = i18n;
export enum Direction {
  callIn,
  callOut,
}

type TimeRange = {
  from: string;
  to: string;
};

export enum NumberReflectMode {
  close,
  partial,
  global,
}

const isValidTime = (range: TimeRange) => {
  const [fromHour, fromMinute] = range.from.split(':');
  const [toHour, toMinute] = range.to.split(':');
  const now = new Date();
  if (now.getHours() > +fromHour && now.getHours() < +toHour) {
    return true;
  }
  if (now.getHours() === +fromHour || now.getHours() === +toHour) {
    return now.getMinutes() >= +fromMinute && now.getMinutes() <= +toMinute;
  }
  return false;
};

const onBrowserNotificationAccess = (type: string) => {
  try {
    const e = window.document.createEvent('MouseEvents');
    const newLocal = 'click';
    e.initEvent(newLocal, true, true);
    let btn = null;
    // 如果是SDK的话，从iframe中获取
    if (window.location.href.includes('/open.html')) {
      btn = window.document.querySelector('button[name="btn_im_callin"]');
    } else if (type === 'im') {
      btn = window.document.querySelector('button[name="btn_im_callin"]');
    } else {
      btn = window.document.querySelector('div[name="btn_media_callin"]');
    }
    btn?.dispatchEvent(e);
  } catch (ex) {
    logger.warn('BrowserNotification onAccessSession click failed', ex);
  }
};
const autoAcceptPlay = () => {
  try {
    const promise = (document.getElementById('autoAccessAudio') as HTMLAudioElement)?.play();
    if (promise !== undefined) {
      promise.catch((error) => {
        logger.error('autoAcceptPlay auto play failed', error);
      });
    }
  } catch (e) {
    logger.error('auto accept play audio error', e);
  }
};

// @ts-ignore
window.tcccSdk = tccc;
tccc.on('agentStateChanged', (response) => {
  const status = pick(response, ['status', 'restReason', 'nextStatus', 'logicStatus', 'IM', 'queueCount']);
  store.dispatch(
    changeStatus({
      ...status,
      reason: status.restReason,
    }),
  );
});
tccc.on('userSettingsUpdated', (response) => {
  const { userInfo } = store.getState();
  const newUserInfo: Partial<{ useMobileCallOut: boolean; useMobileAcceptType: UseMobileAccept }> = {};
  if (response) {
    Object.entries(response).forEach(([item, value]) => {
      if (value !== undefined) {
        if (item === 'useMobileCallOutType') {
          const newVal = value === 1;
          if (userInfo.useMobileCallOut !== newVal) {
            newUserInfo.useMobileCallOut = newVal;
          }
        }
        if (item === 'useMobileAcceptType') {
          if (userInfo.useMobileAcceptType !== value) {
            newUserInfo.useMobileAcceptType = value as UseMobileAccept;
          }
        }
      }
    });
    store.dispatch(updateUseMobileConfig(newUserInfo));
    sdkMessage.postEventMessage(sdkMessage.events.userSettingsUpdated, {
      useMobileCallOut: newUserInfo.useMobileCallOut,
      useMobileAcceptType: newUserInfo.useMobileAcceptType,
    });
  }
});
tccc.on('connectionStateChanged', ({ state }) => {
  store.dispatch(changeStatus({ ...store.getState().agent, connectionState: state }));
});
tccc.on('calloutAccepted', ({ sessionId, isHost }) => {
  store.dispatch(phoneCallOutAnswer({ sessionId, isHost }));
  const session = selectBySessionId(store.getState(), sessionId);
  if (session) {
    sdkMessage.postEventMessage(
      sdkMessage.events.calloutAccepted,
      transformCallInfoToSdk({
        ...session,
        isHost,
        type: session.type === 'dialBack' ? 'dialBack' : 'callOut',
        direction: '1',
      }),
    );
  }
});
tccc.on('callInAccepted', ({ sessionId, serverType, playAudio, isHost, tabUUID }) => {
  Notification.clearAll();
  const session = selectBySessionId(store.getState(), sessionId);
  // 呼入到座机、手机端，在接听时，触发接听事件
  // TODO: 增加座席已接听、正在外呼对方的状态
  if (
    session &&
    session.direction === Direction.callIn &&
    (session.type === 'phone' || session.type === 'voip' || session.type === 'internal')
  ) {
    store.dispatch(phoneCallInAnswer({ sessionId, serverType, playAudio, isHost }));
  }
  if (session) {
    sdkMessage.postEventMessage(
      sdkMessage.events.userAccessed,
      transformCallInfoToSdk({
        ...session,
        tabUUID,
        isHost,
        type: 'callOut',
        direction: session.direction === Direction.callOut ? '1' : '0',
      }),
    );
  }
});
tccc.on('callIn', async (response) => {
  const { appSettings } = store.getState() as RootState;
  const { userInfo } = store.getState();
  const session: Session = {
    sessionId: response.sessionId,
    type: response.type,
    direction: Direction.callIn,
    status: '100',
    timeout: response.timeout,
  };
  if (response.type === 'phone') {
    Object.assign(session, {
      calleePhoneNumber: response.calleePhoneNumber,
      callerPhoneNumber: response.callerPhoneNumber,
      protectedCallee: response.protectedCallee,
      protectedCaller: response.protectedCaller,
      callerLocation: response.callerLocation,
      ivrPath: response.ivrPath,
      remark: response.remark,
      ivrSoundPlaying: true,
      serverType: response.serverType,
    });
    store.dispatch(upsertSession(session));
    store.dispatch(setActiveUser(session));
    sdkMessage.postEventMessage(
      sdkMessage.events.callIn,
      transformCallInfoToSdk({
        ...response,
        phone: session.callerPhoneNumber,
        direction: '0',
        startCallTime: Date.now(),
      }),
    );
    const isWebStaffSeat = !response.serverType || response.serverType === 'staffSeat';
    const serverTypeName = getServerTypeName(session.serverType || 'staffSeat');
    let title = t('您有新来电');
    if (serverTypeName) {
      title = t('您有新的来电，请在{{0}}上接听', { 0: serverTypeName });
    }
    Notification.onMessage({
      title,
      againTitle: t('再次提醒您有新来电，请及时接听'),
      icon: logo,
      time: response.timeout || 30,
      onClick: () => (isWebStaffSeat ? onBrowserNotificationAccess(response.type) : null),
    });
    /**
     * 若
     *  a: Web座席
     *  b: 开启自动接听
     *  c: 在自动接听时间范围内
     * 则 自动接听
     */
    if (isWebStaffSeat && appSettings.telAutoAccept && isValidTime(appSettings.telAutoAcceptRange)) {
      logger.info('auto accept', session);
      debugLogRequest(`auto accept ${session.sessionId}, ${appSettings.telAutoAcceptRange}`);
      autoAcceptPlay();
      return store.dispatch(accessSession(session));
    }
    return;
  }
  if (response.type === 'internal') {
    Object.assign(session, {
      userId: response.userId,
      serverType: response.serverType,
    });
    store.dispatch(upsertSession(session));
    store.dispatch(setActiveUser(session));
    const isWebStaffSeat = !response.serverType || response.serverType === 'staffSeat';
    const serverTypeName = getServerTypeName(session.serverType || 'staffSeat');
    let title = t('您有新内部通话接入');
    if (serverTypeName) {
      title = t('您有新内部通话接入，请在{{0}}上接听', { 0: serverTypeName });
    }
    Notification.onMessage({
      title,
      icon: logo,
      againTitle: t('再次提醒您有新内部通话，请及时处理'),
      time: response.timeout || 30,
      onClick: () => (isWebStaffSeat ? onBrowserNotificationAccess(response.type) : null),
    });
    sdkMessage.postEventMessage(
      sdkMessage.events.callIn,
      transformCallInfoToSdk({
        ...response,
        direction: '0',
      }),
    );
    return;
  }
  if (response.type === 'im') {
    const clientData = {};
    if (typeof response.clientData === 'string') {
      try {
        Object.assign(clientData, JSON.parse(decodeURIComponent(response.clientData)));
      } catch (e) {
        logger.warn('parse clientData failed', response.channelName);
      }
    }
    Object.assign(session, {
      peerSource: response.peerSource,
      channelName: response.channelName,
      clientData,
      nickname: response.nickname || t('访客_{{0}}', { 0: response.userId }),
      userId: response.userId,
      avatar: response.avatar,
      onLineState: response.onlineState,
      channelAgentID: response.channelAgentID ?? '',
      isFirstCallIn: true,
      conversationID: response?.conversationID ?? '',
      imAgentChatType: response?.imAgentChatType ?? 0,
    });
    store.dispatch(upsertSession(session));
    sdkMessage.postEventMessage(
      sdkMessage.events.callIn,
      transformCallInfoToSdk({
        ...response,
        direction: '0',
        startCallTime: Date.now(),
      }),
    );
    Notification.onMessage({
      title: t('您有用户接入'),
      icon: logo,
      againTitle: t('再次提醒您有用户接入，请及时处理'),
      time: response.timeout || 30,
      onClick: () => onBrowserNotificationAccess(response.type),
    });
    if (appSettings.imAutoAccept || response.isIMCallOut === 1) {
      logger.info('im auto accept', session);
      autoAcceptPlay();
      const res = await store.dispatch(accessSession(session));
      if (isFulfilled(res)) {
        try {
          const allSessions = selectAllSession(store.getState());
          const findSession = allSessions.find((item) => item.sessionId === session.sessionId);
          if (findSession?.status === '200') {
            const conversationListRes = await tccc.Chat.getConversationList([findSession.sessionId]).then(unwrapResult);
            if (findSession.sessionId === userInfo?.currentUser?.sessionId) {
              return;
            }
            if (conversationListRes[0]?.unreadCount > 0 && conversationListRes[0]?.unreadCount !== findSession?.count) {
              store.dispatch(
                updateUnreadCount([{ sessionId: findSession.sessionId, count: conversationListRes[0]?.unreadCount }]),
              );
            }
          }
        } catch (e) {
          logger.error('updateUnreadCountAfterAccessSession', e);
        }
      }
      return undefined;
    }
    return;
  }
  if (response.type === 'voip') {
    let clientData = {};
    if (response.clientData && typeof response.clientData === 'string') {
      try {
        Object.assign(clientData, JSON.parse(response.clientData));
      } catch (e) {
        clientData = response.clientData;
        logger.error('parse clientData failed', response.sessionId);
      }
    }
    Object.assign(session, {
      callee: response.callee,
      caller: response.caller,
      avatar: response.avatar,
      clientData,
      nickname: response.nickname,
      remark: response.remark,
      callerPhoneNumber: response.callerPhoneNumber,
      calleeRemark: response.calleeRemark,
      ivrPath: response.ivrPath,
      serverType: response.serverType,
    });
    store.dispatch(upsertSession(session));
    store.dispatch(setActiveUser(session));
    const isWebStaffSeat = !response.serverType || response.serverType === 'staffSeat';
    const serverTypeName = getServerTypeName(session.serverType || 'staffSeat');
    let title = t('您有新音频来电');
    if (serverTypeName) {
      title = t('您有新音频来电，请在{{0}}上接听', { 0: serverTypeName });
    }
    Notification.onMessage({
      title,
      icon: logo,
      againTitle: t('再次提醒您有新音频来电，请及时接听'),
      time: response.timeout || 30,
      ivrSoundPlaying: true,
      onClick: () => (isWebStaffSeat ? onBrowserNotificationAccess(response.type) : null),
    });
    sdkMessage.postEventMessage(sdkMessage.events.callIn, transformCallInfoToSdk(response));
    if (isWebStaffSeat && appSettings.audioAutoAccept) {
      logger.info('voip auto accept');
      debugLogRequest('voip auto accept', session.sessionId);
      autoAcceptPlay();
      return store.dispatch(accessSession(session));
    }
    return;
  }
  if (response.type === 'video') {
    Object.assign(session, {
      avatar: response.avatar,
      nickname: response.nickname || t('访客_{{0}}', { 0: response.userId }),
      remark: response.remark,
      userId: response.userId,
    });
    store.dispatch(upsertSession(session));
    store.dispatch(setActiveUser(session));
    Notification.onMessage({
      title: t('您有新视频来电'),
      icon: logo,
      againTitle: t('再次提醒您有新视频来电，请及时接听'),
      time: response.timeout || 30,
      ivrSoundPlaying: true,
      onClick: () => onBrowserNotificationAccess(response.type),
    });
    sdkMessage.postEventMessage(sdkMessage.events.callIn, transformCallInfoToSdk(response));
    if (appSettings.videoAutoAccept) {
      logger.info('video auto accept');
      autoAcceptPlay();
      return store.dispatch(accessSession(session));
    }
    return;
  }
});
tccc.on('callOut', (response) => {
  const { type, sessionId, serverType } = response;
  const session: Session = {
    sessionId,
    type,
    direction: Direction.callOut,
    status: '150',
    serverType,
  };
  if (type === 'phone') {
    const { callerPhoneNumber, calleePhoneNumber, calleeLocation, remark, aiEnabled } = response;
    const { numberReflectMode } = store.getState().appSettings;
    let protectedCallee = '';
    if (numberReflectMode === NumberReflectMode.global || numberReflectMode === NumberReflectMode.partial) {
      protectedCallee = calleePhoneNumber;
    }

    Object.assign(session, {
      callerPhoneNumber,
      calleePhoneNumber,
      calleeLocation,
      remark,
      protectedCallee,
      aiEnabled,
    });
    store.dispatch(upsertSession(session));
    store.dispatch(setActiveUser(session));
  }
  if (type === 'internal') {
    if (response.userId) {
      Object.assign(session, {
        userId: response.userId,
      });
    }
    store.dispatch(upsertSession({ ...session, timestamp: Math.floor(Date.now() / 1000) }));
    store.dispatch(setActiveUser(session));
  }
  sdkMessage.postEventMessage(
    sdkMessage.events.callOuted,
    transformCallInfoToSdk({
      ...session,
      tabUUID: 'tabUUID' in response ? response.tabUUID : '',
      type: 'callOut',
      direction: '1',
      startCallTime: Date.now(),
    }),
  );
});
tccc.on('sessionEnded', ({ sessionId, closeBy, mainReason, subReason, hangupType, setRest }) => {
  const session = selectBySessionId(store.getState(), sessionId);
  if (!session) return;
  // 电话使用新模型，单独使用挂断通知
  if (session.type && ['voip', 'phone', 'dialBack'].includes(session.type)) {
    let duration = Math.floor(session.timestamp ? Date.now() / 1000 - session.timestamp : 0);
    if (session.status !== '200') {
      duration = 0;
    }
    sdkMessage.postEventMessage(
      sdkMessage.events.sessionEnded,
      transformCallInfoToSdk(
        {
          ...session,
          closeBy,
          hangupType: getHangupType(session, hangupType, closeBy),
          duration,
          mainReason,
          subReason,
        },
        true,
      ),
    );
  }

  store.dispatch(
    endSession({
      sessionId,
      closeBy,
      hangupType: session.type === 'phone' && closeBy === 'timer' ? 'giveup' : hangupType,
      mainReason,
      subReason,
      fromEvent: true,
    }),
  );
  if (closeBy === 'system' && session.type === 'phone') {
    message.error({
      content: t('通话已被中断'),
      duration: 10000,
    });
    Notification.onMessage({
      title: t('通话已被中断'),
      icon: logo,
      time: 15,
    });
    return;
  }
  if (session.type !== 'im' && session.type !== 'video' && hangupType !== 1 && closeBy !== 'timer') {
    Notification.onMessage({
      title: t('通话已被挂断，原因：{{0}}', { 0: mainReason || t('系统异常') }),
      icon: logo,
      time: 15,
    });
    // 客户侧网络异常
    if (hangupType === 211) {
      showConfirmDialog({
        icon: 'error',
        title: t('呼叫失败'),
        enterText: t('知道了'),
        content: `${mainReason}\n${subReason}`,
        onEnter() {
          hideConfirmDialog();
        },
        cancelText: '',
      });
      return;
    }
    // 运营商错误
    if (hangupType === 208) {
      showConfirmDialog({
        icon: 'error',
        title: t('外呼失败'),
        enterText: t('知道了'),
        content: `${mainReason}\n${subReason}`,
        onEnter() {
          hideConfirmDialog();
        },
        cancelText: '',
      });
      return;
    }
    return message.error({
      content: `${mainReason || t('系统异常')}  ${subReason || ''}`,
      duration: 10000,
    });
  }
  if (closeBy === 'user') {
    let content = Number(session.status) >= 200 ? t('通话结束，对方已挂断') : t('对方已取消通话');
    if (hangupType !== 1) {
      content = mainReason || content;
    }
    message.warning({
      content,
      duration: 2000,
    });
    Notification.onMessage({
      title: content,
      icon: logo,
      time: 15,
    });
  }
  if (closeBy === 'timer') {
    Notification.onMessage({
      title: t('您有来电超时未接入，请到工作台确认'),
      icon: logo,
      time: 15,
    });
    if (setRest) {
      showConfirmDialog({
        content: t(
          '您有一个来电未接入，该来电已转接至其他座席。同时您的服务状态已被自动设置为小休。如需继续服务，请点击结束小休。',
        ),
        enterText: t('结束小休'),
        onEnter() {
          tccc.Agent.setStatus('free');
          hideConfirmDialog();
        },
      });
    }
    sdkMessage.postEventMessage(sdkMessage.events.autoTransfer, transformCallInfoToSdk(session));
  }
  if (closeBy === 'seat' && session?.serverType !== 'staffSeat') {
    const serverTypeName = getServerTypeName(session.serverType || 'staffPhoneSeat');
    message.warning({
      content: t('座席{{0}}已挂断', { 0: serverTypeName }),
      duration: 2000,
    });
    Notification.onMessage({
      title: t('座席{{0}}已挂断', { 0: serverTypeName }),
      icon: logo,
      time: 15,
    });
  }
});
let instanceSessionKeyChanged = false;
let instanceSessionTimer: ReturnType<typeof setTimeout>;
window.addEventListener('storage', (e) => {
  if (e.key !== 'lscache-tcccsession_key') {
    return;
  }
  instanceSessionKeyChanged = true;
  instanceSessionTimer = setTimeout(() => {
    instanceSessionKeyChanged = false;
  }, 3000);
});
tccc.on('kickedOut', () => {
  sdkMessage.postEventMessage(sdkMessage.events.kickedOut);
  instanceSessionTimer && clearTimeout(instanceSessionTimer);
  const oldInstanceSessionKeyChanged = instanceSessionKeyChanged;
  const fromSdk = ['/open.html', '/open'].includes(window.location.pathname);
  goldlog
    .of('MANAGE')
    .forType('INTERACTION')
    .doing('CLICK_PREVIEW')
    .with({ instanceSessionKeyChanged }, 'kickedOut show');
  showConfirmDialog({
    content: oldInstanceSessionKeyChanged
      ? t(
          '您的账号在浏览器的其他标签页登录了，请在最新的标签页使用，或者点击刷新此页面继续使用（其他标签页将会被踢下线）。',
        )
      : t('您的账号已在其他地方登录'),
    cancelText: oldInstanceSessionKeyChanged && !fromSdk ? t('忽略') : '',
    enterText: oldInstanceSessionKeyChanged ? t('刷新继续使用') : t('重新登录'),
    onEnter() {
      goldlog
        .of('MANAGE')
        .forType('INTERACTION')
        .doing('CLICK_PREVIEW')
        .with({ oldInstanceSessionKeyChanged }, 'kickedOut onEnter');
      if (oldInstanceSessionKeyChanged) {
        window.location.reload();
        return;
      }
      if (fromSdk) {
        // 如果是SDK内，可以直接登录
        return store.dispatch(online());
      }
      sessionManage.loginOut();
    },
    onCancel() {
      hideConfirmDialog();
    },
  });
  instanceSessionKeyChanged = false;
});
tccc.on('forcedOffline', () => {
  sdkMessage.postEventMessage(sdkMessage.events.forcedOffline);
  showConfirmDialog({
    content: t('您的账号被强制下线，请联系管理员'),
    cancelText: '',
    onEnter() {
      sessionManage.loginOut();
    },
  });
});
tccc.on('expired', () => {
  showConfirmDialog({
    content: t('您的登录已过期，请重新登录'),
    cancelText: '',
    onEnter() {
      sessionManage.loginOut();
    },
    countDownConfig: {
      from: Date.now(),
      countdown: 5,
    },
  });
});
tccc.on('asrEvent', ({ sessionId, result, flow }) => {
  sdkMessage.postEventMessage(sdkMessage.events.asr, {
    sessionId,
    result,
    flow,
  });
  if (asrParserMap.has(sessionId)) {
    const asrParser = asrParserMap.get(sessionId);
    asrParser?.handleRecognizeMessages({ flow, ...result });
  } else {
    const asrParser = new AsrParser({ sessionId });
    asrParser?.handleRecognizeMessages({ flow, ...result });
  }
});

tccc.on('imReadyStateChanged', ({ state }) => {
  // @ts-ignore
  store.dispatch(imSdkReadyStateChanged(state));
});
tccc.on('typingStateChanged', ({ sessionId, state }) => {
  store.dispatch(updateTypingState({ sessionId, state }));
});
tccc.on('ivrSoundPlayFinished', ({ sessionId }) => {
  store.dispatch(setIvrSoundPlayFinished({ sessionId }));
});
tccc.on('audioVolume', (data) => {
  const localAudioVolumeData = data.find((item) => item.userId === store.getState().userInfo.userId);
  if (localAudioVolumeData) {
    sdkMessage.postEventMessage(sdkMessage.events.liveLocalLevel, { audioVolume: localAudioVolumeData.audioVolume });
  }
});
tccc.on('networkQuality', (res) => {
  sdkMessage.postEventMessage(sdkMessage.events.networkQuality, res);
});
tccc.on('inviteCallStateChanged', ({ sessionId, state }) => {
  if (state === 'accepted') {
    store.dispatch(
      updateForwardStatus({
        forwardInfo: {
          forwardStartServerTime: Date.now(),
          clientStatus: 'HOLDING',
          outlineStatus: 'TALKING',
        },
        sessionId,
      }),
    );
  } else if (state === 'hangup') {
    store.dispatch(
      updateForwardStatus({
        forwardInfo: {
          outlineStatus: 'HANGUP',
        },
        sessionId,
      }),
    );
  } else if (state === 'failed') {
    store.dispatch(
      updateForwardStatus({
        forwardInfo: {
          outlineStatus: 'FAILED',
        },
        sessionId,
      }),
    );
  }
});

tccc.on('receiveIvrStateChanged', ({ sessionId, state, clientData, keyPressed }) => {
  store.dispatch(
    updateReceiveStatus({
      sessionId,
      receiveStatus: state,
      clientData,
      keyPressed,
    }),
  );
  sdkMessage.postEventMessage(sdkMessage.events.receiveStatusChanged, {
    sessionId,
    receiveStatus: state,
    keyPressed: keyPressed || '',
    clientData: clientData || '',
  });
});

tccc.on('ratingSucceeded', ({ sessionId }) => {
  store.dispatch(addInfoMessage({ data: [getRatingMessage(sessionId, store.getState().userInfo.userId)] }));
});
tccc.on('messageReceived', (event) => {
  const state = store.getState();
  store.dispatch(receiveMessageThunk(event)).then(() => {
    sdkMessage.postEventMessage(sdkMessage.events.messageReceived, event);
    const sessionIdList = event.data.map((item) => item.sessionId);
    sessionIdList.forEach((item) => {
      const session = selectBySessionId(state, item);
      if (state.userInfo.currentUser?.sessionId === item && !state.tim.sdkMinimized && getTabIsActive()) {
        store.dispatch(setMessageRead({ sessionId: item }));
      }
      if (session?.status === '200') {
        const shouldPlayNewMessageAudio = event.data.some(shouldAddUnreadCountOrUpdateLastMessage);
        if (shouldPlayNewMessageAudio) {
          const promise = (document.getElementById('newMessageAudio') as HTMLAudioElement)?.play();
          if (promise !== undefined) {
            promise.catch((error) => {
              logger.error('messageReceived auto play failed', error);
            });
          }
        }
      }
    });
  });
});
tccc.on('onMessageRevoked', (event) => {
  store.dispatch(messageRevoked(event.data));
});
tccc.on('onMessageModified', (event) => {
  store.dispatch(messageModified(event.data));
});
tccc.on('onlineStateChanged', ({ sessionId, state }) => {
  sdkMessage.postEventMessage(sdkMessage.events.onlineStateChanged, { sessionId, state });
  store.dispatch(
    updateSession({
      id: sessionId,
      changes: {
        onLineState: state,
      },
    }),
  );
});

tccc.on('mediaAccepted', ({ sessionId }) => {
  const session = selectBySessionId(store.getState(), sessionId);
  if (session?.media) {
    store.dispatch(
      updateSession({
        id: sessionId,
        changes: {
          media: {
            ...session.media,
            status: '200',
            startTime: Math.floor(Date.now() / 1000),
          },
        },
      }),
    );
  }
});
tccc.on('mediaEnded', ({ sessionId }) => {
  store.dispatch(endMedia({ sessionId, autoClose: true }));
});
tccc.on('mediaRejected', ({ sessionId }) => {
  store.dispatch(
    updateSession({
      id: sessionId,
      changes: {
        media: undefined,
      },
    }),
  );
  message.warning({
    content: t('对方拒绝了您的通话请求'),
  });
  Notification.onMessage({
    title: t('对方拒绝了您的通话请求'),
    icon: logo,
    time: 5,
  });
});

tccc.on('memberStateChanged', (res) => {
  if (res.isSelf) {
    store.dispatch(
      updateMember({
        sessionId: res.sessionId,
        isHost: res.isHost,
      }),
    );
  }
  if (
    res.userId &&
    !res.isSelf &&
    res.serverType !== 'outboundSeat' &&
    res.serverType !== 'queue' &&
    res.serverType !== 'customer'
  ) {
    const member: SeatMember = {
      memberId: res.memberId,
      userId: res.userId,
      waitId: res.waitId,
      callState: res.callState,
      displayName: res.displayName,
      serverType: res.serverType,
      extra: {
        skillGroupId: res.extra?.skillGroupId,
      },
      acceptedState: res.acceptedState,
      acceptTimestamp: res.acceptTimestamp,
      hungupTimestamp: res.hungupTimestamp,
    };
    store.dispatch(
      updateMember({
        sessionId: res.sessionId,
        changes: member,
      }),
    );
  }
  if ((res.phone || res.protectedPhone) && res.serverType === 'outboundSeat') {
    const member: OutboundMember = {
      memberId: res.memberId,
      callState: res.callState,
      phone: res.phone,
      protectedPhone: res.protectedPhone,
      serverType: 'outboundSeat',
      extra: {
        callerLocation: res.extra.callerLocation,
      },
      acceptedState: res.acceptedState,
      acceptTimestamp: res.acceptTimestamp,
      hungupTimestamp: res.hungupTimestamp,
    };
    store.dispatch(
      updateMember({
        sessionId: res.sessionId,
        changes: member,
      }),
    );
  }
  if (res.serverType === 'queue') {
    const member: QueueMember = {
      memberId: res.memberId,
      callState: res.callState,
      serverType: 'queue',
      userId: res.userId,
      displayName: res.displayName,
      acceptTimestamp: res.acceptTimestamp,
      hungupTimestamp: res.hungupTimestamp,
    };
    store.dispatch(
      updateMember({
        sessionId: res.sessionId,
        changes: member,
      }),
    );
  }

  sdkMessage.postEventMessage(
    sdkMessage.events.memberStateChanged,
    pick(res, [
      'sessionId',
      'memberId',
      'waitId',
      'displayName',
      'callState',
      'acceptedState',
      'phone',
      'protectedPhone',
      'serverType',
      'userId',
      'acceptTimestamp',
      'hungupTimestamp',
      'isHost',
      'isSelf',
    ]),
  );
});

tccc.on('muted', ({ sessionId }) => {
  store.dispatch(changeMicStatusAction({ sessionId, micStatus: 'off' }));
});
tccc.on('unmuted', ({ sessionId }) => {
  store.dispatch(changeMicStatusAction({ sessionId, micStatus: 'on' }));
});

tccc.on('conversationListUpdated', (data) => {
  const updateList: { sessionId: string | undefined; count: number }[] = [];
  const allSessions = selectAllSession(store.getState());
  const { userInfo } = store.getState();
  if (Array.isArray(data.data)) {
    data.data.map((conversationItem) => {
      const findSession = allSessions.find((item) => item.sessionId === conversationItem?.sessionId);
      if (
        findSession &&
        conversationItem?.conversation?.unreadCount > 0 &&
        conversationItem?.conversation?.unreadCount !== findSession?.count &&
        conversationItem.sessionId !== userInfo?.currentUser?.sessionId
      ) {
        updateList.push({ sessionId: findSession?.sessionId, count: conversationItem?.conversation?.unreadCount });
      }
    });
  }
  if (updateList.length > 0) {
    store.dispatch(updateUnreadCount(updateList));
  }
});

tccc.on('onMessageReadReceiptReceived', (data) => {
  const updateList: { sessionId: string; messageID: string; readCount: number; unreadCount: number }[] = [];
  const { userInfo } = store.getState();
  if (Array.isArray(data.data)) {
    data.data.map((message) => {
      if (message?.sessionId === userInfo?.currentUser?.sessionId) {
        updateList.push({
          sessionId: message.sessionId,
          messageID: message.messageID,
          readCount: message.readCount,
          unreadCount: message.unreadCount,
        });
      }
    });
  }
  if (updateList.length > 0) {
    store.dispatch(updateReadReceipt(updateList));
  }
});

export const removeListener = () => {
  tccc.removeAllListeners();
};
export default tccc;
