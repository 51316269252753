import i18n from 'src/i18n';

const { t } = i18n;

export enum SeatType {
  Premium = 1,
  Standard = 2,
  ImAgent = 3,
  TUiDeskKit = 4,
  NewStandard = 5,
  IviStandard = 6,
  IviPremium = 7,
}

export type SeatTypeRelatedFeatures = {
  // 显示logo
  showLogo: boolean;
  // 腾讯云呼叫中心
  tcccChineseName: string;
  // brand name
  brandName: string;
  // 呼叫中心
  tcccInstanceName: string;
  // 显示新手指引
  showBeginnerGuide: boolean;
  // 显示技术服务交流群
  showWxWorkSupportGroup: boolean;
  // 非座席角色登录，默认进入管理端
  defaultLoginToAdminPanelIfAdmin: boolean;
  // 电话权限
  phoneCallEnabled: boolean;
  // 在线会话权限
  imEnabled: boolean;
  // 视频客服权限
  videoCallEnabled: boolean;
  // 语音客服权限
  audioCallEnabled: boolean;
  // 实时语音识别功能
  realTimeAsr: boolean;
  // 自定义节假日
  customWorkday: boolean;
  // 显示警告
  lineUnavailableWarning: boolean;
  // IVR高级节点
  ivrAdvancedNode: boolean;
  // LoginComponent
  loginComponent?: React.ComponentType;
  // CRM权限
  crmEnabled: boolean;
};

const standSeatTypeRelatedFeatures: SeatTypeRelatedFeatures = {
  showLogo: true,
  tcccChineseName: t('腾讯云联络中心'),
  tcccInstanceName: t('企业联络中心'),
  brandName: t('腾讯云'),
  showBeginnerGuide: true,
  showWxWorkSupportGroup: true,
  defaultLoginToAdminPanelIfAdmin: false,
  phoneCallEnabled: true,
  imEnabled: true,
  videoCallEnabled: true,
  audioCallEnabled: true,
  realTimeAsr: false,
  customWorkday: false,
  lineUnavailableWarning: true,
  ivrAdvancedNode: false,
  crmEnabled: false,
};
export const seatTypeToFeaturesMapping: Record<SeatType, SeatTypeRelatedFeatures> = {
  [SeatType.Premium]: {
    ...standSeatTypeRelatedFeatures,
    realTimeAsr: true,
    customWorkday: true,
    ivrAdvancedNode: true,
    crmEnabled: true,
  },
  [SeatType.Standard]: standSeatTypeRelatedFeatures,
  [SeatType.ImAgent]: {
    ...standSeatTypeRelatedFeatures,
    phoneCallEnabled: false,
    videoCallEnabled: false,
    audioCallEnabled: false,
    lineUnavailableWarning: false,
  },
  [SeatType.TUiDeskKit]: {
    ...standSeatTypeRelatedFeatures,
    brandName: t('腾讯云'),
    tcccChineseName: t('即时通讯IM-客服插件'),
    tcccInstanceName: t('客服插件'),
    showLogo: false,
    showWxWorkSupportGroup: false,
    defaultLoginToAdminPanelIfAdmin: true,
    phoneCallEnabled: false,
    videoCallEnabled: false,
    lineUnavailableWarning: false,
    audioCallEnabled: false,
  },
  [SeatType.NewStandard]: standSeatTypeRelatedFeatures,
  [SeatType.IviStandard]: standSeatTypeRelatedFeatures,
  [SeatType.IviPremium]: {
    ...standSeatTypeRelatedFeatures,
    realTimeAsr: true,
    customWorkday: true,
    ivrAdvancedNode: true,
  },
};
export const filterPermissionKeys = (seatType: SeatType, permissionList: string[]): string[] => {
  const featureFlags = seatTypeToFeaturesMapping[seatType];
  return permissionList.filter((key) => {
    if (featureFlags.phoneCallEnabled && key.startsWith('TEL')) {
      return false;
    }
    if (featureFlags.imEnabled && key.startsWith('IM')) {
      return false;
    }
    if (featureFlags.videoCallEnabled && key.startsWith('VIDEO')) {
      return false;
    }
    if (featureFlags.audioCallEnabled && key.startsWith('RADIO')) {
      return false;
    }
    return true;
  });
};
