import findIndex from 'lodash/findIndex';

import get from 'lodash/get';
import { TIMSDK as TIM } from 'tccc-sdk';

import { CUSTOM_MESSAGE_SRC, IM_MESSAGE_EXTRA_TYPE, ROBOT_COMMAND, IMROBOT_MESSAGE_TYPE_LIST } from 'src/constants/im';
import i18n from 'src/i18n';
import logger from 'src/utils/logger';
import { getMessageFrom, paresMessageImageSet } from 'src/utils/message';

import {
  RobotElement,
  FileElement,
  ImageElement,
  LocationElement,
  MediaElement,
  SoundElement,
  TextElement,
  VideoElement,
  InfoElement,
  EndElement,
} from '../im-elements';
import { CardElement } from '../im-elements/card-element/CardElement';
import MessageChatbotInfoCollect from '../im-elements/chatbot-info-collect-element/ChatbotInfoCollectElement';
import { ImRobotElement } from '../im-elements/imRobot-element/ImRobotElement';
import { OrderLement } from '../im-elements/order-element/Order-element';
import MessageStream from '../im-elements/streamText-element/StreamTextElement';
import { UserCustomElement } from '../im-elements/userCustom-element/userCustomElement';
import { ImAside, ImCenter } from '../im-layout';

const { t } = i18n;

export function getActiveImageIndex(images, currentImageId) {
  return findIndex(images, (image) => image.ID === currentImageId);
}

export function getImagesFromMessageList(list) {
  return list
    ?.filter((item) => item.type === TIM.TYPES.MSG_IMAGE)
    ?.map((item) => {
      const imageUrl = paresMessageImageSet(item.payload);
      return {
        ...item,
        src: imageUrl['720P'],
        alt: item.payload.uuid,
        downloadUrl: imageUrl.raw,
      };
    });
}

const getCustomMessageData = (message) => {
  let data = message?.payload?.data || {};
  try {
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }
  } catch (e) {
    data = {};
  }
  return data;
};

function getCustomComponent(message) {
  let data = getCustomMessageData(message);
  let component = null;
  try {
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }
  } catch (e) {
    data = {};
  }
  if (data.fromPostMessage === 1) {
    return <UserCustomElement data={data} />;
  }
  if (data?.chatbotPlugin === 1) {
    // 现在只展示src为9的富文本消息
    // src为15的欢迎卡片与猜你想问的卡片现在不渲染
    if (
      data?.src === IMROBOT_MESSAGE_TYPE_LIST.RICHTEXT &&
      data?.content !== null &&
      data?.content !== '' &&
      data?.content !== undefined
    ) {
      return <ImRobotElement data={data} />;
    }
  }
  switch (data.src || data.type) {
    case CUSTOM_MESSAGE_SRC.ROBOT:
      switch (data.content.command) {
        case ROBOT_COMMAND.SHOW_DIALOG:
          return <RobotElement data={data} />;
        default:
          return null;
      }
    case IM_MESSAGE_EXTRA_TYPE.MEDIA:
      component = <MediaElement data={data} />;
      break;
    case CUSTOM_MESSAGE_SRC.NO_SEAT_ONLINE:
    case CUSTOM_MESSAGE_SRC.TIMEOUT:
    case CUSTOM_MESSAGE_SRC.END:
      component = <EndElement data={data.src || data.type} message={message} />;
      break;
    case CUSTOM_MESSAGE_SRC.CustomCard:
      component = <CardElement {...get(data, 'content', {})} />;
      break;
    case CUSTOM_MESSAGE_SRC.ORDER_CARD:
      component = <OrderLement {...get(data, 'content', {})} />;
      break;
    case CUSTOM_MESSAGE_SRC.RICH_TEXT:
      component = <ImRobotElement data={data} />;
      break;
    case CUSTOM_MESSAGE_SRC.STREAM_TEXT:
      component = <MessageStream data={data} />;
      break;
    case CUSTOM_MESSAGE_SRC.NEW_BRANCH:
      component = (
        <div style={{ wordBreak: 'break-all', padding: '12px' }}>{data?.content?.header || data?.content?.title}</div>
      );
      break;
    case CUSTOM_MESSAGE_SRC.NEW_INFO_COLLECT: {
      component = <MessageChatbotInfoCollect message={message} data={data} />;
      break;
    }
    case '1':
    case '2':
    case '5':
    case '6':
    case '7':
    case 'ivr':
    case CUSTOM_MESSAGE_SRC.MENU:
    case CUSTOM_MESSAGE_SRC.MENU_SELECTED:
      component = null;
      break;
    default:
      component = null;
  }
  return component;
}
export function getComponent({ data, classes, index, list, userInfo, callInfo }) {
  let component = null;

  if (data.isRevoked) {
    return (
      <div className={classes.infoMessage}>
        {data?.RevokerInfo?.UserId !== userInfo?.userId ? (
          <span>{t('对方撤回了一条消息')}</span>
        ) : (
          <span>{t('你撤回了一条消息')}</span>
        )}
      </div>
    );
  }
  switch (data.type) {
    case TIM.TYPES.MSG_GRP_TIP:
    case TIM.TYPES.MSG_GRP_SYS_NOTICE:
    case TIM.TYPES.MSG_CUSTOM:
      component = getCustomComponent(data, index);
      break;
    case TIM.TYPES.MSG_TEXT:
      component = <TextElement data={data} index={index} list={list} userInfo={userInfo} callInfo={callInfo} />;
      break;
    case TIM.TYPES.MSG_IMAGE:
      component = <ImageElement data={data} index={index} />;
      break;
    case TIM.TYPES.MSG_FILE:
      component = <FileElement data={data} index={index} />;
      break;
    case IM_MESSAGE_EXTRA_TYPE.INFO:
      component = <InfoElement info={data?.payload.text} />;
      break;
    case TIM.TYPES.MSG_SOUND:
      component = <SoundElement data={data} index={index} />;
      break;
    case TIM.TYPES.MSG_VIDEO:
      component = <VideoElement data={data} index={index} />;
      break;
    case TIM.TYPES.MSG_GEO:
      component = <LocationElement data={data} index={index} />;
      break;
    default:
      logger.warn(`暂未支持的消息类型：${data.type || data.payload.data.type || data.payload.data.src}`);
      component = null;
  }
  if (component) return getMessageWithLayout(component, data, index, callInfo, userInfo, list);
  return null;
}
// 需要居中的消息类型
const messageToCentered = [IM_MESSAGE_EXTRA_TYPE.INFO];
// 需要居中的自定义消息类型性
const customMessageToCentered = [
  CUSTOM_MESSAGE_SRC.MENU,
  CUSTOM_MESSAGE_SRC.END,
  CUSTOM_MESSAGE_SRC.TIMEOUT,
  CUSTOM_MESSAGE_SRC.NO_SEAT_ONLINE,
];
function getMessageWithLayout(component, message, index, callInfo, userInfo, list) {
  const data = getCustomMessageData(message);
  const from = getMessageFrom(message, callInfo, userInfo);
  if (
    messageToCentered.includes(message.type) ||
    (message.type === TIM.TYPES.MSG_CUSTOM && customMessageToCentered.includes(data.src))
  ) {
    return <ImCenter key={index} content={component} />;
  }

  const messageHeader = from;
  return (
    <ImAside
      renderBackground={![TIM.TYPES.MSG_FILE, TIM.TYPES.MSG_IMAGE].includes(message.type)}
      message={message}
      callInfo={callInfo}
      userInfo={userInfo}
      key={`${message.ID}`}
      content={component}
      list={list}
      header={messageHeader}
    >
      {component}
    </ImAside>
  );
}
