import { createAsyncThunk } from '@reduxjs/toolkit';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import { unwrapResult } from 'tccc-sdk';

import config from 'src/config';
import { SeatType, filterPermissionKeys, seatTypeToFeaturesMapping } from 'src/config/seatTypeToFeatureToggles';
import { IVR_TYPE } from 'src/constants/manage';
import i18n from 'src/i18n';
import { debugLogRequest } from 'src/services';
import { AdminKeys, SuperVisorKeys } from 'src/services/httpAPIs/admin';

import { checkIsDeskAuthing } from 'src/utils';

import { RootState } from '..';
import { aegis } from '../../aegis';
import { axiosInstance } from '../../services/axios';
import * as AdminAPI from '../../services/httpAPIs/appInfo';
import { judgeInWhitelistByKey, request } from '../../services/httpClient';
import { logger } from '../../utils/log';
import sessionManage from '../../utils/sessionManage';
import tccc from '../../utils/tccc';

import { PeerSourceKey, Session } from './sessions';
import { getInnerConversationList, getMessageList } from './tim.thunk';

// App级别的配置信息，如果后台不方便支持，前端暂时采用commonKV的方式来做
export const fetchExtraInfo = createAsyncThunk('fetchExtraInfo', AdminAPI.getExtraInfo);
export const updateExtraInfo = createAsyncThunk('updateExtraInfo', AdminAPI.updateExtraInfo);
export const fetchAppInfo = createAsyncThunk('fetchAppInfo', AdminAPI.getAppInfo);
export const updateAppInfo = createAsyncThunk('setAppInfo', AdminAPI.setAppInfo);
export const fetchRestReasons = createAsyncThunk('fetchRestReasons', AdminAPI.getRestReasons);
export const fetchSkillGroupList = createAsyncThunk('fetchSkillGroupList', (options: { sdkAppId: string }) =>
  request('/tcccadmin/tech/getSkillGroupList', options).then(({ skillGroupList }) => skillGroupList),
);
export const updateAssignAccept = createAsyncThunk(
  'updateAssignAccept',
  async (options: { assignIM: boolean } | { assignCall: boolean }, { getState }) => {
    const { userInfo } = getState() as RootState;
    let { assignIM, assignCall } = userInfo;
    if ('assignCall' in options) {
      assignCall = options.assignCall;
    }
    if ('assignIM' in options) {
      assignIM = options.assignIM;
    }
    await request('/tcccadmin/staff/setAssignAccept', { assignIM, assignCall });
    return {
      assignIM,
      assignCall,
    };
  },
);

export const updatePhoneNumReflect = createAsyncThunk(
  'updatePhoneNumReflect',
  async (
    params:
      | { mode: 0 }
      | ({ mode: 1 | 2; url: string } & (
          | {
              authType: 'oauth2';
              tokenURL: string;
              clientID: string;
              clientSecret: string;
            }
          | {
              authType: 'basic';
              username: string;
              password: string;
            }
        )),
    { rejectWithValue, dispatch },
  ) => {
    if (params.mode === 0) {
      // 关闭号码映射的同时开启呼入呼出号码加密
      try {
        await Promise.all([
          dispatch(updateAppInfo({ hideCallinNumber: true, hideCalloutNumber: true })),
          request('/tcccadmin/app/setPhoneNumReflect', {
            mode: 0,
          } as any),
        ]);
        return {
          numberReflectMode: params.mode as 0,
        };
      } catch (e: any) {
        return rejectWithValue(e?.msg);
      }
    }
    // 开启时关闭呼入呼出加密
    try {
      await Promise.all([
        dispatch(updateAppInfo({ hideCallinNumber: false, hideCalloutNumber: false })),
        request('/tcccadmin/app/setPhoneNumReflect', {
          mode: params.mode,
          url: params.url,
          authType: params.authType!,
          authConfig:
            params.authType === 'basic'
              ? {
                  username: params.username,
                  password: params.password,
                }
              : {
                  tokenURL: params.tokenURL,
                  clientID: params.clientID,
                  clientSecret: params.clientSecret,
                },
        }),
      ]);
      return {
        numberReflectMode: params.mode as 1 | 2,
      };
    } catch (e: any) {
      return rejectWithValue(e?.msg);
    }
  },
);

type LoginParams =
  | {
      sdkAppId: string;
      userId: string;
      token: string;
      suspenseSeatFeat?: boolean;
      origin?: string;
    }
  | { sdkAppId: string; userId: string; suspenseSeatFeat?: boolean };
export const loginInstance = createAsyncThunk('loginInstance', async (params: LoginParams, { dispatch }) => {
  const appInfo = tccc.Agent.settings;
  const suspenseSeatFeat = params.suspenseSeatFeat || !!new URLSearchParams(window.location.search).get('fromSdk');
  const isDeskAuthing = checkIsDeskAuthing();
  let enableShared = false;
  if ('token' in params) {
    enableShared = Boolean(new URLSearchParams(window.location.search).get('enableShared'));
  }

  const loginAndGetBasicInfo = async () => {
    // 话机场景跳过初始化本地流以防弹窗造成对用户的打扰, TCCC内部、TCCC内部(禁用)、领健、朱氏药业、珠海德瑞斯
    const skipLocalStreamInit = await judgeInWhitelistByKey('skipLocalStreamInit', params.sdkAppId);
    const skipInitLocalStreamWhileLogin = config.isDeskKit || skipLocalStreamInit || isDeskAuthing;
    await tccc.Agent.createAgent(
      'token' in params
        ? {
            sdkAppId: params.sdkAppId,
            userId: params.userId,
            token: params.token,
            origin: params.origin,
          }
        : {
            userId: params.userId,
            sdkAppId: params.sdkAppId,
          },
    );
    axiosInstance.defaults.headers.Authorization = `Bearer ${tccc.Agent.sessionKey}`;
    if (!suspenseSeatFeat) {
      await tccc.Agent.online({
        createLocalStreamOnLogin: !skipInitLocalStreamWhileLogin,
        enableShared,
      });
    }
    const { settings: partialAppSettings, userInfo, agentState } = tccc.Agent;
    if (!userInfo) {
      throw new Error(i18n.t('登录失败'));
    }
    const { sdkAppId, userId } = userInfo;
    aegis.setConfig({
      uin: userInfo.userId,
      ext1: userInfo.sdkAppId,
    });
    logger.setUserId(userInfo.userId);

    sessionManage.setGlobalUserId(userId);
    sessionManage.setGlobalSdkAppId(sdkAppId);
    dispatch(fetchAppInfo({ sdkAppId }));
    dispatch(fetchExtraInfo());
    dispatch(fetchRestReasons({ sdkAppId }));
    dispatch(fetchSkillGroupList({ sdkAppId }));
    dispatch(getUserSettings());
    const appSettings = mapKeys(partialAppSettings, (_, key) =>
      key
        .replace('CountDown', 'Countdown')
        .replace('TimeOut', 'Timeout')
        .replace('audioEndCountDown', 'audioEndCountdown'),
    );

    const isAdvanced = appSettings.seatType === 1; // 是否是高级版座席
    const isNormal = appSettings.seatType === 2; // 是否是标准版座席

    return {
      userInfo: {
        ...omit(userInfo, 'nickname'),
        nickName: userInfo.nickname,
        loginChecked: true,
      },
      agentAssignConfig: {
        existCall: agentState.existCall,
        assignCall: agentState.assignCall,
        existIM: agentState.existIM,
        assignIM: agentState.assignIM,
      },
      partialAppSettings: {
        ...appSettings,
        isAdvanced,
        isNormal,
      },
      telCallinPostIVR: appSettings.telCallinPostIVR,
      isAdvanced,
      isNormal,
    };
  };

  const getSessions = async () => {
    const sessions: Session[] = [];
    if (suspenseSeatFeat) {
      return Promise.resolve([]);
    }
    // 涉及到tccc sdk的操作必须要登录后才能进行
    // checkLogin时已create sdk
    dispatch(getInnerConversationList());
    try {
      const conversationList = await tccc.getConversationList().then(unwrapResult);
      sessions.push(
        ...conversationList.map((item) => ({
          ...item,
          peerSource: item.peerSource as PeerSourceKey,
          type: item.type,
          count: item.unreadCount,
          nickname: item.type === 'phone' ? '' : item.nickname || i18n.t('访客_{{0}}', { 0: item.userId }),
          hangupSide: item.hangupSide || 'seat',
          media: undefined,
          members: [],
        })),
      );
      conversationList
        .filter((item) => item.type === 'im' && item.status === '200')
        .forEach((item) => {
          // 拉取离线消息由参数统一控制，入口为登录，或者重新上线时触发
          dispatch(getMessageList({ sessionId: item.sessionId, isGetOfflineMessages: true }));
        });
    } catch (e: any) {
      debugLogRequest('tccc.getConversationList Error', e.message);
    }
    return sessions;
  };

  const getOldDataNum = async () => {
    let inputCallBackTotal = 0;
    let customerSatisfactionTotal = 0;
    try {
      const [res1, res2] = await Promise.all([
        request('/tcccadmin/ivr/getIvrList', { type: IVR_TYPE.inputCallback }),
        request('/tcccadmin/ivr/getIvrList', {
          type: IVR_TYPE.customerSatisfaction,
        }),
      ]);
      inputCallBackTotal = +(res1?.total || 0);
      customerSatisfactionTotal = +(res2?.total || 0);
    } catch (error: any) {
      debugLogRequest(`请求满意度评价和收号配置失败 ${error}`);
    }
    return { inputCallBackTotal, customerSatisfactionTotal };
  };

  const getPermissionList = async ({ roleId }: { roleId: string; userId: string }) => {
    if (roleId === '1') {
      return {
        permissionList: [...AdminKeys, ...SuperVisorKeys],
      };
    }
    if (roleId === '2') {
      return {
        permissionList: SuperVisorKeys,
      };
    }
    if (roleId === '3') {
      return undefined;
    }
    return request('/tcccadmin/staff/getRoleList', {}).then((res) => {
      const role = res.roleList.find((id) => id.roleId === roleId);
      if (!role) {
        return undefined;
      }
      return {
        permissionList: role.permissionList as string[],
        skillGroupScope: role.skillGroupPermissionList![0].skillGroupList,
      };
    });
  };
  const basicInfo = await loginAndGetBasicInfo();
  const seatType = (basicInfo.partialAppSettings as any).seatType as SeatType;

  const [sessions, counts, permissionList] = await Promise.all([
    getSessions(),
    getOldDataNum(),
    getPermissionList(basicInfo.userInfo),
  ]);

  return {
    userInfo: {
      ...basicInfo.userInfo,
      ...permissionList,
      permissionList: filterPermissionKeys(
        config.isDeskKit ? SeatType.TUiDeskKit : seatType,
        permissionList?.permissionList || [],
      ),
    },
    agentAssignConfig: basicInfo.agentAssignConfig,
    appSettings: {
      ...basicInfo.partialAppSettings,
      showOldInputCallback: basicInfo.isAdvanced && counts.inputCallBackTotal > 0,
      showOldUserSatisfaction:
        basicInfo.isAdvanced && appInfo?.telCallinPostIVR && counts.customerSatisfactionTotal > 0,
      featureFlag: config.isDeskKit
        ? seatTypeToFeaturesMapping[SeatType.TUiDeskKit]
        : seatTypeToFeaturesMapping[seatType],
    },
    sessions,
  };
});

export const getUserSettings = createAsyncThunk('getUserSettings', async () => {
  try {
    const { useMobileAcceptType, useMobileCallOut, canUseMobile } = await request('/tcccadmin/staff/getConfig', {});
    return {
      useMobileCallOut,
      useMobileAcceptType,
      canUseMobile,
    };
  } catch (e: any) {
    debugLogRequest('getConfig Error', e.message);
  }
});

export const updateUserSettings = createAsyncThunk(
  'updateUserSettings',
  async (params: { useMobileAcceptType?: 0 | 1 | 2; useMobileCallOut?: boolean }, { getState }) => {
    const store = getState() as RootState;
    try {
      await request('/tcccadmin/staff/setConfig', params);
      return {
        ...store.userInfo,
        ...params,
      };
    } catch (e: any) {
      debugLogRequest('setConfig Error', e.message);
    }
  },
);
