import { createAction, createAsyncThunk, isFulfilled } from '@reduxjs/toolkit';
import get from 'lodash/get';
import moment, { duration, utc } from 'moment';
import { Message, TIMSDK as TIMSdk, unwrapResult } from 'tccc-sdk';

import sdkMessage from 'src/agentSdkIframe/sdkMessage';
import { INVISIBLE_MESSAGE_TYPE_LIST } from 'src/constants/im';
import i18n from 'src/i18n';
import { StaffStatus } from 'src/services/httpAPIs/admin';
import { request } from 'src/services/httpClient';
import { logger as TxLogger } from 'src/utils/log';
import logger from 'src/utils/logger';
import tccc from 'src/utils/tccc';
import { timer } from 'src/utils/timing';

import { RootState, selectBySessionId } from '..';

import { Direction } from './sessions';
import { InfoMessage, InnerConversation } from './tim';

const { t } = i18n;

export const updateImageViewer = createAction<{ visible: boolean; currentImageId?: string }>('TIM/updateImageViewer');

export const updateReplyInfo = createAction<{ sessionId: string; replyInfo: { replying: boolean; message?: Message } }>(
  'TIM/updateReplyInfo',
);

export const updateTypingState = createAction<{ sessionId: string; state: boolean }>('TIM/updateTypingState');

export const addInfoMessage = createAction<{ data: InfoMessage[] }>('TIM/addInfoMessage');

export const imSdkReadyStateChanged = createAction('TIM/imSdkReadyStateChanged');

export const updateUnreadCount = createAction<{ sessionId?: string; count: number }[]>('TIM/updateUnreadCount');

export const updateReadReceipt = createAction<
  {
    sessionId: string;
    messageID: string;
    readCount: number;
    unreadCount: number;
  }[]
>('TIM/updateReadReceipt');

export const markUnread = createAsyncThunk('TIM/markUnread', async (params: { sessionId: string }) => {
  try {
    await tccc.Chat.markUnread(params);
  } catch (e) {
    logger.error('markUnread: markUnread failed', e);
  }
  return params;
});

const getCustomMessageData = (message: Message) => {
  let data = get(message, 'payload.data', {});
  try {
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }
  } catch (e) {
    logger.error('getCustomMessageData: getCustomMessageData failed', e);
    data = {};
  }
  return data;
};

export const shouldAddUnreadCountOrUpdateLastMessage = (message: Message): boolean => {
  try {
    // 群通知不增加
    if (message?.type === TIMSdk.TYPES.MSG_GRP_TIP) {
      return false;
    }
    if (message?.type === TIMSdk.TYPES.MSG_CUSTOM) {
      const data = getCustomMessageData(message);
      if (INVISIBLE_MESSAGE_TYPE_LIST.includes(data?.src)) {
        return false;
      }
    }
  } catch (e) {
    logger.error('shouldAddUnreadCount: shouldAddUnreadCount failed', e);
  }
  return true;
};

export const setMessageRead = createAsyncThunk('TIM/setMessageRead', async (params: { sessionId: string }) => {
  try {
    await tccc.Chat.setMessageRead(params);
  } catch (e) {
    logger.error('setMessageRead: setMessageRead failed', e);
  }
  return params;
});

export const sdkMinimizedChanged = createAsyncThunk(
  'TIM/sdkMinimizedChanged',
  async (args: { sdkMinimized: boolean }, { getState, dispatch }) => {
    const store = getState() as RootState;
    const { currentUser } = store.userInfo;
    if (currentUser?.sessionId && !args.sdkMinimized) {
      const session = selectBySessionId(store, currentUser.sessionId);
      if (session?.count && session.count > 0) {
        dispatch(setMessageRead({ sessionId: currentUser.sessionId }));
      }
    }
    return args;
  },
);
export const messageRevoked = createAsyncThunk(
  'TIM/messageRevoked',
  async (params: { messageId: string; sessionId: string }[]) => params,
);
export const messageModified = createAsyncThunk(
  'TIM/messageModified',
  async (params: { message: Message; sessionId: string }[]) => params,
);
export const InitGroupInfo = createAsyncThunk('TIM/InitGroupInfo', async (params: { sessionId: string }) => params);
export const updateGroupNotification = createAsyncThunk(
  'TIM/updateGroupNotification',
  async (params: { notification: string; sessionId: string; type?: 'init' }) => params,
);
export const updateGroupMemberCount = createAsyncThunk(
  'TIM/updateGroupMemberCount',
  async (params: { memberCount: number; sessionId: string; type?: 'init' }) => params,
);
export const updateGroupMemberList = createAsyncThunk(
  'TIM/updateGroupMemberList',
  async (params: {
    memberList: { userID: string; avatar: string; nick: string; role: string }[];
    sessionId: string;
    type: 'init' | 'add' | 'delete' | 'update';
  }) => params,
);
export const receiveMessageThunk = createAsyncThunk(
  'TIM/receiveMessageThunk',
  async (params: { data: Message[] }, { getState, dispatch }) => {
    const store = getState() as RootState;
    const { innerConversationList } = store.tim;
    let hasNewInnerConv = false;
    params.data.forEach((item) => {
      if (
        item.sessionId.startsWith('C2C') &&
        item.flow === 'in' &&
        !innerConversationList.find((conv) => conv.sessionId === item.sessionId)
      ) {
        hasNewInnerConv = true;
      }
      if (item.type === TIMSdk.TYPES.MSG_GRP_TIP) {
        const { payload } = item;
        switch (payload?.operationType) {
          case TIMSdk.TYPES.GRP_TIP_MBR_JOIN:
            dispatch(
              updateGroupMemberList({
                memberList: payload?.memberList ?? [],
                sessionId: item?.sessionId ?? '',
                type: 'add',
              }),
            );
            break;
          case TIMSdk.TYPES.GRP_TIP_MBR_QUIT:
            dispatch(
              updateGroupMemberList({
                memberList: payload?.memberList ?? [],
                sessionId: item?.sessionId ?? '',
                type: 'delete',
              }),
            );
            break;
          case TIMSdk.TYPES.GRP_TIP_MBR_KICKED_OUT:
            dispatch(
              updateGroupMemberList({
                memberList: payload?.memberList ?? [],
                sessionId: item?.sessionId ?? '',
                type: 'delete',
              }),
            );
            break;
          case TIMSdk.TYPES.GRP_TIP_GRP_PROFILE_UPDATED:
            dispatch(
              updateGroupNotification({
                notification: payload?.newGroupProfile?.notification ?? '',
                sessionId: item?.sessionId ?? '',
              }),
            );
            break;
        }
      }
    });
    if (hasNewInnerConv) {
      await dispatch(getInnerConversationList());
    }
    return params.data;
  },
);

export const getRatingMessage = (sessionId: string, userId: string, text?: string): InfoMessage => ({
  ID: '',
  type: 'INFO',
  payload: {
    text: text || t('{{0}} 用户已对您的服务作出评价', { 0: moment().format('MM-DD HH:mm:ss') }),
  },
  time: Math.floor(Date.now() / 1000),
  flow: 'in',
  sessionId,
  conversationID: '',
  isRevoked: false,
  isResend: false,
  isSystemMessage: true,
  status: 'success',
  needReadReceipt: false,
  from: userId,
  to: sessionId,
  nick: userId,
  avatar: '',
  cloudCustomData: '',
  sequence: 0,
  readReceiptInfo: {
    readCount: 0,
    unreadCount: 0,
  },
});

export const sendIMSessionRating = createAsyncThunk(
  'sessions/sendIMSessionRating',
  async ({ sessionId }: { sessionId: string }, { getState, rejectWithValue, dispatch }) => {
    if (!sessionId) {
      return rejectWithValue('sendIMSessionRating failed, sessionId must not be empty');
    }
    const store = getState() as RootState;
    const session = selectBySessionId(store, sessionId);
    if (!session) {
      return rejectWithValue(`sendIMSessionRating failed, sessionId: ${sessionId} does ont exist`);
    }

    try {
      await tccc.Chat.sendSessionRating({
        sessionId,
      });
      dispatch(
        addInfoMessage({
          data: [
            getRatingMessage(
              sessionId,
              store.userInfo.userId,
              t('{{0}} 您已成功向用户发送了评价邀请', { 0: moment().format('MM-DD HH:mm:ss') }),
            ),
          ],
        }),
      );
    } catch (error) {
      const errorMessage = (error as Error).message || t('邀请评价失败，请重试');
      return rejectWithValue(errorMessage);
    }
    return session;
  },
);

export const getMessageList = createAsyncThunk(
  'TIM/getMessageList',
  async (params: { sessionId: string; nextReqMessageID?: string; isGetOfflineMessages?: boolean }, { getState }) => {
    // 拉取离线消息由参数统一控制，入口为登录，或者重新上线时触发
    const isGetOfflineMessages = params?.isGetOfflineMessages ?? false;
    const store = getState() as RootState;
    const TIMItem = store.tim.messageList[params.sessionId];
    TxLogger.info(`[getMessageList req], sessionId: ${params.sessionId}, nextReqMessageID: ${params.nextReqMessageID}`);
    // 防止漫游的下线消息没拉到,保留isCompleted if分支，方便以后扩展
    if (isGetOfflineMessages) {
      if (params.sessionId !== store?.userInfo?.currentUser?.sessionId) {
        return {
          sessionId: params.sessionId,
          messageList: [],
          nextReqMessageID: '',
          isCompleted: false,
          isGetOfflineMessages,
        };
      }

      const res = await tccc.Chat.getMessageList({ sessionId: params.sessionId }).then(unwrapResult);
      TxLogger.info(`[getMessageList res], sessionId: ${params.sessionId}, res: ${JSON.stringify(res)}`);
      return {
        sessionId: res.sessionId,
        messageList: res.messageList,
        nextReqMessageID: res.nextReqMessageID,
        isCompleted: res.isCompleted,
        isGetOfflineMessages,
      };
    }
    if (!TIMItem) {
      const res = await tccc.Chat.getMessageList({ sessionId: params.sessionId }).then(unwrapResult);
      TxLogger.info(`[getMessageList res], sessionId: ${params.sessionId}, res: ${JSON.stringify(res)}`);
      return {
        sessionId: res.sessionId,
        messageList: res.messageList,
        nextReqMessageID: res.nextReqMessageID,
        isCompleted: res.isCompleted,
      };
    }
    const res = await tccc.Chat.getMessageList({
      sessionId: params.sessionId,
      nextReqMessageID: params.nextReqMessageID,
    })
      .then(unwrapResult)
      .then((data) => data);
    TxLogger.info(`[getMessageList res], sessionId: ${params.sessionId}, res: ${JSON.stringify(res)}`);
    return {
      sessionId: res.sessionId,
      messageList: res.messageList,
      nextReqMessageID: res.nextReqMessageID,
      isCompleted: res.isCompleted,
    };
  },
);

export const initMessageList = createAsyncThunk('TIM/initMessageList', async (params: { sessionId: string }) => {
  TxLogger.info(`[initMessageList req], sessionId: ${params.sessionId}`);
  const res = await tccc.Chat.getMessageList({
    sessionId: params.sessionId,
  }).then(unwrapResult);
  TxLogger.info(`[initMessageList res], sessionId: ${params.sessionId}, res: ${JSON.stringify(res)}`);
  return {
    sessionId: res.sessionId,
    messageList: res.messageList,
    nextReqMessageID: res.nextReqMessageID,
    isCompleted: res.isCompleted,
  };
});

export const createTextMessage = createAsyncThunk(
  'TIM/createTextMessage',
  (params: { sessionId: string; payload: { text: string; custom?: string } }) =>
    tccc.Chat.createTextMessage(params).then(unwrapResult),
);
export const reportFileUpload = createAction<{ requestId: string; progress: number; sessionId: string }>(
  'reportFileUpload',
);

export const createFileMessage = createAsyncThunk(
  'TIM/createFileMessage',
  (params: { sessionId: string; payload: { file: File } }, { dispatch, requestId }) =>
    tccc.Chat.createFileMessage({
      sessionId: params.sessionId,
      payload: params.payload,
      onProgress: (progress: number) =>
        dispatch(reportFileUpload({ requestId, progress, sessionId: params.sessionId })),
    }).then(unwrapResult),
);
export const sendMessageReadReceipt = createAsyncThunk(
  'TIM/sendMessageReadReceipt',
  (params: { sessionId: string; messageIds: string[] }) => {
    tccc.Chat.sendMessageReadReceipt({ messageIds: params.messageIds }).then(unwrapResult);
  },
);
export const createVideoMessage = createAsyncThunk(
  'TIM/createVideoMessage',
  (params: { sessionId: string; payload: { file: File } }) =>
    tccc.Chat.createVideoMessage({ sessionId: params.sessionId, payload: params.payload }).then(unwrapResult),
);

export const createImageMessage = createAsyncThunk(
  'TIM/createImageMessage',
  (params: { sessionId: string; payload: { file: File } }) =>
    tccc.Chat.createImageMessage({ sessionId: params.sessionId, payload: params.payload }).then(unwrapResult),
);

export const createCustomMessage = createAsyncThunk(
  'IM/createCustomMessage',
  (params: { sessionId: string; payload: { data: string; description: string; extension: string } }) =>
    tccc.Chat.createCustomMessage(params).then(unwrapResult),
);

type MessageStatus = 'unSend' | 'success' | 'fail';
export const sendMessage = createAsyncThunk(
  'TIM/sendMessage',
  async (params: { message: Message; options?: { onlineUserOnly: boolean } }) => {
    const response = await tccc.Chat.sendMessage(params.message, params.options).then(unwrapResult);
    if (params.message.type !== 'TIMCustomElem') {
      sdkMessage.postEventMessage(sdkMessage.events.sendMessage, {
        message: response.data.message,
      });
    }
    return {
      message: response.data.message,
    };
  },
);

export const resendMessage = createAsyncThunk('TIM/resendMessage', async (params: { message: Message }) => {
  const response = await tccc.Chat.resendMessage(params.message).then(unwrapResult);
  if (response.code === 0) {
    return {
      message: response.data.message,
    };
  }
  return {
    message: {
      ...params.message,
      status: 'fail' as MessageStatus,
    },
  };
});

export const revokeMessage = createAsyncThunk('TIM/revokeMessage', async (params: { message: Message }) => {
  const response = await tccc.Chat.revokeMessage(params.message).then(unwrapResult);
  if (response.code === 0) {
    return {
      message: response.data.message,
    };
  }
  return {
    message: params.message,
  };
});

export const getInnerConversationList = createAsyncThunk('TIM/getInnerConversationList', async () => {
  const conversationList = await tccc.Chat.getInnerConversationList().then(unwrapResult);
  return conversationList.map((item) => ({ ...item, count: item.unreadCount }));
});
export const refreshInnerConversationStatus = createAsyncThunk(
  'TIM/refreshInnerConversationStatus',
  async (_, { getState }) => {
    const { innerConversationList } = (getState() as any).tim;
    if (innerConversationList?.length) {
      const { staffList } = await request('/tcccadmin/staff/getStaffList', {
        batchUserIds: innerConversationList.map(({ userId }: { userId: string }) => userId),
        isNeedStatus: true,
      });
      return staffList;
    }
    return [];
  },
);
export const startInnerConversation = createAsyncThunk(
  'TIM/startInnerConversation',
  async (
    {
      userId,
      staffNo,
      skillGroupId,
      nickName,
    }: { userId: string; staffNo: string; skillGroupId: string; nickName: string },
    { dispatch },
  ) => {
    const session: InnerConversation = {
      sessionId: `C2C${userId}`,
      userId,
      staffNo,
      skillGroupId,
      count: 0,
      nickname: nickName,
      status: StaffStatus.Offline,
    };
    await dispatch(getMessageList({ sessionId: `C2C${userId}` }));
    return session;
  },
);

export const inviteMedia = createAsyncThunk(
  'TIM/inviteMedia',
  async (params: { sessionId: string; type: 'audio' | 'video' }) => {
    if (params.type === 'audio') {
      await tccc.Chat.inviteAudio({ sessionId: params.sessionId });
    } else {
      await tccc.Chat.inviteVideo({ sessionId: params.sessionId });
    }
    return {
      sessionId: params.sessionId,
      media: {
        type: params.type,
        status: '150',
        direction: Direction.callOut,
      },
    };
  },
);

export const endMedia = createAsyncThunk(
  'TIM/endMedia',
  async (params: { sessionId: string; autoClose?: boolean }, { getState, dispatch }) => {
    const store = getState() as RootState;
    const session = selectBySessionId(store, params.sessionId);
    if (!session) {
      throw new Error(`session not found: ${params.sessionId}`);
    }
    const type = session.media?.type;
    if (!session.media) {
      throw new Error('ednMediaSession failed, cannot end current status Media session');
    }
    if (!type) {
      throw new Error(`media not found: ${params.sessionId}`);
    }
    if (session.media.startTime) {
      timer.clear(session.media.startTime);
    }
    const response = {
      sessionId: params.sessionId,
    };
    const now = Math.floor(Date.now() / 1000);
    const seconds = session.media?.startTime ? now - session.media.startTime : 0;
    let text = '';
    if (seconds === 0) {
      if (type === 'audio') {
        text = t('语音通话已取消');
      } else {
        text = t('视频通话已取消');
      }
    } else {
      const momentDuration = duration(seconds || 0, 'second');
      let formatType = 'mm:ss';
      if (momentDuration.get('hours') >= 1) {
        formatType = 'HH:mm:ss';
      }
      const time = utc(momentDuration.asMilliseconds()).format(formatType);
      if (type === 'audio') {
        text = t('语音通话已结束，通话时长：{{0}}', { 0: time });
      } else {
        text = t('视频通话已结束，通话时长：{{0}}', { 0: time });
      }
    }
    const textResp = await dispatch(createTextMessage({ sessionId: params.sessionId, payload: { text } }));
    if (isFulfilled(textResp)) {
      await dispatch(sendMessage({ message: textResp.payload }));
    }
    if (params.autoClose) {
      return response;
    }
    if (session.media?.status === '200' || session.media?.status === '150') {
      try {
        if (!params.autoClose) {
          if (type === 'video') {
            await tccc.Chat.endVideo(session);
          } else {
            await tccc.Chat.endAudio(session);
          }
        }
      } catch (e) {
        console.warn('ednMediaSession warn, rtc quit error');
      }
    }
    return response;
  },
);
